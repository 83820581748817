<template>
    <!-- 分页组件 -->
    <div class="pagination">
        <button :disabled="pageNo == 1" @click="$emit('getPageNo', pageNo - 1)">
            上一页
        </button>
        <button
            v-if="startNumAndEndNum.start > 1"
            @click="$emit('getPageNo', 1)"
        >
            1
        </button>
        <button v-if="startNumAndEndNum.start > 2">···</button>

        <button
            v-for="(page, index) in startNumAndEndNum.end"
            :key="index"
            @click="$emit('getPageNo', page)"
            v-show="page >= startNumAndEndNum.start"
            :class="{ active: pageNo == page }"
        >
            {{ page }}
        </button>

        <button v-if="startNumAndEndNum.end < totalPage - 1">···</button>
        <button
            v-if="startNumAndEndNum.end < totalPage"
            @click="$emit('getPageNo', totalPage)"
        >
            {{ totalPage }}
        </button>
        <button
            :disabled="pageNo == totalPage"
            @click="$emit('getPageNo', pageNo + 1)"
        >
            下一页
        </button>

        <button style="margin-left: 30px">共 {{ total }} 条</button>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: ["pageNo", "pageSize", "total", "continues"],
    computed: {
        // 总页数
        totalPage() {
            return Math.ceil(this.total / this.pageSize); //向上取整
        },
        // 计算出连续的页码的起始数字与结束数字【连续页码的数字：至少是5】
        startNumAndEndNum() {
            const { continues, pageNo, totalPage } = this;
            // 起始数字、结束数字
            let start = 0;
            let end = 0;

            // 连续页码数字为5，不正常现象————totalPage不够5页
            if (continues > totalPage) {
                start = 1;
                end = totalPage;
            } else {
                // 正常现象
                start = pageNo - parseInt(continues / 2);
                end = pageNo + parseInt(continues / 2);

                // 【start出现0或负数】
                if (start < 1) {
                    start = 1;
                    end = continues;
                }
                // 【end大于totalPage】
                if (end > totalPage) {
                    end = totalPage;
                    start = totalPage - continues + 1;
                }
            }
            return { start, end };
        },
    },
};
</script>

<style scoped lang="scss">
// .pagination {
//     display: flex;
//     margin-top: 30px;

//     // 第一个button样式
//     button:first-child {
//         width: 30px;
//     }

//     .active {
//         background-color: #cbc9c9;
//     }

//     button {
//         width: 20px;
//         height: 13px !important;
//         background-color: #fff;
//         border: 1px solid #0056b0;
//     }

//     // 倒数第二个button样式
//     button:nth-last-of-type(2) {
//         width: 30px;
//     }

//     // 最后一个button样式
//     button:last-child {
//         width: 30px;
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) {
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .pagination {
//         display: flex;
//         margin-top: 15px;

//         // 第一个button样式
//         button:first-child {
//             width: 30px;
//         }

//         .active {
//             background-color: #cbc9c9;
//         }

//         button {
//             width: 20px;
//             height: 20px;
//             background-color: #fff;
//             border: 1px solid #0056b0;
//         }

//         // 倒数第二个button样式
//         button:nth-last-of-type(2) {
//             width: 30px;
//         }

//         // 最后一个button样式
//         button:last-child {
//             width: 30px;
//         }
//     }
// }
// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
