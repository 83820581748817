<template>
    <!-- 助企活动 -->
    <div class="activity">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <div class="survey-nav-title">
                    <div class="survey-title-cn">助企活动</div>
                    <div class="survey-title-sn">About the Institute</div>
                </div>
                <div class="survey-title-text">活动筛选：</div>
                <div class="survey-title-select1">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            全部分类
                            <i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(item, index) in catelistDe"
                                :key="index"
                                :command="item.id"
                                >{{ item.name }}</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="survey-title-select1">
                    <el-dropdown @command="handleflag">
                        <span class="el-dropdown-link">
                            全部状态
                            <i class="el-icon-caret-bottom el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(itema, indexa) in flaglistDe"
                                :key="indexa"
                                :command="itema"
                                >{{ itema }}</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <div class="left1">
                            <div class="left-card">
                                <ul class="ul2">
                                    <li
                                        v-for="(
                                            itemb, indexb
                                        ) in activitylist.data"
                                        :key="indexb"
                                        @click="detailsEv(itemb)"
                                    >
                                        <div class="science-card">
                                            <!-- 助企活动图片 -->
                                            <img :src="itemb.image" alt="" />
                                            <!-- 助企活动标题 -->
                                            <div class="science-card3-title">
                                                {{ itemb.title }}
                                            </div>
                                            <!-- 助企活动描述 -->
                                            <div class="science-card3-ms">
                                                {{ itemb.address }}
                                            </div>
                                            <!-- 助企活动底部样式 -->
                                            <div class="science-card3-footer">
                                                <div
                                                    class="science-card3-footer1"
                                                >
                                                    {{ itemb.flagname }}
                                                </div>
                                                <div
                                                    class="science-card3-footer2"
                                                >
                                                    <img
                                                        src="../../../assets/pc/Index/clock.png"
                                                        alt=""
                                                    />
                                                    <!-- 时间 -->
                                                    <div>
                                                        {{ itemb.htime }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="science-card3-footer3"
                                                >
                                                    <img
                                                        src="../../../assets/pc/Index/location.png"
                                                        alt=""
                                                    />
                                                    <div>****</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <!-- 左侧内容区域分页 -->
                                    <div class="pageDown">
                                        <Pagination
                                            v-if="JSON.stringify() != ''"
                                            :pageNo="searchParams.page"
                                            :pageSize="searchParams.pageSize"
                                            :total="total"
                                            :continues="5"
                                            @getPageNo="getPageEv"
                                        />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Pagination from "@/components/Pagination";
import HeadNav from "@/components/Nav.vue"; //导航
import { catelist, flaglist, activitylist } from "../../../network/data";
import Advertising from "@/components/Advertising.vue"; //广告图

export default {
    // 组件名称
    name: "activity",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前tap索引
            currentIndex: 0,
            // 关于学会tap数据
            machiningTap: [
                {
                    name: "食品加工",
                },
                {
                    name: "科学研究",
                },
                {
                    name: "原辅料",
                },
                {
                    name: "智能装备",
                },
                {
                    name: "冷链云仓",
                },
            ],
            // 助企活动分类
            catelistDe: [],
            // 助企活动状态
            flaglistDe: [],
            // 选中的活动分类
            activCate: "",
            // 选中的助企活动列表
            activeFlag: "",
            // 活动列表
            activitylist: [],
            searchParams: {
                page: 1,
                pageSize: 6,
            },
            total: 10,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取助企活动分类
        this.catelistRe();
        // 获取助企活动状态
        this.flaglistRe();
        // 获取助企活动列表
        this.activitylistRe();
    },
    methods: {
        machiningTapEv(index) {
            // this.surveyData = [];
            this.currentIndex = index;
        },
        // 助企活动分类
        catelistRe() {
            catelist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.catelistDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 助企活动状态
        flaglistRe() {
            flaglist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.flaglistDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取助企活动列表数据
        activitylistRe() {
            let data = {
                menuid: 19,
                cateid: this.activCate,
                flag: this.activeFlag,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            activitylist(data).then((res) => {
                if (res.code == 1) {
                    console.log(res.data, "活动列表");
                    this.activitylist = res.data.list;
                    this.searchParams.page = res.data.list.current_page;
                    this.searchParams.pageSize = res.data.list.per_page;
                    this.total = res.data.list.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 分页触发事件
        getPageEv(index) {
            this.searchParams.page = index;
            this.activitylistRe();
        },
        // 跳转到助企下级界面
        detailsEv(itemb) {
            this.$router.push({
                path: `/science/ActivityDetails`,
                query: { id: itemb.id },
            });
        },
        handleCommand(command) {
            // console.log(command, "全部分类");
            this.activCate = command;
            this.activitylistRe();
        },
        handleflag(command) {
            // console.log(command, "状态");
            this.activeFlag =
                command == "报名中" ? "0" : command == "活动中" ? "1" : "2";
            this.activitylistRe();
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Pagination,
        HeadNav,
        Advertising,
    },
};
</script>

<style lang="scss">
// .el-dropdown-link {
//     color: #333333 !important;
// }

// .el-dropdown-menu {
//     .el-dropdown-menu__item {
//         width: 50px !important;
//         height: 20px;
//         text-align: center;
//         line-height: 20px;
//         font-size: 10px;
//         padding: 0;
//         // border: 1px solid red !important;
//     }
// }

// .activity {
//     width: 100%;

//     .minkave-wrap {
//         width: 70%;
//         // height: 6000px;
//         margin: 0 auto;
//         margin-bottom: 40px;
//         // margin-top: 15px;
//         // border: 1px solid red;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 10px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 1px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 30%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;

//                 .survey-title-cn {
//                     font-size: 10px;
//                     color: #0056b0;
//                     margin-right: 15px;
//                 }

//                 .survey-title-sn {
//                     height: 10px;
//                     line-height: 10px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     background-color: #0056b0;
//                 }
//             }

//             .survey-title-text {
//                 font-size: 10px;
//                 margin-top: 3px;
//             }

//             .survey-title-select1 {
//                 line-height: 0px;
//                 // border: 1px solid red;
//                 .el-dropdown {
//                     margin-right: 20px;
//                     .el-dropdown-link {
//                         cursor: pointer;
//                         font-size: 10px;
//                         color: #409eff;

//                         > i {
//                             margin: 0px;
//                         }
//                     }
//                     .el-icon-arrow-down {
//                         font-size: 12px;
//                     }
//                 }
//             }
//         }
//         /*关于学会tap end*/

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 // height: 300px;
//                 // border: 1px solid red;

//                 // 内容区域左侧
//                 .survey-content-left {
//                     width: 68%;
//                     height: 100%;
//                     // border: 1px solid blueviolet;

//                     .left1 {
//                         width: 100%;

//                         .left-card {
//                             width: 100%;
//                             // 助企名家start
//                             .ul2 {
//                                 display: flex;
//                                 justify-content: space-between;
//                                 flex-wrap: wrap;
//                                 margin-top: 10px;
//                                 list-style: none;

//                                 li {
//                                     width: 46%;
//                                     // padding: 8px 8px;
//                                     box-sizing: border-box;
//                                     margin-bottom: 25px;
//                                     border: 0.5px solid #e6e6e6;

//                                     .science-card {
//                                         width: 100%;
//                                         display: flex;
//                                         flex-direction: column;

//                                         // 助企活动图片
//                                         > img {
//                                             width: 100%;
//                                             height: 90px;
//                                         }

//                                         // 助企活动标题
//                                         .science-card3-title {
//                                             padding: 8px 3px;
//                                             font-size: 9px;
//                                             height: 18px;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                             display: -webkit-box;
//                                             -webkit-line-clamp: 2; //行数
//                                             -webkit-box-orient: vertical;
//                                         }

//                                         // 助企活动描述
//                                         .science-card3-ms {
//                                             height: 12px;
//                                             line-height: 10px;
//                                             padding: 0 3px;
//                                             padding-bottom: 10px;
//                                             font-size: 7px;
//                                             color: #999999;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                             display: -webkit-box;
//                                             -webkit-line-clamp: 2; //行数
//                                             -webkit-box-orient: vertical;
//                                             border-bottom: 0.5px dashed #e6e6e6;
//                                         }

//                                         // 助企活动底部样式
//                                         .science-card3-footer {
//                                             display: flex;
//                                             margin-top: 5px;
//                                             // justify-content: space-between;
//                                             padding: 4px;
//                                             .science-card3-footer1 {
//                                                 text-align: center;
//                                                 width: 30px;
//                                                 height: 13px;
//                                                 line-height: 13px;
//                                                 margin-right: auto;
//                                                 color: #fff;
//                                                 font-size: 6px;
//                                                 background-color: #333333;
//                                             }

//                                             .science-card3-footer2 {
//                                                 display: flex;
//                                                 align-items: center;

//                                                 > img {
//                                                     width: 8px;
//                                                     height: 8px;
//                                                 }

//                                                 > div {
//                                                     margin-left: 3px;
//                                                     font-size: 7.5px;
//                                                     color: #999999;
//                                                 }
//                                             }

//                                             .science-card3-footer3 {
//                                                 display: flex;
//                                                 align-items: center;
//                                                 margin-left: 5px;

//                                                 > img {
//                                                     width: 8px;
//                                                     height: 8px;
//                                                 }

//                                                 > div {
//                                                     margin-left: 3px;
//                                                     font-size: 7.5px;
//                                                     color: #999999;
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                             // 助企名家end

//                             // 左侧内容区域分页
//                             .pageDown {
//                                 width: 100%;
//                                 margin: 20px auto;
//                                 text-align: start;
//                                 // border: 1px solid blue;
//                             }
//                         }
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 30%;
//                     // border: 1px solid red;
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .activity {
//         width: 100%;

//         .minkave-wrap {
//             width: 70%;
//             margin: 0 auto;
//             margin-bottom: 40px;
//             // margin-top: 15px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 10px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 30%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 15px;
//                     }

//                     .survey-title-sn {
//                         height: 10px;
//                         line-height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }
//                 }

//                 .survey-title-text {
//                     font-size: 10px;
//                 }

//                 .survey-title-select1 {
//                     line-height: 0px;
//                     .el-dropdown {
//                         margin-right: 20px;
//                         .el-dropdown-link {
//                             cursor: pointer;
//                             font-size: 10px;
//                             color: #409eff;

//                             > i {
//                                 margin: 0px;
//                             }
//                         }
//                         .el-icon-arrow-down {
//                             font-size: 12px;
//                         }
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     // height: 300px;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 68%;
//                         height: 100%;
//                         // border: 1px solid blueviolet;

//                         .left1 {
//                             width: 100%;

//                             .left-card {
//                                 width: 100%;
//                                 // 助企名家start
//                                 .ul2 {
//                                     display: flex;
//                                     justify-content: space-between;
//                                     flex-wrap: wrap;
//                                     margin-top: 10px;
//                                     list-style: none;

//                                     li {
//                                         width: 46%;
//                                         // padding: 8px 8px;
//                                         box-sizing: border-box;
//                                         margin-bottom: 25px;
//                                         border: 0.5px solid #e6e6e6;
//                                         // border: 1px solid red;

//                                         .science-card {
//                                             width: 100%;
//                                             // height: 140px;
//                                             display: flex;
//                                             flex-direction: column;

//                                             // 助企活动图片
//                                             > img {
//                                                 width: 100%;
//                                                 height: 60px;
//                                             }

//                                             // 助企活动标题
//                                             .science-card3-title {
//                                                 padding: 5px 3px;
//                                                 font-size: 8px;
//                                                 height: 18px;
//                                                 text-overflow: ellipsis;
//                                                 display: -webkit-box;
//                                                 -webkit-line-clamp: 2; //行数
//                                                 -webkit-box-orient: vertical;
//                                                 overflow: hidden;
//                                                 // border: 1px solid red;
//                                             }

//                                             // 助企活动描述
//                                             .science-card3-ms {
//                                                 padding: 0px 3px;
//                                                 font-size: 6px;
//                                                 height: 18px;
//                                                 text-overflow: ellipsis;
//                                                 display: -webkit-box;
//                                                 -webkit-line-clamp: 2; //行数
//                                                 -webkit-box-orient: vertical;
//                                                 overflow: hidden;
//                                                 // border: 1px solid red;
//                                             }

//                                             // 助企活动底部样式
//                                             .science-card3-footer {
//                                                 display: flex;
//                                                 margin-top: 1px;
//                                                 // justify-content: space-between;
//                                                 padding: 4px;
//                                                 .science-card3-footer1 {
//                                                     text-align: center;
//                                                     width: 30px;
//                                                     height: 13px;
//                                                     line-height: 13px;
//                                                     margin-right: auto;
//                                                     color: #fff;
//                                                     font-size: 6px;
//                                                     background-color: #333333;
//                                                 }

//                                                 .science-card3-footer2 {
//                                                     display: flex;
//                                                     align-items: center;
//                                                     display: none;

//                                                     > img {
//                                                         width: 8px;
//                                                         height: 8px;
//                                                     }

//                                                     > div {
//                                                         margin-left: 3px;
//                                                         font-size: 7.5px;
//                                                         color: #999999;
//                                                     }
//                                                 }

//                                                 .science-card3-footer3 {
//                                                     display: flex;
//                                                     align-items: center;
//                                                     margin-left: 5px;

//                                                     > img {
//                                                         width: 8px;
//                                                         height: 8px;
//                                                     }

//                                                     > div {
//                                                         margin-left: 3px;
//                                                         font-size: 7.5px;
//                                                         color: #999999;
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                                 // 助企名家end

//                                 // 左侧内容区域分页
//                                 .pageDown {
//                                     width: 100%;
//                                     margin: 20px auto;
//                                     text-align: start;
//                                     // border: 1px solid blue;
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .activity {
//         width: 100%;

//         .minkave-wrap {
//             width: 70%;
//             // height: 800px;
//             margin: 0 auto;
//             margin-bottom: 40px;
//             // margin-top: 15px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 30px;
//                 margin-top: 3px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 30%;
//                     display: flex;
//                     align-items: center;
//                     margin-right: 5px;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         width: 50px;
//                         font-size: 8px;
//                         color: #0056b0;
//                         margin-right: 5px;
//                         // border: 1px solid red;
//                     }

//                     .survey-title-sn {
//                         height: 10px;
//                         line-height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }
//                 }

//                 .survey-title-text {
//                     font-size: 10px;
//                 }

//                 .survey-title-select1 {
//                     line-height: 0px;
//                     .el-dropdown {
//                         margin-right: 20px;
//                         .el-dropdown-link {
//                             cursor: pointer;
//                             font-size: 10px;
//                             color: #409eff;

//                             > i {
//                                 margin: 0px;
//                             }
//                         }
//                         .el-icon-arrow-down {
//                             font-size: 12px;
//                         }
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     // height: 300px;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 68%;
//                         height: 100%;
//                         // border: 1px solid blueviolet;

//                         .left1 {
//                             width: 100%;

//                             .left-card {
//                                 width: 100%;
//                                 // 助企名家start
//                                 .ul2 {
//                                     display: flex;
//                                     justify-content: space-between;
//                                     flex-wrap: wrap;
//                                     margin-top: 10px;
//                                     list-style: none;

//                                     li {
//                                         width: 48%;
//                                         // padding: 4px 4px;
//                                         box-sizing: border-box;
//                                         margin-bottom: 10px;
//                                         border: 1px solid #e6e6e6;

//                                         .science-card {
//                                             width: 100%;
//                                             display: flex;
//                                             flex-direction: column;

//                                             // 助企活动图片
//                                             > img {
//                                                 width: 100%;
//                                                 height: 50px;
//                                             }

//                                             // 助企活动标题
//                                             .science-card3-title {
//                                                 padding: 0px 3px;
//                                                 padding-top: 5px;
//                                                 font-size: 9px;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                             }

//                                             // 助企活动描述
//                                             .science-card3-ms {
//                                                 height: 10px;
//                                                 line-height: 10px;
//                                                 margin-top: 3px;
//                                                 padding: 0 3px;
//                                                 padding-bottom: 10px;
//                                                 font-size: 7px;
//                                                 color: #999999;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                                 border-bottom: 0.5px dashed
//                                                     #e6e6e6;
//                                             }

//                                             // 助企活动底部样式
//                                             .science-card3-footer {
//                                                 display: flex;
//                                                 margin-top: 3px;
//                                                 // justify-content: space-between;
//                                                 padding: 4px;
//                                                 .science-card3-footer1 {
//                                                     text-align: center;
//                                                     width: 30px;
//                                                     height: 13px;
//                                                     line-height: 13px;
//                                                     margin-right: auto;
//                                                     color: #fff;
//                                                     font-size: 6px;
//                                                     background-color: #333333;
//                                                 }

//                                                 .science-card3-footer2 {
//                                                     display: none !important;
//                                                     display: flex;
//                                                     align-items: center;

//                                                     > img {
//                                                         width: 8px;
//                                                         height: 8px;
//                                                     }

//                                                     > div {
//                                                         margin-left: 3px;
//                                                         font-size: 7.5px;
//                                                         color: #999999;
//                                                         white-space: nowrap;
//                                                         overflow: hidden;
//                                                         text-overflow: ellipsis;
//                                                     }
//                                                 }

//                                                 .science-card3-footer3 {
//                                                     display: flex;
//                                                     align-items: center;
//                                                     margin-left: 5px;

//                                                     > img {
//                                                         width: 8px;
//                                                         height: 8px;
//                                                     }

//                                                     > div {
//                                                         margin-left: 3px;
//                                                         font-size: 7.5px;
//                                                         color: #999999;
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                                 // 助企名家end

//                                 // 左侧内容区域分页
//                                 .pageDown {
//                                     width: 100%;
//                                     margin: 20px auto;
//                                     text-align: start;
//                                     // border: 1px solid blue;
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
