import { render, staticRenderFns } from "./Minkave.vue?vue&type=template&id=02988522&scoped=true&"
import script from "./Minkave.vue?vue&type=script&lang=js&"
export * from "./Minkave.vue?vue&type=script&lang=js&"
import style0 from "./Minkave.vue?vue&type=style&index=0&id=02988522&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02988522",
  null
  
)

export default component.exports