<template>
    <!-- 公共右侧组件 -->
    <div class="right">
        <div class="right-content">
            <!-- 右侧热门专题 -->
            <div class="survey-right-title">
                <div>热门专题</div>
                <!-- 右侧热门专题内容 -->
                <ul>
                    <li v-for="(item, index) in rightDe.hotlist" :key="index" @click="goMachining(item.id,item.menu_id,index)">
                        <div class="survey-right-naws">
                            {{ item.title }}
                        </div>
                        <div class="survey-right-img">
                            <img :src="item.image" />
                        </div>
                    </li>
                    <!-- <li></li> -->
                </ul>
            </div>

            <!-- 新闻排行榜 -->
            <div class="survey-right-news">
                <div>新闻排行榜</div>
                <ul>
                    <li
                        :key="indexa"
                        @click="goNewsDetail(indexa, itema.id)"
                        v-for="(itema, indexa) in rightDe.newslist"
                    >
                        <div>{{ indexa + 1 }}</div>
                        <div>{{ itema.title }}</div>
                    </li>
                </ul>

                <ul>
                    <li v-for="(itemc, indexc) in image" :key="indexc">
                        <img  :src="itemc.image" alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { right, advimage,advimagenew } from "../network/data";
export default {
    // 组件名称
    name: "right",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            image:'',
            // 每个页面右侧内容数据
            rightDe: {},
            // 广告图数据
            advimageDe: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取每个页面右侧内容
        this.rightRe();
        // 获取广告图
        this.advimageRe();
        this.getImage()
    },
    methods: {
          //获取广告图
          getImage() {
            let data = {
                type:1
            }
            advimagenew(data).then(res=>{
                if(res.code==1) {
                   this.image = res.data.listadvone
                   console.log(this.image,'this.image')
                }
            })
        },
        // 获取每个页面右侧内容
        rightRe() {
            right().then((res) => {
                if (res.code == 1) {
                    this.rightDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取广告图
        advimageRe() {
            advimage().then((res) => {
                if (res.code == 1) {
                    this.advimageDe = res.data.listadvone;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 跳转到学会新闻页面special/specialDetail
        goMachining(id,menu,type) {
            this.$router.push({
                path: `/special/specialDetail/${type}`,
                query: {
                    ids: id,
                    menu_id:menu
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到学会新闻详情页{
        goNewsDetail(type, id) {
            this.$router.push({
                path: `/about/newsDetail/${type}`,
                query: {
                    ids: id,
                },
            });
            window.scrollTo(0, 0);
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {},
};
</script>

<style scoped lang="scss">
// .right {
//     width: 100%;
//     // 内容区域右侧
//     .right-content {
//         width: 100%;
//         margin-top: 10px;
//         // margin-bottom: 40px;
//         // border: 1px solid red;

//         // 右侧热门专题
//         .survey-right-title {
//             > div {
//                 font-size: 12px;
//                 padding-bottom: 10px;
//                 border-bottom: 1px solid #cacaca;
//             }

//             > ul {
//                 list-style: none;
//                 cursor: pointer;

//                 li:hover {
//                     color: #0056b0;
//                 }

//                 li {
//                     margin-bottom: 10px;
//                     .survey-right-naws {
//                         padding: 5px 0;
//                         font-size: 10px;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }

//                     .survey-right-img {
//                         height: 115px;
//                         width: 100%;

//                         img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }
//                 }
//             }
//         }

//         // 右侧新闻排行榜
//         .survey-right-news {
//             margin-top: 20px;
//             > div {
//                 font-size: 12px;
//                 padding-bottom: 10px;
//                 border-bottom: 1px solid #cacaca;
//             }

//             > ul:nth-child(2) {
//                 list-style: none;
//                 margin-bottom: 20px;
//                 // border: 1px solid red;

//                 li {
//                     display: flex;
//                     align-items: center;
//                     justify-content: space-between;
//                     margin-top: 10px;
//                     width: 100%;
//                     font-size: 10px;
//                     cursor: pointer;
//                     // border: 1px solid red;

//                     > div:nth-child(1) {
//                         width: 8%;
//                         height: 14px;
//                         text-align: center;
//                         border-radius: 2px;
//                         color: #fff;
//                         background-color: #0056b0;
//                     }

//                     > div:nth-child(2) {
//                         width: 90%;
//                         font-size: 9px;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         white-space: nowrap;
//                     }
//                 }
//             }

//             > ul:nth-child(3) {
//                 list-style: none;
//                 margin-top: 20px;
//                 // border: 1px solid red;

//                 li {
//                     width: 100%;
//                     height: 100px;
//                     margin-top: 10px;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .right {
//         width: 100%;
//         // 内容区域右侧
//         .right-content {
//             width: 100%;
//             margin-top: 10px;
//             // border: 1px solid red;

//             // 右侧热门专题
//             .survey-right-title {
//                 > div {
//                     font-size: 10px;
//                     padding-bottom: 5px;
//                     border-bottom: 0.8px solid #cacaca;
//                 }

//                 > ul {
//                     list-style: none;
//                     cursor: pointer;

//                     li {
//                         margin-bottom: 5px;
//                         .survey-right-naws {
//                             padding: 5px 0;
//                             font-size: 8px;
//                         }

//                         .survey-right-img {
//                             height: 80px;
//                             width: 100%;
//                             // border: 1px solid red;

//                             img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }
//                     }
//                 }
//             }

//             // 右侧新闻排行榜
//             .survey-right-news {
//                 margin-top: 20px;
//                 > div {
//                     font-size: 10px;
//                     padding-bottom: 5px;
//                     border-bottom: 0.8px solid #cacaca;
//                 }

//                 > ul:nth-child(2) {
//                     list-style: none;
//                     margin-bottom: 20px;
//                     // border: 1px solid red;

//                     li {
//                         display: flex;
//                         align-items: center;
//                         justify-content: space-between;
//                         margin-top: 5px;
//                         width: 100%;
//                         font-size: 10px;
//                         cursor: pointer;
//                         // border: 1px solid red;

//                         > div:nth-child(1) {
//                             width: 12px;
//                             height: 12px;
//                             line-height: 12px;
//                             font-size: 8px;
//                             text-align: center;
//                             border-radius: 1px;
//                             color: #fff;
//                             background-color: #0056b0;
//                         }

//                         > div:nth-child(2) {
//                             margin-left: 2px;
//                             font-size: 8px;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                             white-space: nowrap;
//                         }
//                     }
//                 }

//                 > ul:nth-child(3) {
//                     list-style: none;

//                     li {
//                         width: 100%;
//                         height: 60px;
//                         margin-top: 10px;

//                         img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .right {
//         width: 100%;
//         // 内容区域右侧
//         .right-content {
//             width: 100%;
//             margin-top: 10px;
//             // border: 1px solid red;

//             // 右侧热门专题
//             .survey-right-title {
//                 > div {
//                     font-size: 10px;
//                     padding-bottom: 5px;
//                     border-bottom: 0.8px solid #cacaca;
//                 }

//                 > ul {
//                     list-style: none;
//                     cursor: pointer;

//                     li {
//                         margin-bottom: 5px;
//                         .survey-right-naws {
//                             padding: 5px 0;
//                             font-size: 8px;
//                         }

//                         .survey-right-img {
//                             height: 80px;
//                             width: 100%;
//                             // border: 1px solid red;

//                             img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }
//                     }
//                 }
//             }

//             // 右侧新闻排行榜
//             .survey-right-news {
//                 margin-top: 20px;
//                 > div {
//                     font-size: 10px;
//                     padding-bottom: 5px;
//                     border-bottom: 0.8px solid #cacaca;
//                 }

//                 > ul:nth-child(2) {
//                     list-style: none;
//                     margin-bottom: 20px;
//                     // border: 1px solid red;

//                     li {
//                         display: flex;
//                         align-items: center;
//                         justify-content: space-between;
//                         margin-top: 5px;
//                         width: 100%;
//                         font-size: 10px;
//                         cursor: pointer;
//                         // border: 1px solid red;

//                         > div:nth-child(1) {
//                             width: 12px;
//                             height: 12px;
//                             line-height: 12px;
//                             font-size: 8px;
//                             text-align: center;
//                             border-radius: 1px;
//                             color: #fff;
//                             background-color: #0056b0;
//                         }

//                         > div:nth-child(2) {
//                             margin-left: 2px;
//                             font-size: 8px;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                             white-space: nowrap;
//                         }
//                     }
//                 }

//                 > ul:nth-child(3) {
//                     list-style: none;

//                     li {
//                         width: 100%;
//                         height: 60px;
//                         margin-top: 10px;

//                         img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
