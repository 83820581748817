<template>
    <!-- 学院教授风采 -->
    <div class="academy-Demeanour">
        <AHeader :currentIndex="3"></AHeader>
        <MeSwiper></MeSwiper>

        <!-- 内容区域 -->
        <div class="academy-Demeanour-content">
            <div>您当前的位置：首页>教授风采</div>
            <div>
                <h6>教授</h6>
                <span>风采</span>
                <ul>
                    <li
                        :key="index"
                        @click="teachcateEv(item, index)"
                        v-for="(item, index) in teachcate"
                        :class="
                            teachcateCurrent == index ? 'activeCurrent' : ''
                        "
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li
                        v-for="(item, index) in teachsList"
                        @click="goAcademyDetail(item)"
                        :key="index"
                    >
                        <div>
                            <img :src="item.image" alt="" />
                        </div>
                        <div>
                            <span>{{ item.title }}</span>
                            <span>{{ item.rank }}</span>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 左侧内容区域分页 -->
            <div class="pageDown">
                <Pagination
                    :pageNo="searchParams.page"
                    :pageSize="searchParams.pageSize"
                    :total="total"
                    :continues="5"
                    @getPageNo="getPageEv"
                />
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import AHeader from "@/components/Aheader.vue";
import Footer from "@/components/Footer.vue";
import Pagination from "@/components/Pagination";
import MeSwiper from "../../../components/College/swiper.vue";
import { teachcate, teachs } from "../../../network/data";
export default {
    // 组件名称
    name: "Demeanour",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 分页
            searchParams: {
                page: 1,
                pageSize: 10,
            },
            total: 10,
            // 院校导航栏id
            menu_id: "",
            // 院校教授风采分类
            teachcate: [],
            // 当前教授风采列表索引
            teachcateCurrent: 0,
            // 当前教授风采默认选中分类
            cateid: "",
            // 院校教授风采列表数据
            teachsList: [],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;

            // 获取院校教授风采数据
            this.teachcateRe();
            // this.teachsRe();
        }
    },
    methods: {
        // 院校教授风采分类切换
        teachcateEv(item, index) {
            this.teachcateCurrent = index;
            this.cateid = item.id;
            this.searchParams.page = 1;
            this.searchParams.pageSize = 5;
            this.teachsRe();
        },
        // 获取院校教授风采分类数据
        teachcateRe() {
            let data = {
                id: sessionStorage.getItem("school_id"),
                menuid: this.menu_id,
            };
            teachcate(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.teachcate = res.data.list;
                    this.cateid = res.data.list[0].id;
                    this.teachsRe();
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取院校教授风采列表数据
        teachsRe() {
            let data = {
                id: sessionStorage.getItem("school_id"),
                menuid: this.menu_id,
                cateid: this.cateid,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            teachs(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.searchParams.page = res.data.current_page;
                    this.searchParams.pageSize = res.data.per_page;
                    this.total = res.data.total;
                    this.teachsList = res.data.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 分页点击事件
        getPageEv(e) {
            this.searchParams.page = e;
            this.teachsRe();
        },
        // 跳转到院校详情页
        goAcademyDetail(item) {
            this.$router.push({
                path: "/AcademyDetail",
                query: {
                    sonId: item.id,
                    // 导航栏选中样式索引
                    current: "3",
                },
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: { AHeader, Footer, Pagination, MeSwiper },
};
</script>

<style scoped lang="scss">
// .academy-Demeanour {
//     .academy-Demeanour-img {
//         width: 100%;
//         height: 150px;

//         > img {
//             width: 100%;
//             height: 100%;
//         }
//     }

//     .academy-Demeanour-content {
//         width: 70%;
//         margin: 0 auto;

//         > div:nth-child(1) {
//             margin: 10px 0 10px 0;
//             font-size: 8px;
//             color: #999999;
//         }

//         > div:nth-child(2) {
//             display: flex;
//             padding: 5px 0;
//             box-sizing: border-box;
//             border-bottom: 1px solid #0056b0;

//             > h6 {
//                 font-size: 12px;
//                 color: #0056b0;
//             }

//             > span {
//                 margin-top: 1px;
//                 margin-left: 3px;
//                 font-size: 8px;
//             }

//             > ul {
//                 list-style: none;
//                 display: flex;
//                 margin-left: auto;

//                 .activeCurrent {
//                     color: #0056b0;
//                 }

//                 li {
//                     cursor: pointer;
//                     margin-left: 30px;
//                     font-size: 10px;
//                 }
//             }
//         }

//         > div:nth-child(3) {
//             // height: 300px;

//             > ul {
//                 list-style: none;
//                 display: flex;
//                 justify-content: space-between;
//                 flex-wrap: wrap;
//                 // margin-top:30px;
//                 padding: 0 30px;
//                 box-sizing: border-box;

//                 li {
//                     cursor: pointer;
//                     width: 15%;
//                     height: 120px;
//                     margin: 0 10px;
//                     margin-top: 30px;
//                     box-sizing: border-box;
//                     border: 1px solid #999999;

//                     > div:nth-child(1) {
//                         height: 77%;

//                         > img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }

//                     > div:nth-child(2) {
//                         height: 23%;
//                         font-size: 10px;

//                         > span:nth-child(1) {
//                             display: block !important;
//                             text-align: center;
//                             margin-top: 2px;
//                             font-size: 10px;
//                         }
//                         > span:nth-child(2) {
//                             display: block;
//                             text-align: center;
//                             font-size: 9px;
//                             color: #999999;
//                         }
//                     }
//                 }
//             }
//         }

//         .pageDown {
//             display: flex;
//             justify-content: center;
//             margin: 20px;
//         }
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .academy-Demeanour {
//         .academy-Demeanour-img {
//             width: 100%;
//             height: 150px;

//             > img {
//                 width: 100%;
//                 height: 100%;
//             }
//         }

//         .academy-Demeanour-content {
//             width: 70%;
//             margin: 0 auto;

//             > div:nth-child(1) {
//                 margin: 10px 0 10px 0;
//                 font-size: 8px;
//                 color: #999999;
//             }

//             > div:nth-child(2) {
//                 display: flex;
//                 padding: 5px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #0056b0;

//                 > h6 {
//                     font-size: 12px;
//                     color: #0056b0;
//                 }

//                 > span {
//                     margin-top: 1px;
//                     margin-left: 3px;
//                     font-size: 8px;
//                 }

//                 > ul {
//                     list-style: none;
//                     display: flex;
//                     margin-left: auto;

//                     .activeCurrent {
//                         color: #0056b0;
//                     }

//                     li {
//                         cursor: pointer;
//                         margin-left: 30px;
//                         font-size: 10px;
//                     }
//                 }
//             }

//             > div:nth-child(3) {
//                 // height: 300px;

//                 > ul {
//                     list-style: none;
//                     display: flex;
//                     justify-content: space-between;
//                     flex-wrap: wrap;
//                     // margin-top:30px;
//                     padding: 0 30px;
//                     box-sizing: border-box;

//                     li {
//                         cursor: pointer;
//                         width: 18%;
//                         height: 100px;
//                         margin: 0 10px;
//                         margin-top: 30px;
//                         box-sizing: border-box;
//                         border: 1px solid #999999;

//                         > div:nth-child(1) {
//                             height: 77%;

//                             > img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }

//                         > div:nth-child(2) {
//                             height: 23%;
//                             font-size: 8px;

//                             > span:nth-child(1) {
//                                 display: block !important;
//                                 text-align: center;
//                                 margin-top: 2px;
//                                 font-size: 8px;
//                             }
//                             > span:nth-child(2) {
//                                 display: block;
//                                 text-align: center;
//                                 font-size: 7px;
//                                 color: #999999;
//                             }
//                         }
//                     }
//                 }
//             }

//             .pageDown {
//                 display: flex;
//                 justify-content: center;
//                 margin: 20px;
//             }
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .academy-Demeanour {
//         .academy-Demeanour-img {
//             width: 100%;
//             height: 150px;

//             > img {
//                 width: 100%;
//                 height: 100%;
//             }
//         }

//         .academy-Demeanour-content {
//             width: 70%;
//             height: 60vh;
//             margin: 0 auto;

//             > div:nth-child(1) {
//                 margin: 10px 0 10px 0;
//                 font-size: 8px;
//                 color: #999999;
//             }

//             > div:nth-child(2) {
//                 display: flex;
//                 padding: 5px 0;
//                 border-bottom: 1px solid #0056b0;

//                 > h6 {
//                     font-size: 12px;
//                     color: #0056b0;
//                 }

//                 > span {
//                     margin-top: 1px;
//                     margin-left: 3px;
//                     font-size: 8px;
//                 }

//                 > ul {
//                     list-style: none;
//                     display: flex;
//                     margin-left: auto;

//                     .activeCurrent {
//                         color: #0056b0;
//                     }

//                     li {
//                         cursor: pointer;
//                         width: 50px;
//                         margin-left: 10px;
//                         font-size: 10px;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             > div:nth-child(3) {
//                 // height: 300px;

//                 > ul {
//                     list-style: none;
//                     display: flex;
//                     justify-content: space-between;
//                     flex-wrap: wrap;
//                     // margin-top:30px;
//                     padding: 0 20px;
//                     box-sizing: border-box;

//                     li {
//                         width: 18%;
//                         height: 90px;
//                         margin: 0 10px;
//                         margin-top: 20px;
//                         // border: 1px solid red;

//                         > div:nth-child(1) {
//                             height: 77%;

//                             > img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }

//                         > div:nth-child(2) {
//                             height: 23%;
//                             font-size: 10px;

//                             > span:nth-child(1) {
//                                 display: block !important;
//                                 text-align: center;
//                                 margin-top: 2px;
//                                 font-size: 7px;
//                             }
//                             > span:nth-child(2) {
//                                 display: block;
//                                 text-align: center;
//                                 font-size: 6px;
//                                 color: #999999;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
