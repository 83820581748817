// 导入封装好的Axios
import { request } from "./request"; //注意request.js的相对路径问题

//获取导航栏数据
export function menu() {
    return request({
        url: "/index/menu",
        method: "get",
    });
}

// 获取首页快讯数据数据
export function noticeimage() {
    return request({
        url: "/index/noticeimage",
        method: "get",
    });
}

// 获取首页快讯数据数据
export function alerts() {
    return request({
        url: "/index/alerts",
        method: "get",
    });
}

// 获取首页通知公告列表数据
export function noticelist() {
    return request({
        url: "/index/noticelist",
        method: "get",
    });
}

// 获取首页产业智造数据
export function makeold() {
    return request({
        url: "/index/makeold",
        method: "get",
    });
}

// 获取数智金融数据
export function make() {
    return request({
        url: "/index/topic",
        method: "get",
        params: {},
        data: {},
    });
}

// 获取首页关于学会-学会概况数据
export function about() {
    return request({
        url: "/index/about",
        method: "get",
    });
}

//获取首页行业标准数据
export function norms() {
    return request({
        url: "/index/norms",
        method: "get",
    });
}

// 获取首页产业观察数据
export function observe() {
    return request({
        url: "/index/observe",
        method: "get",
    });
}

// 获取首页食品资讯数据
export function foods() {
    return request({
        url: "/index/foods",
        method: "get",
    });
}

// 获取底部友情链接
export function links() {
    return request({
        url: "/index/links",
        method: "get",
    });
}

// 获取首页搜素
export function search(data) {
    return request({
        url: "/index/search",
        method: "get",
        params: data,
    });
}

// 获取首页科技赋能数据
export function science() {
    return request({
        url: "/index/science",
        method: "get",
        params: {},
        data: {},
    });
}

// 获取关于学会概况数据
export function abouts() {
    return request({
        url: "/about/abouts",
        method: "get",
        params: {
            menuid: 2,
        },
    });
}

// 获取关于学会章程数据
export function process() {
    return request({
        url: "/about/process",
        method: "get",
        params: {
            menuid: 3,
        },
        data: {},
    });
}

// 获取入会申请数据
export function apply() {
    return request({
        url: "/about/apply",
        method: "get",
        params: {
            menuid: 6,
        },
        data: {},
    });
}

// 获取学会工作数据
export function works(data) {
    return request({
        url: "/about/works",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取学会工作详情数据
export function workdetail(data) {
    return request({
        url: "/about/workdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取分支机构数据
export function branchs(data) {
    return request({
        url: "/about/branchs",
        method: "get",
        params: data,
        data: {},
    });
}

// 分支机构详情页
export function branchdetail(data) {
    return request({
        url: "/about/branchdetail",
        method: "get",
        params: data,
        data: {},
    });
}

//获取通知通告数据
export function notices(data) {
    return request({
        url: "/about/notices",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取通知通告详情页数据
export function noticedetail(data) {
    return request({
        url: "/about/noticedetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取学会要闻数据
export function news(data) {
    return request({
        url: "/about/news",
        method: "get",
        params: data,
        data: {},
    });
}

// 学会要闻详情页数据
export function newdetail(data) {
    return request({
        url: "/about/newdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取产业制造面包屑导航栏
export function navlist(id) {
    return request({
        url: "/index/navlist",
        method: "get",
        params: {
            menuid: id,
        },
        data: {},
    });
}

// 获取产业智造列表数据
export function makelist(data) {
    return request({
        url: "/make/makelist",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取产业智造详情页数据
export function makedetail(data) {
    // console.log(data);
    return request({
        url: "/make/makedetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取产业专题数据
export function topiclist(data) {
    return request({
        url: "/topic/topiclist",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取产业专题详情页数据
export function topicdetail(data) {
    return request({
        url: "/topic/topicdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取产业观察列表数据
export function observelist(data) {
    return request({
        url: "/observe/observelist",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取产业观察详情页数据
export function observedetail(data) {
    return request({
        url: "/observe/observedetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 获取行业标准导航
export function normnav() {
    return request({
        url: "/norm/normnav",
        method: "get",
        params: {
            menuid: 40,
        },
        data: {},
    });
}

// 获取行业标准列表
export function normlist(data) {
    return request({
        url: "/norm/normlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 行业标准详情页
export function normdetail(data) {
    return request({
        url: "/norm/normdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 政策规范列表数据
export function lawlist(data) {
    return request({
        url: "/norm/lawlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 政策法规详情页书
export function lawdetail(data) {
    return request({
        url: "/norm/lawdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 食品咨询列表数据
export function foodnewlist(data) {
    return request({
        url: "/foodnews/foodnewlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 食品咨询详情页数据
export function foodnewdetail(data) {
    return request({
        url: "/foodnews/foodnewdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 页面右侧内容
export function right() {
    return request({
        url: "/make/makedetail",
        method: "get",
        params: {
            menuid: 11,
            id: 1,
        },
        data: {},
    });
}

// 获取广告图
export function advimage() {
    return request({
        url: "/index/advimage",
        method: "get",
        params: {},
        data: {},
    });
}

// 获取站点信息
export function site() {
    return request({
        url: "/index/site",
        method: "get",
        params: {},
        data: {},
    });
}

// 登录
export function login(data) {
    console.log(data, "登录");
    return request({
        url: "/user/login",
        method: "post",
        data: data,
    });
}

// 首页企业会员
export function companys() {
    return request({
        url: "/index/companys",
        method: "get",
        params: {},
        data: {},
    });
}

// 关于学会-企业会员
export function companycatelist(data) {
    return request({
        url: "/about/companycatelist",
        method: "get",
        params: data,
    });
}

// 企业会员导航栏
export function company() {
    return request({
        url: "/company/menu",
        method: "get",
        params: {},
        data: {},
    });
}

// 企业会员轮播图
export function companyBanner(data) {
    return request({
        url: "/company/banner",
        method: "get",
        params: data,
    });
}

// 企业会员首页数据
export function companyIndex(data) {
    return request({
        url: "/company/index",
        method: "get",
        params: data,
    });
}

// 企业会员留言原因
export function messagecause() {
    return request({
        url: "/company/messagecause",
        method: "get",
        params: {},
        data: {},
    });
}

// 企业会员留言
export function message(data) {
    return request({
        url: "/company/message",
        method: "post",
        data: data,
    });
}

// 企业会员公司信息
export function companye(data) {
    return request({
        url: "/company/company",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业会员公司简介
export function companyAbout(data) {
    return request({
        url: "/company/about",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业会员导航栏
export function companyMneu() {
    return request({
        url: "/company/menu",
        method: "get",
        params: {},
        data: {},
    });
}

// 企业会员公司荣誉
export function companyHonors(data) {
    return request({
        url: "/company/honors",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业会员产品展示
export function productcate(data) {
    return request({
        url: "/company/productcate",
        method: "get",
        params: data,
        data: {},
    });
}

// 产品展示列表数据
export function products(data) {
    return request({
        url: "/company/products",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业会员产品展示详情
export function productdetail(data) {
    return request({
        url: "/company/productdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业会员新闻列表
export function companyNews(data) {
    return request({
        url: "/company/news",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业会员新闻详情页
export function companyNewdetail(data) {
    return request({
        url: "/company/newdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业会员招聘数据
export function companyJobs(data) {
    return request({
        url: "/company/jobs",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校信息
export function schoolInfo(data) {
    return request({
        url: "/school/school",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校分类
export function schoolcate() {
    return request({
        url: "/school/schoolcate",
        method: "get",
        params: {},
        data: {},
    });
}

// 院校导航栏数据
export function schoolMenu() {
    return request({
        url: "/school/menu",
        method: "get",
        params: {},
        data: {},
    });
}

// 院校banner
export function schoolBanner(data) {
    return request({
        url: "/school/banner",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校首页数据
export function schoolIndex(data) {
    return request({
        url: "/school/index",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校概况
export function schoolAbout(data) {
    return request({
        url: "/school/about",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校科研平台
export function researchs(data) {
    return request({
        url: "/school/researchs",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校科研平台详情页
export function researchdetail(data) {
    return request({
        url: "/school/researchdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校教授风采分类
export function teachcate(data) {
    return request({
        url: "/school/teachcate",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校教授风采列表
export function teachs(data) {
    return request({
        url: "/school/teachs",
        method: "get",
        params: data,
        data: {},
    });
}

// 院校动态
export function schoolNews(data) {
    return request({
        url: "/school/news",
        method: "get",
        params: data,
        data: {},
    });
}

// 设置登录名称
export function register(data) {
    return request({
        url: "/user/register",
        method: "post",
        params: {},
        data: data,
    });
}

// 公司类型
export function companytype() {
    return request({
        url: "/user/companytype",
        method: "get",
        params: {},
        data: {},
    });
}

// 公司分类
export function companycate(data) {
    return request({
        url: "/user/companycate",
        method: "get",
        params: data,
        data: {},
    });
}

// 完善企业信息
export function moldCompany(data) {
    return request({
        url: "/user/company",
        method: "post",
        params: {},
        data: data,
    });
}

// 个人会员身份列表
export function identity() {
    return request({
        url: "/user/identity",
        method: "get",
        params: {},
        data: {},
    });
}

// 技能方向列表
export function skills() {
    return request({
        url: "/user/skills",
        method: "get",
        params: {},
        data: {},
    });
}

// 填写个人资料
export function per(data) {
    return request({
        url: "/user/member",
        method: "post",
        params: {},
        data: data,
    });
}

// 上传图片
export function upload(data) {
    return request({
        url: "/common/upload",
        method: "post",
        params: {},
        data: data,
    });
}

// 助企名家面包屑
export function memberNavlist(data) {
    return request({
        url: "/member/navlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业名家列表
export function memberlist(data) {
    return request({
        url: "/member/memberlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 企业名家详情导航栏数据
export function detailnavlist(data) {
    return request({
        url: "/member/detailnavlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-专业介绍
export function memberdetail(data) {
    return request({
        url: "/member/memberdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-论文论著
export function worklist(data) {
    return request({
        url: "/member/worklist",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-论文论著详情页
export function workdetailw(data) {
    return request({
        url: "/member/workdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-成果课题列表
export function outcomelist(data) {
    return request({
        url: "/member/outcomelist",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-成果课题详情
export function outcomedetail(data) {
    return request({
        url: "/member/outcomedetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-助企交流列表
export function newlist(data) {
    return request({
        url: "/member/newlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-助企交流详情页
export function wnewdetail(data) {
    return request({
        url: "/member/newdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企名家-授课视频列表
export function courselist(data) {
    return request({
        url: "/member/courselist",
        method: "get",
        params: data,
        data: {},
    });
}

// 发布专家介绍
export function setmembercontent(data) {
    return request({
        url: "/member/setmembercontent",
        method: "post",
        params: {},
        data: data,
    });
}

// 发布论文论著
export function setwork(data) {
    return request({
        url: "/member/setwork",
        method: "post",
        params: {},
        data: data,
    });
}

// 发布成果课题
export function setoutcome(data) {
    return request({
        url: "/member/setoutcome",
        method: "post",
        params: {},
        data: data,
    });
}

// 发布助企交流
export function setnew(data) {
    return request({
        url: "/member/setnew",
        method: "post",
        params: {},
        data: data,
    });
}

// 发布授权视频
export function setcourse(data) {
    return request({
        url: "/member/setcourse",
        method: "post",
        params: {},
        data: data,
    });
}

// 助企活动分类
export function catelist() {
    return request({
        url: "/activity/catelist",
        method: "get",
        params: {},
        data: {},
    });
}

// 助企活动状态
export function flaglist() {
    return request({
        url: "/activity/flaglist",
        method: "get",
        params: {},
        data: {},
    });
}

// 助企业活动列表
export function activitylist(data) {
    return request({
        url: "/activity/activitylist",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企活动详情页分类
export function navlistify() {
    return request({
        url: "/activity/navlist",
        method: "get",
        params: {},
        data: {},
    });
}

// 助企活动详情分类列表
export function detailactivitylist(data) {
    return request({
        url: "/activity/detailactivitylist",
        method: "get",
        params: data,
        data: {},
    });
}

// 助企活动详情页
export function activityDetail(data) {
    return request({
        url: "/activity/detail",
        method: "get",
        params: data,
        data: {},
    });
}

// 疑难解答
export function problemlist(data) {
    return request({
        url: "/member/problemlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 产业需求导航栏
export function needNavlist(data) {
    return request({
        url: "/need/navlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 产业需求-技术难题列表
export function needProblemlist(data) {
    return request({
        url: "/need/problemlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 产业需求-技术难题分类
export function needCatelist() {
    return request({
        url: "/need/catelist",
        method: "get",
        params: {},
        data: {},
    });
}
// 产业需求-技术难题分类
export function uselist() {
    return request({
        url: "/member/uselist",
        method: "get",
        params: {},
        data: {},
    });
}

// 产业需求-技术难题详情页
export function problemdetail(data) {
    return request({
        url: "/need/problemdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 产业需求-技术难题发布
export function setproblem(data) {
    return request({
        url: "/need/setproblem",
        method: "post",
        params: {},
        data: data,
    });
}

// 产业需求-技术难题解答
export function setanswer(data) {
    return request({
        url: "/need/setanswer",
        method: "post",
        params: {},
        data: data,
    });
}

// 发布成果转化
export function needSetoutcome(data) {
    return request({
        url: "/need/setoutcome",
        method: "post",
        params: {},
        data: data,
    });
}

// 成果转换标签
export function outcomescate() {
    return request({
        url: "/member/outcomescate",
        method: "get",
        params: {},
        data: {},
    });
}

// 成果转换列表
export function needOutcomelist(data) {
    return request({
        url: "/need/outcomelist",
        method: "get",
        params: data,
        data: {},
    });
}

// 成果转化详情页
export function needOutcomedetail(data) {
    return request({
        url: "/need/outcomedetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 就业招聘列表
export function joblist(data) {
    return request({
        url: "/need/joblist",
        method: "get",
        params: data,
        data: {},
    });
}

// 就业招聘详情页
export function jobdetail(data) {
    return request({
        url: "/need/jobdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 招商引资列表
export function attractlist(data) {
    return request({
        url: "/need/attractlist",
        method: "get",
        params: data,
        data: {},
    });
}

// 招商引资详情页
export function attractdetail(data) {
    return request({
        url: "/need/attractdetail",
        method: "get",
        params: data,
        data: {},
    });
}
// 产学研合作列表
export function teamworklist(data) {
    return request({
        url: "/need/teamworklist",
        method: "get",
        params: data,
        data: {},
    });
}

// 产学合作详情页
export function teamworkdetail(data) {
    return request({
        url: "/need/teamworkdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 就业招聘职位方向
export function jobcate() {
    return request({
        url: "/need/jobcate",
        method: "get",
        params: {},
        data: {},
    });
}

// 经历
export function needWorklist(data) {
    return request({
        url: "/need/worklist",
        method: "get",
        params: data,
        data: {},
    });
}

// 学历
export function schoollist() {
    return request({
        url: "/need/schoollist",
        method: "get",
        params: {},
        data: {},
    });
}

// 薪资
export function wagelist() {
    return request({
        url: "/need/wagelist",
        method: "get",
        params: {},
        data: {},
    });
}

// 招聘发布
export function getetjob(data) {
    return request({
        url: "/need/getetjob",
        method: "post",
        params: {},
        data: data,
    });
}

// 就业发布
export function setjob(data) {
    return request({
        url: "/need/setjob",
        method: "post",
        params: {},
        data: data,
    });
}

// 企业会员省信息
export function areasheng() {
    return request({
        url: "/company/areasheng",
        method: "get",
        params: {},
        data: {},
    });
}

// 企业会员市信息
export function areacity(data) {
    return request({
        url: "/company/areacity",
        method: "get",
        params: data,
        data: {},
    });
}

// 广告页学院列表
export function schoollistw(data) {
    return request({
        url: "/school/schoollist",
        method: "get",
        params: data,
        data: {},
    });
}

// 学院动态详情数据
export function schoolNewdetail(data) {
    return request({
        url: "/school/newdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 教授风采详情页数据
export function tschoolTeachdetail(data) {
    return request({
        url: "/school/teachdetail",
        method: "get",
        params: data,
        data: {},
    });
}

// 关于-企业会员新增
export function companylist(data) {
    return request({
        url: "/about/companylist",
        method: "get",
        params: data,
        data: {},
    });
}

// 首页广告图
export function advlist() {
    return request({
        url: "/index/advlist",
        method: "get",
        params: {},
        data: {},
    });
}

// 获取首页政策法规
export function laws() {
    return request({
        url: "/index/laws",
        method: "get",
        params: {},
        data: {},
    });
}

// 首页下面企业会员
export function downcompanys() {
    return request({
        url: "/index/downcompanys",
        method: "get",
        params: {},
        data: {},
    });
}
//应用领域
export function yingyonglist() {
    return request({
        url: "/need/uselist",
        method: "get",
        params: {},
        data: {},
    });
}
//企业会员搜索
export function qieyeSearch(data) {
    return request({
        url: "/about/companylist",
        method: "get",
        params: data,
    });
}
//证书
export function cardList(data) {
    return request({
        url: "/index/cardList",
        method: "post",
        params: {},
        data: data,
    });
}
//头部背景
export function getBanner() {
    return request({
        url: "/index/bannernew",
        method: "get",
        params: {},
        data: {},
    });
}
//行业标准搜索
export function hangYeSearch(data) {
    console.log(data, "1111111111");
    return request({
        url: "/norm/normlsearch",
        method: "get",
        params: data,
    });
}
//学院分类
export function schoolCate() {
    return request({
        url: "/school/schoolcate",
        method: "get",
        params: {},
        data: {},
    });
}

//学院
export function tabSchoolList(data) {
    return request({
        url: "/school/schoollist",
        method: "get",
        params: data,
    });
}
//学院
export function advimagenew(data) {
    return request({
        url: "/index/advimagenew",
        method: "get",
        params: data,
    });
}
//学院topbanner
export function topbanner(data) {
    return request({
        url: "/school/topbanner",
        method: "get",
        params: data,
    });
}

//学院topbanner
export function cardCateList(data) {
    return request({
        url: "/index/cardCateList",
        method: "get",
        params: data,
    });
}