<template>
    <div class="memberSwiper">
        <el-carousel indicator-position="outside">
            <el-carousel-item :key="index" v-for="(item, index) in swiperData">
                <img :src="item.image" />
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import { companyBanner } from "../../network/data";
export default {
    // 组件名称
    name: "请输入组件名称",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 首页轮播图数据
            swiperData: [],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 获取企业会员轮播图数据
        this.companyBannerRe();
    },
    methods: {
        // 获取企业会员轮播图数据
        companyBannerRe() {
            let data = {
                id: sessionStorage.getItem("id"),
            };
            companyBanner(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data, "轮播图数据");
                    this.swiperData = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {},
};
</script>

<style lang="scss">
// .memberSwiper {
//     .el-carousel__container {
//         height: 190px !important;
//         // border: 1px solid red;
//     }

//     .el-carousel__indicators {
//         display: none;
//     }
//     .el-carousel__item h3 {
//         color: #475669;
//         font-size: 18px;
//         opacity: 0.75;
//         line-height: 300px;
//         margin: 0;
//     }

//     .el-carousel__item:nth-child(2n) {
//         background-color: #99a9bf;
//     }

//     .el-carousel__item:nth-child(2n + 1) {
//         background-color: #d3dce6;
//     }
// }

// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .memberSwiper {
//         .el-carousel__container {
//             // height: 130px;
//         }

//         .el-carousel__indicators {
//             display: none;
//         }
//         .el-carousel__item h3 {
//             color: #475669;
//             font-size: 18px;
//             opacity: 0.75;
//             line-height: 300px;
//             margin: 0;
//         }

//         .el-carousel__item:nth-child(2n) {
//             background-color: #99a9bf;
//         }

//         .el-carousel__item:nth-child(2n + 1) {
//             background-color: #d3dce6;
//         }
//     }
// }

// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .memberSwiper {
//         .el-carousel__container {
//             // height: 100px;

//             .el-carousel__indicators {
//                 display: none;
//             }
//             .el-carousel__item h3 {
//                 color: #475669;
//                 font-size: 18px;
//                 opacity: 0.75;
//                 line-height: 300px;
//                 margin: 0;
//             }

//             .el-carousel__item:nth-child(2n) {
//                 background-color: #99a9bf;
//             }

//             .el-carousel__item:nth-child(2n + 1) {
//                 background-color: #d3dce6;
//             }
//         }
//     }
// }
</style>
