<template>
    <!-- 编辑页面 -->
    <div class="compile">
        <Header></Header>
        <div class="compile-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->
            <div class="compile-content">
                <div>
                    <!-- 个人介绍 -->
                    <div class="compile-introduce" v-if="type == 0">
                        <label for="">个人介绍：</label>
                        <textarea
                            rows="5"
                            cols="20"
                            v-model="form1.content"
                        ></textarea>
                    </div>
                    <!-- 论文论著 -->
                    <div class="compile-works" v-if="type == 1">
                        <div>
                            <label for="">标题：</label>
                            <input
                                type="text"
                                v-model="form2.title"
                                placeholder="请输入标题"
                            />
                        </div>
                        <div>
                            <label for="">简介：</label>
                            <input
                                type="text"
                                v-model="form2.description"
                                placeholder="请输入简介"
                            />
                        </div>
                        <div>
                            <label for="">详情：</label>
                            <textarea
                                rows="5"
                                cols="20"
                                v-model="form2.content"
                            ></textarea>
                        </div>
                        <div>
                            <label for="">图片：</label>
                            <Upload @url="getUrl"></Upload>
                        </div>
                        <!-- <button class="issue" @click="setmembercontentRe">
                            去发布
                        </button> -->
                    </div>
                    <!-- 成果课题 -->
                    <div class="compile-task" v-if="type == 2">
                        <div>
                            <label for="">标题：</label>
                            <input
                                type="text"
                                v-model="form3.title"
                                placeholder="请输入标题"
                            />
                        </div>
                        <div>
                            <label for="">标签：</label>
                            <input
                                type="text"
                                v-model="form3.lable_ids"
                                placeholder="请输入标签"
                            />
                        </div>
                        <div>
                            <label for="">归属单位：</label>
                            <input
                                type="text"
                                v-model="form3.company"
                                placeholder="请输入归属单位"
                            />
                        </div>
                        <div>
                            <label for="">完成作者：</label>
                            <input
                                type="text"
                                v-model="form3.author"
                                placeholder="请输入完成作者"
                            />
                        </div>
                        <div>
                            <label for="">评价单位：</label>
                            <input
                                type="text"
                                v-model="form3.authenticate"
                                placeholder="请输入评价单位"
                            />
                        </div>
                        <div>
                            <label for="">成功阶段：</label>
                            <input
                                type="text"
                                v-model="form3.stage"
                                placeholder="请输入成功阶段"
                            />
                        </div>
                        <div>
                            <label for="">应用领域：</label>
                            <input
                                type="text"
                                v-model="form3.usedomain"
                                placeholder="请输入应用领域"
                            />
                        </div>
                        <div>
                            <label for="">转让方式：</label>
                            <input
                                type="text"
                                v-model="form3.makeway"
                                placeholder="请输入转让方式"
                            />
                        </div>
                        <div>
                            <label for="">技术简介：</label>
                            <input
                                type="text"
                                v-model="form3.description"
                                placeholder="请输技术简介"
                            />
                        </div>
                        <div>
                            <label for="">内容：</label>
                            <textarea
                                rows="5"
                                cols="20"
                                v-model="form3.content"
                            ></textarea>
                        </div>
                    </div>
                    <!-- 助企交流 -->
                    <div class="compile-works" v-if="type == 3">
                        <div>
                            <label for="">标题：</label>
                            <input
                                type="text"
                                v-model="form4.title"
                                placeholder="请输入标题"
                            />
                        </div>
                        <div>
                            <label for="">简介：</label>
                            <input
                                type="text"
                                v-model="form4.description"
                                placeholder="请输入简介"
                            />
                        </div>
                        <div>
                            <label for="">详情：</label>
                            <textarea
                                rows="5"
                                cols="20"
                                v-model="form4.content"
                            ></textarea>
                        </div>
                        <div>
                            <label for="">图片：</label>
                            <Upload @url="setnewUrl"></Upload>
                        </div>
                    </div>
                    <!-- 授课视频 -->
                    <div class="compile-works" v-if="type == 4">
                        <div>
                            <label for="">标题：</label>
                            <input
                                type="text"
                                v-model="form5.title"
                                placeholder="请输入标题"
                            />
                        </div>
                        <div>
                            <label for="">简介：</label>
                            <input
                                type="text"
                                v-model="form5.description"
                                placeholder="请输入简介"
                            />
                        </div>
                        <div>
                            <label for="">封面：</label>
                            <Upload @url="coverUrl"></Upload>
                        </div>
                        <div>
                            <label for="">视频：</label>
                            <Video
                                :videos="videoInfo.allVideo"
                                @delete="deleteVideo"
                                @change="getVideo"
                            >
                            </Video>
                        </div>
                        <!-- <button class="issue" @click="setmembercontentRe">
                            去发布
                        </button> -->
                    </div>
                    <!-- 技术难题发布 -->
                    <div class="setproblem" v-if="type == 10">
                        <div class="detail-right-name">
                            <label for="">*难题名称</label>
                            <input
                                class="name"
                                type="表单类型"
                                v-model="form.name"
                                placeholder="请输入难题名称"
                            />
                        </div>
                        <div class="detail-right-select">
                            <label for="">*分类</label>
                            <select @change="selectChange">
                                <option
                                    :value="item.id"
                                    v-for="(item, index) in needCatelist"
                                    :key="index"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                        <div class="detail-right-area">
                            <label for="">*难题描述</label>
                            <textarea
                                rows="行数"
                                cols="列数"
                                v-model="form.area"
                                class="area"
                                placeholder="请输入难题描述"
                            >
                            </textarea>
                        </div>
                        <div class="detail-right-firm">
                            <label for="">公司名称</label>
                            <input
                                class="phone"
                                type="表单类型"
                                v-model="form.firm"
                                placeholder="请输入公司名称"
                            />
                        </div>

                        <div class="relation-right-linkman">
                            <label for="">联系人</label>
                            <input
                                class="linkman"
                                type="表单类型"
                                v-model="form.linkman"
                                placeholder="请输入联系人"
                            />

                            <input
                                type="radio"
                                name="gender"
                                value="1"
                                checked
                                @change="changeRadio"
                            />先生
                            <input
                                type="radio"
                                name="gender"
                                value="2"
                                @change="changeRadio"
                            />女士
                        </div>
                        <div class="relation-right-phone">
                            <label for="">*联系电话</label>
                            <input
                                class="phone"
                                type="表单类型"
                                v-model="form.phone"
                                placeholder="请输入联系电话"
                            />
                        </div>
                        <div class="relation-right-phone">
                            <label for="">*标签</label>
                            <input
                                class="phone"
                                type="表单类型"
                                v-model="form.lable"
                                placeholder="请输入联系电话"
                            />
                        </div>
                        <div class="relation-right-mail">
                            <label for="">电子邮件</label>
                            <input
                                class="mail"
                                type="表单类型"
                                v-model="form.mail"
                                placeholder="请输入电子邮件"
                            />
                        </div>

                        <div class="relation-right-code">
                            <label for="">*验证码</label>
                            <input
                                class="mail"
                                type="表单类型"
                                v-model="form.code"
                                placeholder="请输入验证码"
                            />

                            <div>
                                <!-- <Code @getCode="getIdentifyCode" ref="code"></Code> -->
                            </div>
                        </div>
                        <div>
                            <!-- <div @click="submit">提交</div>
                        <div @click="rewrite">重写</div> -->
                        </div>
                    </div>
                    <!-- 成果转化 -->
                    <div class="compile-setoutcome" v-if="type == 11">
                        <div class="detail-right-name">
                            <label for="">*成果名称</label>
                            <input
                                type="表单类型"
                                v-model="form11.name"
                                placeholder="请输入难题名称"
                            />
                        </div>
                        <div class="detail-right-select">
                            <label for="">*标签</label>
                            <select @change="setoutChange">
                                <option
                                    :value="item.id"
                                    v-for="(item, index) in setoutOptionDe"
                                    :key="index"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                        <div class="detail-right-unit">
                            <label for="">*归属单位</label>
                            <input
                                type="表单类型"
                                v-model="form11.unit"
                                placeholder="请输入公司名称"
                            />
                        </div>

                        <div class="relation-right-author">
                            <label for="">*完成作者</label>
                            <input
                                class="linkman"
                                type="表单类型"
                                v-model="form11.author"
                                placeholder="请输入联系人"
                            />
                        </div>
                        <div class="relation-right-phone">
                            <label for="">*联系电话</label>
                            <input
                                class="phone"
                                type="表单类型"
                                v-model="form11.phone"
                                placeholder="请输入联系电话"
                            />
                        </div>
                        <div class="relation-right-prove">
                            <label for="">*评价单位</label>
                            <input
                                class="mail"
                                type="表单类型"
                                v-model="form11.prove"
                                placeholder="请输入电子邮件"
                            />
                        </div>

                        <div class="relation-right-result">
                            <label for="">*成果阶段</label>
                            <input
                                class="mail"
                                type="表单类型"
                                v-model="form11.result"
                                placeholder="请输入验证码"
                            />
                        </div>
                        <div class="relation-right-domain">
                            <label for="">*应用领域</label>
                            <input
                                class="mail"
                                type="表单类型"
                                v-model="form11.domain"
                                placeholder="请输入验证码"
                            />
                        </div>
                        <div class="relation-right-rotate">
                            <label for="">*转让方式</label>
                            <input
                                class="mail"
                                type="表单类型"
                                v-model="form11.rotate"
                                placeholder="请输入验证码"
                            />
                        </div>

                        <!-- 技术简介 -->
                        <div class="detail-right-area">
                            <label for="">*技术简介</label>
                            <textarea
                                rows="行数"
                                cols="列数"
                                v-model="form11.area"
                                class="area"
                                placeholder="请输入难题描述"
                            >
                            </textarea>
                        </div>

                        <!-- 技术内容 -->
                        <div class="detail-right-content">
                            <label for="">*技术内容</label>
                            <textarea
                                rows="行数"
                                cols="列数"
                                v-model="form11.content"
                                class="area"
                                placeholder="请输入难题描述"
                            >
                            </textarea>
                        </div>

                        <!-- 验证码 -->
                        <div class="relation-right-code">
                            <label for="">*验证码</label>
                            <input
                                class="mail"
                                type="表单类型"
                                v-model="form11.code"
                                placeholder="请输入验证码"
                            />

                            <div>
                                <!-- <Code @getCode="getIdentifyCode" ref="code"></Code> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Right></Right>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Upload from "@/components/Upload.vue"; //上传
import Video from "@/components/video.vue"; //上传
import Advertising from "@/components/Advertising.vue"; //广告图
import {
    setproblem,
    needCatelist,
    needSetoutcome,
    setmembercontent,
    setwork,
    setoutcome,
    setnew,
    outcomescate,
    setcourse,
} from "../../../network/data";

export default {
    name: "compile",
    data() {
        return {
            // 视频上传
            videoInfo: {
                allVideo: [],
                deleteVideo: [],
            },
            // 视频上传结果
            video: "",
            // 封面图片
            coverimg: "",
            type: "",
            // 专家介绍
            form1: {
                content: "",
            },
            // 论文论著
            form2: {
                title: "",
                image: "",
                description: "",
                content: "",
            },
            // 成果课题
            form3: {
                title: "",
                lable_ids: "",
                company: "",
                author: "",
                authenticate: "",
                stage: "",
                usedomain: "",
                makeway: "",
                description: "",
                content: "",
            },
            // 选中的性别,1=先生,2=女士
            sex: "1",
            // 助企交流
            form4: {
                title: "",
                image: "",
                description: "",
                content: "",
            },
            // 授课视频
            form5: {
                title: "",
                image: "",
                description: "",
                video: "",
            },
            // 发布问题输入框数据
            form: {
                name: "",
                area: "",
                firm: "",
                linkman: "",
                phone: "",
                mail: "",
                code: "",
                lable: "",
            },
            // 选中需求分类id
            cate_id: "",
            // 需求分类数据
            needCatelist: [],
            // 发布成果转化
            form11: {
                name: "",
                unit: "",
                author: "",
                phone: "",
                prove: "",
                result: "",
                domain: "",
                rotate: "",
                area: "",
                content: "",
            },
            // 成果转换标签
            setoutOptionDe: [],
            // 选中成果转换标签
            setoutId: "",
        };
    },
    computed: {},
    watch: {},
    created() {
        if (this.$route.query) {
            if (this.$route.query.type == 0) {
                // this.form1 = JSON.parse(this.$route.query.item);
                this.type = this.$route.query.type;
            } else if (this.$route.query.type == 1) {
                // this.form2 = JSON.parse(this.$route.query.item);
                this.type = this.$route.query.type;
            } else if (this.$route.query.type == 2) {
                // this.form3 = JSON.parse(this.$route.query.item);
                this.type = this.$route.query.type;
            } else if (this.$route.query.type == 3) {
                // this.form3 = JSON.parse(this.$route.query.item);
                this.type = this.$route.query.type;
            } else if (this.$route.query.type == 4) {
                // this.form3 = JSON.parse(this.$route.query.item);
                this.type = this.$route.query.type;
            } else if (this.$route.query.type == 10) {
                this.type = this.$route.query.type;
                this.needCatelistRe();
            } else if (this.$route.query.type == 11) {
                this.type = this.$route.query.type;
                this.setoutOptionRe();
            }
        }
    },
    methods: {
        // 视频上传
        getVideo(event) {
            this.videoInfo.allVideo = event;
            console.log(event, "上传视频");
            this.changeFile(event);
        },
        // 视频删除
        deleteVideo(index) {
            const video = this.videoInfo.allVideo[index];
            if (video.videoLink) {
                this.videoInfo.deleteVideo.push(video);
            }
            this.videoInfo.allVideo.splice(index, 1);
        },
        changeFile(e) {
            let formData = new FormData();
            formData.append("file", e[0].videoFile.raw);
            axios
                .post(
                    "http://admin.shipin.dd371.com/api/common/upload",
                    formData
                )
                .then((res) => {
                    // console.log(res, "视频上传结果");
                    this.video = res.data.data.url;
                });
        },
        // 获取需求分类
        needCatelistRe() {
            needCatelist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.needCatelist = res.data;
                    this.needCatelist.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取需求成果转换标签
        setoutOptionRe() {
            outcomescate().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.setoutOptionDe = res.data;
                    this.setoutOptionDe.unshift({ id: 0, name: "请选择" });
                } else {
                    this.message(res.msg);
                }
            });
        },
        setmembercontentRe() {
            if (this.type == 0) {
                // 提交专家介绍
                let data = {
                    id: sessionStorage.getItem("specialist_id"),
                    content: this.form1.content,
                };
                setmembercontent(data).then((res) => {
                    if (res.code == 1) {
                        // console.log(res.data);
                        this.$message.warning({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                    } else {
                        this.$message.warning({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                    }
                });
            } else if (this.type == 1) {
                // 提交论文论著
                if (this.form2.title == "") {
                    this.$message.warning({
                        message: "标题为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form2.description == "") {
                    this.$message.warning({
                        message: "简介为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form2.content == "") {
                    this.$message.warning({
                        message: "详情为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form2.image == "") {
                    this.$message.warning({
                        message: "请上传",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else {
                    let data = {
                        id: sessionStorage.getItem("specialist_id"),
                        title: this.form2.title,
                        image: this.form2.image,
                        description: this.form2.description,
                        content: this.form2.content,
                    };
                    setwork(data).then((res) => {
                        if (res.code == 1) {
                            this.$message.warning({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        } else {
                            this.$message.warning({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        }
                    });
                }
            } else if (this.type == 2) {
                // 提交成果课题
                let data = {
                    id: sessionStorage.getItem("specialist_id"),
                    title: this.form3.title,
                    lable_ids: this.form3.lable_ids,
                    company: this.form3.company,
                    author: this.form3.author,
                    authenticate: this.form3.authenticate,
                    stage: this.form3.stage,
                    usedomain: this.form3.usedomain,
                    makeway: this.form3.makeway,
                    description: this.form3.description,
                    content: this.form3.content,
                };
                setoutcome(data).then((res) => {
                    if (res.code == 1) {
                        this.$message.warning({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                    } else {
                        this.$message.warning({
                            message: res.msg,
                            duration: 1000,
                            customClass: "myBox",
                        });
                    }
                });
            } else if (this.type == 3) {
                // 提交助企交流
                if (this.form4.title == "") {
                    this.$message.warning({
                        message: "标题为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form4.description == "") {
                    this.$message.warning({
                        message: "简介为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form4.content == "") {
                    this.$message.warning({
                        message: "详情为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form4.image == "") {
                    this.$message.warning({
                        message: "请上传",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else {
                    let data = {
                        id: sessionStorage.getItem("specialist_id"),
                        title: this.form4.title,
                        image: this.form4.image,
                        description: this.form4.description,
                        content: this.form4.content,
                    };
                    setnew(data).then((res) => {
                        if (res.code == 1) {
                            this.$message.warning({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        } else {
                            this.$message.warning({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        }
                    });
                }
            } else if (this.type == 4) {
                // 提交授权视频
                if (this.form5.title == "") {
                    this.$message.warning({
                        message: "标题为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form5.description == "") {
                    this.$message.warning({
                        message: "简介为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.coverimg == "") {
                    this.$message.warning({
                        message: "请点击上传封面图片",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.video == "") {
                    this.$message.warning({
                        message: "请上传视频",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else {
                    let data = {
                        id: sessionStorage.getItem("specialist_id"),
                        title: this.form5.title,
                        image: this.coverimg,
                        description: this.form5.description,
                        video: this.video,
                    };
                    setcourse(data).then((res) => {
                        if (res.code == 1) {
                            this.$message.warning({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        } else {
                            this.$message.warning({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        }
                    });
                }
            } else if (this.type == 10) {
                let phoneDta = this.form.phone;
                let refPhone =
                    /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
                if (this.form.name == "") {
                    this.$message.warning({
                        message: "难题名称为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form.area == "") {
                    this.$message.warning({
                        message: "难题描述为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (!refPhone.test(phoneDta)) {
                    this.$message.warning({
                        message: "联系电话错误",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form.code == "") {
                    this.$message.warning({
                        message: "验证码为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else {
                    let data = {
                        id: sessionStorage.getItem("companyId"), //企业ID
                        cate_id: this.cate_id, //分类id
                        lable: this.form.lable, //标签
                        title: this.form.name, //标题
                        content: this.form.area, //难题描述
                        company: this.form.firm, //公司名称
                        author: this.form.linkman, //联系人
                        sex: this.sex, //性别
                        mobile: this.form.phone, //联系方式
                        email: this.form.mail, //邮箱
                    };
                    setproblem(data).then((res) => {
                        if (res.code == 1) {
                            // console.log(res.data);
                            this.$message.success({
                                message: "提交成功",
                                duration: 1000,
                                customClass: "myBox",
                            });
                        } else {
                            this.$message.success({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        }
                    });
                }
            } else if (this.type == 11) {
                let phoneDta = this.form11.phone;
                let refPhone =
                    /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
                if (this.form.name11 == "") {
                    this.$message.warning({
                        message: "成果名称为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form11.unit == "") {
                    this.$message.warning({
                        message: "归属单位为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form11.author == "") {
                    this.$message.warning({
                        message: "完成作者为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (!refPhone.test(phoneDta)) {
                    this.$message.warning({
                        message: "联系电话错误",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form11.prove == "") {
                    this.$message.warning({
                        message: "评价单位为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form11.result == "") {
                    this.$message.warning({
                        message: "成果阶段为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form11.domain == "") {
                    this.$message.warning({
                        message: "引用领域为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form11.rotate == "") {
                    this.$message.warning({
                        message: "转让方式为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else if (this.form11.code == "") {
                    this.$message.warning({
                        message: "验证码为空",
                        duration: 1000,
                        customClass: "myBox",
                    });
                    return;
                } else {
                    let data = {
                        id: sessionStorage.getItem("companyId"), //企业ID
                        title: this.form11.name,
                        lable_ids: this.setoutId,
                        company: this.form11.unit,
                        author: this.form11.author,
                        authenticate: this.form11.prove,
                        stage: this.form11.result,
                        usedomain: this.form11.domain,
                        makeway: this.form11.rotate,
                        description: this.form11.area,
                        content: this.form11.content,
                    };
                    needSetoutcome(data).then((res) => {
                        if (res.code == 1) {
                            this.$message.success({
                                message: "提交成功",
                                duration: 1000,
                                customClass: "myBox",
                            });
                        } else {
                            this.$message.success({
                                message: res.msg,
                                duration: 1000,
                                customClass: "myBox",
                            });
                        }
                    });
                }
            }
        },
        // 论文论著上传图片
        getUrl(e) {
            this.form2.image = e;
        },
        coverUrl(e) {
            this.coverimg = e;
        },
        // 助企交流
        setnewUrl(e) {
            this.form4.image = e;
        },
        // 选择性别
        changeRadio(e) {
            this.sex = e.target.value;
            // console.log(e.target.value, "性别");
        },
        // 选择需求分类
        selectChange(e) {
            this.cate_id = e.target.value;
            // console.log(this.cate_id);
        },
        // 成果转换标签
        setoutChange(e) {
            this.setoutId = e.target.value;
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        Upload,
        Video,
        Right,
        Advertising,
    },
};
</script>

<style lang="scss">
// 修改message弹框样式
// .myBox {
//     min-width: 130px !important;
//     height: 25px !important;
//     padding: 3px !important;
//     text-align: center !important;
//     display: flex !important;
//     justify-content: center !important;
//     .el-message__icon {
//         font-size: 10px;
//     }
//     .el-message__content {
//         font-size: 8px;
//     }
// }

// .compile {
//     .compile-wrap {
//         width: 70%;
//         margin: 0 auto;
//         margin-top: 35px;
//         margin-bottom: 40px;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 15px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         .issue {
//             cursor: pointer;
//             width: 60px;
//             height: 20px;
//             font-size: 12px;
//             background-color: #0056b0;
//             border: none;
//             border-radius: 10px;
//             color: #fff;
//         }

//         .compile-content {
//             // height: 600px;
//             display: flex;
//             // border: 1px solid red;

//             > div:nth-child(1) {
//                 width: 70%;
//                 margin-right: 10px;
//                 .compile-introduce {
//                     display: flex;
//                     width: 100%;
//                     margin-top: 10px;
//                     font-size: 10px;
//                     // border: 1px solid red;

//                     > label {
//                         display: block;
//                         width: 20%;
//                         text-align: end;
//                         box-sizing: border-box;
//                         padding-right: 10px;
//                         // border: 1px solid red;
//                     }

//                     > textarea {
//                         width: 100%;
//                         height: 200px;
//                     }
//                 }

//                 .compile-task {
//                     > div {
//                         display: flex;
//                         width: 100%;
//                         margin-top: 10px;
//                         font-size: 10px;
//                         // border: 1px solid red;

//                         > label {
//                             display: block;
//                             width: 20%;
//                             text-align: end;
//                             box-sizing: border-box;
//                             padding-right: 10px;
//                             // border: 1px solid red;
//                         }

//                         > input {
//                             width: 80%;
//                             height: 15px;
//                         }
//                     }
//                 }

//                 // 论文论著
//                 .compile-works {
//                     > div {
//                         display: flex;
//                         width: 100%;
//                         margin-top: 10px;
//                         font-size: 10px;
//                         // border: 1px solid red;

//                         > label {
//                             display: block;
//                             width: 12%;
//                             text-align: end;
//                             box-sizing: border-box;
//                             padding-right: 10px;
//                             // border: 1px solid red;
//                         }

//                         > input {
//                             width: 87%;
//                             height: 15px;
//                         }
//                         // 上传
//                         .upload-box {
//                             width: 80%;
//                             .avatar-uploader-box {
//                                 .el-upload {
//                                     width: 1.3rem;
//                                     height: 1.3rem;
//                                     border: 1px solid #d9d9d9;

//                                     .el-icon-plus {
//                                         width: 1.3rem;
//                                         line-height: 1.3rem;
//                                     }
//                                 }
//                             }
//                         }

//                         > textarea {
//                             width: 87%;
//                             height: 50px;
//                         }
//                     }
//                 }

//                 .setproblem {
//                     padding: 5px;
//                     font-size: 8px;
//                     .detail-right-name {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 200px;
//                             height: 13px;
//                         }
//                     }

//                     .detail-right-select {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > select {
//                             width: 53%;
//                             height: 15px;
//                         }
//                     }

//                     // 难题描述
//                     .detail-right-area {
//                         display: flex;
//                         margin-bottom: 10px;
//                         > label {
//                             width: 50px;
//                         }

//                         > textarea {
//                             width: 200px;
//                             height: 33px;
//                         }
//                     }

//                     // 公司名称
//                     .detail-right-firm {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 200px;
//                             height: 13px;
//                         }
//                     }

//                     // 联系人
//                     .relation-right-linkman {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input:nth-child(2) {
//                             width: 100px;
//                             height: 13px;
//                         }

//                         > input:nth-child(3) {
//                             margin: 0 5px 0 5px;
//                         }

//                         > input:nth-child(4) {
//                             margin: 0 5px 0 5px;
//                         }
//                     }
//                     // 联系电话
//                     .relation-right-phone {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 200px;
//                             height: 13px;
//                         }
//                     }
//                     // 电子邮件
//                     .relation-right-mail {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 200px;
//                             height: 13px;
//                         }
//                     }

//                     // 验证码
//                     .relation-right-code {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 100px;
//                             height: 13px;
//                         }

//                         > div {
//                             margin-left: 5px;
//                             width: 50px;
//                         }
//                     }
//                 }

//                 .compile-setoutcome {
//                     padding: 5px;
//                     font-size: 8px;
//                     .detail-right-name {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }

//                     .detail-right-select {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > select {
//                             width: 53%;
//                             height: 15px;
//                         }
//                     }

//                     // 归属单位
//                     .detail-right-unit {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }

//                     // 完成作者
//                     .relation-right-author {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input:nth-child(2) {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }
//                     // 联系电话
//                     .relation-right-phone {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }
//                     // 评价单位
//                     .relation-right-prove {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }

//                     // 成果阶段
//                     .relation-right-result {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }

//                     // 应用领域
//                     .relation-right-domain {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }

//                     // 应用领域
//                     .relation-right-rotate {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 400px;
//                             height: 13px;
//                         }
//                     }

//                     // 技术简介
//                     .detail-right-area,
//                     .detail-right-content {
//                         display: flex;
//                         margin-bottom: 10px;
//                         > label {
//                             width: 50px;
//                         }

//                         > textarea {
//                             width: 400px;
//                             height: 123px;
//                         }
//                     }
//                     // 验证码
//                     .relation-right-code {
//                         display: flex;
//                         align-items: center;
//                         margin-bottom: 10px;

//                         > label {
//                             width: 50px;
//                         }

//                         > input {
//                             width: 100px;
//                             height: 13px;
//                         }

//                         > div {
//                             margin-left: 5px;
//                             width: 50px;
//                         }
//                     }
//                 }
//             }

//             > div:nth-child(2) {
//                 width: 30%;
//             }
//         }
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .compile {
//         .compile-wrap {
//             width: 70%;
//             margin: 0 auto;
//             margin-top: 35px;
//             margin-bottom: 40px;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             .issue {
//                 cursor: pointer;
//                 width: 60px;
//                 height: 20px;
//                 font-size: 12px;
//                 background-color: #0056b0;
//                 border: none;
//                 border-radius: 10px;
//                 color: #fff;
//             }

//             .compile-content {
//                 // height: 600px;
//                 display: flex;
//                 // border: 1px solid red;

//                 > div:nth-child(1) {
//                     width: 70%;
//                     margin-right: 10px;
//                     .compile-introduce {
//                         display: flex;
//                         width: 100%;
//                         margin-top: 10px;
//                         font-size: 10px;
//                         // border: 1px solid red;

//                         > label {
//                             display: block;
//                             width: 20%;
//                             text-align: end;
//                             box-sizing: border-box;
//                             padding-right: 10px;
//                             // border: 1px solid red;
//                         }

//                         > textarea {
//                             width: 100%;
//                             height: 200px;
//                         }
//                     }

//                     // 成果课题
//                     .compile-task {
//                         > div {
//                             display: flex;
//                             width: 100%;
//                             margin-top: 10px;
//                             font-size: 10px;
//                             // border: 1px solid red;

//                             > label {
//                                 display: block;
//                                 width: 20%;
//                                 text-align: end;
//                                 box-sizing: border-box;
//                                 padding-right: 10px;
//                                 // border: 1px solid red;
//                             }

//                             > input {
//                                 width: 80%;
//                                 height: 15px;
//                             }
//                         }
//                     }

//                     // 论文论著
//                     .compile-works {
//                         > div {
//                             display: flex;
//                             width: 100%;
//                             margin-top: 10px;
//                             font-size: 10px;
//                             // border: 1px solid red;

//                             > label {
//                                 display: block;
//                                 width: 15%;
//                                 text-align: end;
//                                 box-sizing: border-box;
//                                 padding-right: 10px;
//                                 // border: 1px solid red;
//                             }

//                             > input {
//                                 width: 87%;
//                                 height: 15px;
//                             }
//                             // 上传
//                             .upload-box {
//                                 width: 80%;
//                                 .avatar-uploader-box {
//                                     .el-upload {
//                                         width: 1.3rem;
//                                         height: 1.3rem;
//                                         border: 1px solid #d9d9d9;

//                                         .el-icon-plus {
//                                             width: 1.3rem;
//                                             line-height: 1.3rem;
//                                         }
//                                     }
//                                 }
//                             }

//                             > textarea {
//                                 width: 82%;
//                                 height: 50px;
//                             }
//                         }
//                     }

//                     .setproblem {
//                         padding: 5px;
//                         font-size: 8px;
//                         .detail-right-name {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }

//                         .detail-right-select {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > select {
//                                 width: 53%;
//                                 height: 15px;
//                             }
//                         }

//                         // 难题描述
//                         .detail-right-area {
//                             display: flex;
//                             margin-bottom: 10px;
//                             > label {
//                                 width: 50px;
//                             }

//                             > textarea {
//                                 width: 200px;
//                                 height: 33px;
//                             }
//                         }

//                         // 公司名称
//                         .detail-right-firm {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }

//                         // 联系人
//                         .relation-right-linkman {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input:nth-child(2) {
//                                 width: 100px;
//                                 height: 13px;
//                             }

//                             > input:nth-child(3) {
//                                 margin: 0 5px 0 5px;
//                             }

//                             > input:nth-child(4) {
//                                 margin: 0 5px 0 5px;
//                             }
//                         }
//                         // 联系电话
//                         .relation-right-phone {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }
//                         // 电子邮件
//                         .relation-right-mail {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }

//                         // 验证码
//                         .relation-right-code {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 100px;
//                                 height: 13px;
//                             }

//                             > div {
//                                 margin-left: 5px;
//                                 width: 50px;
//                             }
//                         }
//                     }

//                     .compile-setoutcome {
//                         padding: 5px;
//                         font-size: 8px;
//                         .detail-right-name {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         .detail-right-select {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > select {
//                                 width: 53%;
//                                 height: 15px;
//                             }
//                         }

//                         // 归属单位
//                         .detail-right-unit {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 完成作者
//                         .relation-right-author {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input:nth-child(2) {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }
//                         // 联系电话
//                         .relation-right-phone {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }
//                         // 评价单位
//                         .relation-right-prove {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 成果阶段
//                         .relation-right-result {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 应用领域
//                         .relation-right-domain {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 应用领域
//                         .relation-right-rotate {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 技术简介
//                         .detail-right-area,
//                         .detail-right-content {
//                             display: flex;
//                             margin-bottom: 10px;
//                             > label {
//                                 width: 50px;
//                             }

//                             > textarea {
//                                 width: 400px;
//                                 height: 123px;
//                             }
//                         }
//                         // 验证码
//                         .relation-right-code {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 100px;
//                                 height: 13px;
//                             }

//                             > div {
//                                 margin-left: 5px;
//                                 width: 50px;
//                             }
//                         }
//                     }
//                 }

//                 > div:nth-child(2) {
//                     width: 30%;
//                 }
//             }
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .compile {
//         .compile-wrap {
//             width: 70%;
//             margin: 0 auto;
//             margin-top: 35px;
//             margin-bottom: 40px;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             .issue {
//                 cursor: pointer;
//                 width: 60px;
//                 height: 20px;
//                 font-size: 12px;
//                 background-color: #0056b0;
//                 border: none;
//                 border-radius: 10px;
//                 color: #fff;
//             }

//             .compile-content {
//                 // height: 600px;
//                 display: flex;
//                 // border: 1px solid red;

//                 > div:nth-child(1) {
//                     width: 70%;
//                     margin-right: 10px;
//                     .compile-introduce {
//                         display: flex;
//                         width: 100%;
//                         margin-top: 10px;
//                         font-size: 10px;
//                         // border: 1px solid red;

//                         > label {
//                             display: block;
//                             width: 20%;
//                             text-align: end;
//                             box-sizing: border-box;
//                             padding-right: 10px;
//                             // border: 1px solid red;
//                         }

//                         > textarea {
//                             width: 100%;
//                             height: 200px;
//                         }
//                     }

//                     // 成果课题
//                     .compile-task {
//                         > div {
//                             display: flex;
//                             width: 100%;
//                             margin-top: 10px;
//                             font-size: 10px;
//                             // border: 1px solid red;

//                             > label {
//                                 display: block;
//                                 width: 20%;
//                                 text-align: end;
//                                 box-sizing: border-box;
//                                 padding-right: 10px;
//                                 // border: 1px solid red;
//                             }

//                             > input {
//                                 width: 80%;
//                                 height: 15px;
//                             }
//                         }
//                     }

//                     // 论文论著
//                     .compile-works {
//                         > div {
//                             display: flex;
//                             width: 100%;
//                             margin-top: 10px;
//                             font-size: 10px;
//                             // border: 1px solid red;

//                             > label {
//                                 display: block;
//                                 width: 20%;
//                                 text-align: end;
//                                 box-sizing: border-box;
//                                 padding-right: 10px;
//                                 // border: 1px solid red;
//                             }

//                             > input {
//                                 width: 80%;
//                                 height: 15px;
//                             }
//                             // 上传
//                             .upload-box {
//                                 width: 80%;
//                                 .avatar-uploader-box {
//                                     .el-upload {
//                                         width: 1.3rem;
//                                         height: 1.3rem;
//                                         border: 1px solid #d9d9d9;

//                                         .el-icon-plus {
//                                             width: 1.3rem;
//                                             line-height: 1.3rem;
//                                         }
//                                     }
//                                 }
//                             }

//                             > textarea {
//                                 width: 87%;
//                                 height: 50px;
//                             }
//                         }
//                     }

//                     .setproblem {
//                         padding: 5px;
//                         font-size: 8px;
//                         .detail-right-name {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }

//                         .detail-right-select {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > select {
//                                 width: 53%;
//                                 height: 15px;
//                             }
//                         }

//                         // 难题描述
//                         .detail-right-area {
//                             display: flex;
//                             margin-bottom: 10px;
//                             > label {
//                                 width: 50px;
//                             }

//                             > textarea {
//                                 width: 200px;
//                                 height: 33px;
//                             }
//                         }

//                         // 公司名称
//                         .detail-right-firm {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }

//                         // 联系人
//                         .relation-right-linkman {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input:nth-child(2) {
//                                 width: 100px;
//                                 height: 13px;
//                             }

//                             > input:nth-child(3) {
//                                 margin: 0 5px 0 5px;
//                             }

//                             > input:nth-child(4) {
//                                 margin: 0 5px 0 5px;
//                             }
//                         }
//                         // 联系电话
//                         .relation-right-phone {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }
//                         // 电子邮件
//                         .relation-right-mail {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 200px;
//                                 height: 13px;
//                             }
//                         }

//                         // 验证码
//                         .relation-right-code {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 100px;
//                                 height: 13px;
//                             }

//                             > div {
//                                 margin-left: 5px;
//                                 width: 50px;
//                             }
//                         }
//                     }

//                     .compile-setoutcome {
//                         padding: 5px;
//                         font-size: 8px;
//                         .detail-right-name {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         .detail-right-select {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > select {
//                                 width: 53%;
//                                 height: 15px;
//                             }
//                         }

//                         // 归属单位
//                         .detail-right-unit {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 完成作者
//                         .relation-right-author {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input:nth-child(2) {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }
//                         // 联系电话
//                         .relation-right-phone {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }
//                         // 评价单位
//                         .relation-right-prove {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 成果阶段
//                         .relation-right-result {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 应用领域
//                         .relation-right-domain {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 应用领域
//                         .relation-right-rotate {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 400px;
//                                 height: 13px;
//                             }
//                         }

//                         // 技术简介
//                         .detail-right-area,
//                         .detail-right-content {
//                             display: flex;
//                             margin-bottom: 10px;
//                             > label {
//                                 width: 50px;
//                             }

//                             > textarea {
//                                 width: 400px;
//                                 height: 123px;
//                             }
//                         }
//                         // 验证码
//                         .relation-right-code {
//                             display: flex;
//                             align-items: center;
//                             margin-bottom: 10px;

//                             > label {
//                                 width: 50px;
//                             }

//                             > input {
//                                 width: 100px;
//                                 height: 13px;
//                             }

//                             > div {
//                                 margin-left: 5px;
//                                 width: 50px;
//                             }
//                         }
//                     }
//                 }

//                 > div:nth-child(2) {
//                     width: 30%;
//                 }
//             }
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
