<template>
    <!-- 行业标准 -->
    <div class="standard">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="prefabricate-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">{{ normnavTap.name }}</div>
                    <div class="survey-title-sn">{{ normnavTap.enname }}</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in normnavTap.list"
                        @click="normTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
                <div class="survey-nav-search">
                    <div class="search-box-right">
                        <div class="search-right-input">
                            <input
                                v-model="keyword"
                                type="text"
                                placeholder="标准号、标准名、分类标签名"
                            />
                            <button @click="search">搜索</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 内容区域,包含学会概况、学会章程、入会申请、通知通告、学会要闻、企业会员 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <ul>
                            <li
                                v-for="(itemc, indexc) in normListDe.data"
                                :key="indexc"
                            >
                                <!-- 行业标准列表左侧内容 -->
                                <div class="index-list-left">
                                    <div class="index-list-top">
                                        <div class="index-list-top2">
                                            {{ itemc.standardno }}—{{
                                                itemc.title
                                            }}
                                        </div>
                                    </div>
                                    <div class="index-list-bottom">
                                        <span
                                            style="
                                                background-color: #0056b0;
                                                color: #ffffff;
                                            "
                                            >{{ "标签：" + itemc.lable }}</span
                                        >

                                        发布日期：{{ itemc.releasetime }} |
                                        实施日期：{{ itemc.carrytime }}
                                    </div>
                                </div>
                                <!-- 行业标准列表右侧内容 -->
                                <div class="index-list-right">
                                    <div class="down iconfont icon-xiazaidaoru">
                                        <span>下载</span>
                                    </div>
                                    <div
                                        class="check"
                                        @click="StandardDetail(itemc, indexc)"
                                    >
                                        <span class="iconfont icon-sousuo"
                                            >查看</span
                                        >
                                    </div>
                                    <div
                                        class="collect iconfont icon-zengjia"
                                        @click="collectPop"
                                    >
                                        <span> 收藏</span>
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify(normListDe) != ''"
                                    :pageNo="normParams.page"
                                    :pageSize="normParams.pageSize"
                                    :total="total"
                                    :continues="5"
                                    @getPageNo="getPageEv"
                                />
                            </div>
                        </ul>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import HeadNav from "@/components/Nav.vue"; //导航
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //关于右侧组件
import Nav from "@/components/About/nav.vue"; //关于tap切换
import Pagination from "@/components/Pagination"; //分页组件
import Advertising from "@/components/Advertising.vue"; //广告图
import { normnav, normlist, hangYeSearch } from "../../../network/data";
export default {
    name: "prefabricate",
    data() {
        return {
            keyword: "",
            // 当前tap索引
            currentIndex: 0,
            // 切换tap栏id,默认1
            id: 1,
            // 关于学会tap数据
            normnavTap: [],
            // 产业专题列表数据
            normListDe: {},
            // 分页
            normParams: {
                page: 1,
                pageSize: 3,
            },
            total: 10,
            // 搜索字段
        };
    },
    computed: {},
    watch: {
        // $route(to, from) {
        //     window.location.reload(); //监测到路由发生跳转时刷新一次页面
        // },
        // 定义 total 侦听器，指向一个配置对象
        normListDe: {
            // handler 属性用来定义侦听器的 function 处理函数
            handler(newVal) {
                if (newVal.current_page) {
                    this.normParams.page = newVal.current_page;
                }
                if (newVal.per_page) {
                    this.normParams.pageSize = newVal.per_page - 0;
                }
                if (newVal.total) {
                    this.total = newVal.total;
                }
            },
            // immediate 属性用来声明此侦听器，是否在页面初次加载完毕后立即调用
            immediate: true,
        },
    },
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.currentIndex = this.$route.query.type;
        }
        // 获取行业标准导航
        this.normnavRe();

        // 获取行业标准列表
        this.normlistRe();
    },
    methods: {
        //行业搜索
        search() {
            let data = {
                menuid: 40,
                keyword: this.keyword,
                page: this.normParams.page,
                pagesize: this.normParams.pageSize,
            };

            hangYeSearch(data).then((res) => {
                if (res.code == 1) {
                    this.normListDe = res.data.list;
                }
            });
        },
        // 行业标准面包屑触发事件
        normTapEv(item, index) {
            this.id = item.id;
            // this.surveyData = [];
            this.currentIndex = index;
            this.normlistRe();
        },
        // 跳转到行业标准详情页
        StandardDetail(item, type) {
            console.log(item);
            this.$router.push({
                path: `/norm/standardDetail/${type}`,
                query: {
                    ids: item.id,
                    menu_id: item.menu_id,
                },
            });
        },
        // 分页触发事件
        getPageEv(index) {
            this.normParams.page = index;
            this.normlistRe();
        },
        // 获取行业标准导航
        normnavRe() {
            normnav().then((res) => {
                if (res.code == 1) {
                    this.normnavTap = res.data;
                    console.log(res.data, "行业标准导航栏");
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取行业标准列表
        normlistRe() {
            let data = {
                menuid: 40,
                cateid: this.id,
                page: this.normParams.page,
                pagesize: this.normParams.pageSize,
            };
            normlist(data).then((res) => {
                if (res.code == 1) {
                    this.normListDe = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        collectPop() {
            this.$message.warning({
                message: "已收藏",
                duration: 1000,
                customClass: "myBox",
            });
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        Right,
        Nav,
        Pagination,
        Advertising,
        HeadNav,
    },
};
</script>

<style lang="scss">
// .standard {
//     width: 100%;
//     .prefabricate-wrap {
//         width: 70%;
//         // height: 1000px;
//         margin: 0 auto;
//         margin-bottom: 40px;
//         // border: 1px solid red;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 35px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 1px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 32%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;
//                 font-size: 10px;

//                 .survey-title-cn {
//                     // width: 30%;
//                     font-size: 13px;
//                     color: #0056b0;
//                     margin-right: 10px;
//                     // border: 1px solid red;
//                 }
//                 .survey-title-sn {
//                     // width: 70%;
//                     height: 10px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     // white-space: nowrap;
//                     // overflow: hidden;
//                     // text-overflow: ellipsis;
//                     background-color: #0056b0;
//                 }
//             }

//             > ul {
//                 width: 70%;
//                 // background: #000;
//                 list-style: none;
//                 display: flex;
//                 // border: 1px solid red;

//                 .active {
//                     color: #0068d5;
//                 }

//                 li {
//                     cursor: pointer;
//                     margin-left: 10px;
//                     padding-left: 10px;
//                     font-size: 10px;
//                     border-left: 1px solid #e9e9e9;
//                 }
//             }

//             .survey-nav-search {
//                 margin-left: auto;
//                 // border: 1px solid red;

//                 .search-box-right {
//                     height: 100%;
//                     display: flex;
//                     align-items: center;
//                     margin-left: auto;

//                     .search-right-input {
//                         display: flex;
//                         width: 130px;
//                         height: 15px;
//                         margin-left: 10px;

//                         > input {
//                             width: 80px;
//                             height: 80%;
//                             // padding-left: 10px;
//                         }

//                         button {
//                             width: 30px;
//                             height: 90%;
//                             margin-left: 3px;
//                             color: #fff;
//                             border: 0;
//                             border-radius: 3px;
//                             background-color: #0056b0;
//                         }
//                     }
//                 }
//             }
//         }
//         /*关于学会tap end*/

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin: 0 auto;
//                 margin-top: 15px;
//                 // border: 1px solid red;

//                 // 内容区域左侧
//                 .survey-content-left {
//                     width: 67%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     > ul {
//                         list-style: none;

//                         > li {
//                             display: flex;
//                             align-items: center;
//                             padding-bottom: 8px;
//                             margin-bottom: 8px;
//                             border-bottom: 0.5px solid #e6e6e6;
//                             // border: 1px solid red;

//                             // 左侧内容区域列表
//                             .index-list-left {
//                                 width: 70%;
//                                 height: 100%;
//                                 // border: 1px solid orange;

//                                 .index-list-top {
//                                     display: flex;
//                                     align-items: center;
//                                     // border: 1px solid red;

//                                     .index-list-top1 {
//                                         width: 50px;
//                                         height: 15px;
//                                         text-align: center;
//                                         line-height: 15px;
//                                         margin-right: 20px;
//                                         color: #fff;
//                                         font-size: 9px;
//                                         background-color: #5daa00;
//                                     }

//                                     .index-list-top2 {
//                                         font-size: 9px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }
//                                 }

//                                 .index-list-bottom {
//                                     font-size: 9px;
//                                     margin-top: 10px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }
//                             }

//                             .index-list-right {
//                                 display: flex;
//                                 margin-left: auto;
//                                 width: 100px;
//                                 height: 100%;
//                                 color: #666666;
//                                 // border: 1px solid orange;

//                                 .down {
//                                     width: 40px;
//                                     height: 15px;
//                                     text-align: center;
//                                     line-height: 15px;
//                                     font-size: 8px;
//                                     margin-right: 5px;
//                                     background-color: #fff;
//                                     border: 1px solid #dedede;

//                                     > span {
//                                         font-size: 8px;
//                                     }
//                                 }

//                                 .check {
//                                     cursor: pointer;
//                                     width: 40px;
//                                     height: 15px;
//                                     display: flex;
//                                     align-items: center;
//                                     justify-content: center;
//                                     margin-right: 5px;
//                                     background-color: #fff;
//                                     border: 1px solid #dedede;

//                                     > img {
//                                         width: 7px;
//                                         height: 7px;
//                                         margin-right: 5px;
//                                         display: block;
//                                     }

//                                     > span {
//                                         font-size: 8px;
//                                     }
//                                 }
//                                 .collect {
//                                     cursor: pointer;
//                                     margin-left: auto;
//                                     width: 40px;
//                                     height: 15px;
//                                     text-align: center;
//                                     line-height: 15px;
//                                     font-size: 8px;
//                                     background-color: #fff;
//                                     border: 1px solid #dedede;

//                                     > span {
//                                         font-size: 8px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 30%;
//                     // border: 1px solid red;
//                 }
//             }
//         }
//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .standard {
//         width: 100%;
//         .prefabricate-wrap {
//             width: 70%;
//             // height: 1000px;
//             margin: 0 auto;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 35px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 30%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         width: 70px;
//                         font-size: 12px;
//                         color: #0056b0;
//                         margin-right: 10px;
//                         // border: 1px solid red;
//                     }
//                     .survey-title-sn {
//                         text-align: center;
//                         width: 78px;
//                         height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }
//                 }

//                 > ul {
//                     // width: 70%;
//                     list-style: none;
//                     display: flex;
//                     // border: 1px solid red;

//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         text-align: center;
//                         width: 30px;
//                         margin-left: 10px;
//                         padding-left: 10px;
//                         font-size: 7.5px;
//                         border-left: 1px solid #e9e9e9;
//                     }
//                 }

//                 .survey-nav-search {
//                     margin-left: auto;
//                     // border: 1px solid red;

//                     .search-box-right {
//                         height: 100%;
//                         display: flex;
//                         align-items: center;
//                         margin-left: auto;

//                         .search-right-input {
//                             display: flex;
//                             width: 130px;
//                             height: 15px;
//                             margin-left: 10px;

//                             > input {
//                                 width: 60px;
//                                 height: 80%;
//                                 padding-left: 10px;
//                             }

//                             button {
//                                 width: 30px;
//                                 height: 90%;
//                                 margin-left: 3px;
//                                 color: #fff;
//                                 border: 0;
//                                 border-radius: 3px;
//                                 background-color: #0056b0;
//                             }
//                         }
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin: 0 auto;
//                     margin-top: 15px;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 67%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         > ul {
//                             list-style: none;

//                             // 左侧内容区域列表
//                             .index-list-left {
//                                 width: 100%;
//                                 height: 100%;
//                                 // border: 1px solid orange;

//                                 .index-list-top {
//                                     display: flex;
//                                     align-items: center;
//                                     // border: 1px solid red;

//                                     .index-list-top1 {
//                                         width: 50px;
//                                         height: 15px;
//                                         text-align: center;
//                                         line-height: 15px;
//                                         margin-right: 20px;
//                                         color: #fff;
//                                         font-size: 9px;
//                                         background-color: #5daa00;
//                                     }

//                                     .index-list-top2 {
//                                         font-size: 9px;
//                                     }
//                                 }

//                                 .index-list-bottom {
//                                     font-size: 9px;
//                                     margin-top: 10px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }
//                             }

//                             .index-list-right {
//                                 display: flex;
//                                 margin-left: auto;
//                                 width: 100px;
//                                 height: 100%;
//                                 color: #666666;
//                                 // border: 1px solid orange;

//                                 .down {
//                                     width: 40px;
//                                     height: 15px;
//                                     text-align: center;
//                                     line-height: 15px;
//                                     font-size: 8px;
//                                     margin-right: 5px;
//                                     background-color: #fff;
//                                     border: 1px solid #dedede;

//                                     > span {
//                                         font-size: 8px;
//                                     }
//                                 }

//                                 .check {
//                                     cursor: pointer;
//                                     width: 40px;
//                                     height: 15px;
//                                     display: flex;
//                                     align-items: center;
//                                     justify-content: center;
//                                     margin-right: 5px;
//                                     background-color: #fff;
//                                     border: 1px solid #dedede;

//                                     > img {
//                                         width: 7px;
//                                         height: 7px;
//                                         margin-right: 5px;
//                                         display: block;
//                                     }

//                                     > span {
//                                         font-size: 8px;
//                                     }
//                                 }
//                                 .collect {
//                                     cursor: pointer;
//                                     margin-left: auto;
//                                     width: 40px;
//                                     height: 15px;
//                                     text-align: center;
//                                     line-height: 15px;
//                                     font-size: 8px;
//                                     background-color: #fff;
//                                     border: 1px solid #dedede;

//                                     > span {
//                                         font-size: 8px;
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }
//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .standard {
//         width: 100%;
//         .prefabricate-wrap {
//             width: 80%;
//             height: 1000px;
//             margin: 0 auto;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 35px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 18%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         width: 80px;
//                         font-size: 5px;
//                         color: #0056b0;
//                         margin-right: 10px;
//                         // border: 1px solid red;
//                     }
//                     .survey-title-sn {
//                         text-align: center;
//                         width: 78px;
//                         height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                     }
//                 }

//                 > ul {
//                     // width: 70%;
//                     list-style: none;
//                     display: flex;
//                     // border: 1px solid red;

//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         text-align: center;
//                         width: 20px;
//                         margin-left: 10px;
//                         padding-left: 10px;
//                         font-size: 7.5px;
//                         border-left: 1px solid #e9e9e9;
//                     }
//                 }

//                 .survey-nav-search {
//                     margin-left: 15px;

//                     .search-box-right {
//                         height: 100%;
//                         display: flex;
//                         align-items: center;
//                         margin-left: auto;

//                         .search-right-input {
//                             display: flex;
//                             width: 130px;
//                             height: 15px;
//                             margin-left: auto;

//                             > input {
//                                 width: 50px;
//                                 // height: 80%;
//                                 padding-left: 10px;
//                             }

//                             button {
//                                 width: 30px;
//                                 height: 90%;
//                                 margin-left: 3px;
//                                 color: #fff;
//                                 border: 0;
//                                 border-radius: 3px;
//                                 background-color: #0056b0;
//                             }
//                         }
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin: 0 auto;
//                     margin-top: 10px;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 67%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         > ul {
//                             list-style: none;

//                             li {
//                                 display: flex;
//                                 align-items: center;

//                                 // 左侧内容区域列表
//                                 .index-list-left {
//                                     width: 60%;
//                                     height: 100%;
//                                     // border: 1px solid orange;

//                                     .index-list-top {
//                                         display: flex;
//                                         align-items: center;
//                                         // border: 1px solid red;

//                                         .index-list-top1 {
//                                             width: 50px;
//                                             height: 15px;
//                                             text-align: center;
//                                             line-height: 15px;
//                                             margin-right: 10px;
//                                             color: #fff;
//                                             font-size: 9px;
//                                             background-color: #5daa00;
//                                         }

//                                         .index-list-top2 {
//                                             font-size: 9px;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }
//                                     }

//                                     .index-list-bottom {
//                                         font-size: 6px;
//                                         margin-top: 10px;
//                                         color: #666666;
//                                     }
//                                 }

//                                 // 行业标准列表右侧内容
//                                 .index-list-right {
//                                     display: flex;
//                                     margin-left: auto;
//                                     width: 100px;
//                                     height: 100%;
//                                     color: #666666;
//                                     // border: 1px solid orange;

//                                     .down {
//                                         width: 40px;
//                                         height: 15px;
//                                         text-align: center;
//                                         line-height: 15px;
//                                         font-size: 8px;
//                                         margin-right: 5px;
//                                         background-color: #fff;
//                                         border: 1px solid #dedede;

//                                         > span {
//                                             font-size: 8px;
//                                         }
//                                     }

//                                     .check {
//                                         cursor: pointer;
//                                         width: 40px;
//                                         height: 15px;
//                                         display: flex;
//                                         align-items: center;
//                                         justify-content: center;
//                                         margin-right: 5px;
//                                         background-color: #fff;
//                                         border: 1px solid #dedede;

//                                         > img {
//                                             width: 7px;
//                                             height: 7px;
//                                             margin-right: 5px;
//                                             display: block;
//                                         }

//                                         > span {
//                                             font-size: 8px;
//                                         }
//                                     }
//                                     .collect {
//                                         cursor: pointer;
//                                         margin-left: auto;
//                                         width: 40px;
//                                         height: 15px;
//                                         text-align: center;
//                                         line-height: 15px;
//                                         font-size: 8px;
//                                         background-color: #fff;
//                                         border: 1px solid #dedede;

//                                         > span {
//                                             font-size: 8px;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
