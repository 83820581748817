<template>
    <!-- 产品需求---技术难题详情页 -->
    <div class="demandDetails">
        <Header></Header>
        <Nav></Nav>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">产业需求</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>

                <div style="font-size: 30px">技术难题详情页</div>
                <!-- <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap"
                        @click="machiningTapEv(index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul> -->
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 内容区域左侧 -->
                    <!-- 技术难题 -->
                    <div class="survey-content-left">
                        <div class="survey-detail-left">
                            <!-- 左侧 -->
                            <div class="minkaveDetails-introduce5-left">
                                <!-- 技术难题标题 -->
                                <div class="minkaveDetails-introduce5-title">
                                    <div>
                                        <div></div>
                                        <span>技术难题</span>
                                    </div>
                                    <ul>
                                        <li
                                            @click="puzzleEv(itemb, indexd)"
                                            v-for="(
                                                itemb, indexb
                                            ) in needCatelist"
                                            :key="indexb"
                                            :class="
                                                currentPuzzle == indexb
                                                    ? 'puzzleActive'
                                                    : ''
                                            "
                                        >
                                            {{ itemb.name }}
                                        </li>
                                    </ul>
                                </div>
                                <!-- 相关技术难题推荐 -->
                                <div class="minkaveDetails-introduce5-title2">
                                    <div class="">相关技术难题推荐</div>
                                    <ul>
                                        <li
                                            v-for="(
                                                itemc, indexc
                                            ) in problemdetailList"
                                            :key="indexc"
                                        >
                                            <div>{{ itemc.title }}</div>
                                            <div>
                                                状态:{{ itemc.statustext }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="survey-detail-right">
                            <div class="detail-right">
                                <div class="detail-right-place">
                                    当前位置：网站首页>产业需求>技术难题
                                </div>
                                <div class="detail-right-cont">
                                    <div>{{ problemdetailInfo.title }}</div>
                                    <div>
                                        状态：
                                        <span style="color: #ffa14d">{{
                                            problemdetailInfo.status
                                                ? "未解决"
                                                : "已解决"
                                        }}</span>
                                    </div>
                                    <div>
                                        标签：<span style="color: #ff6000">{{
                                            problemdetailInfo.lable
                                        }}</span>
                                    </div>
                                    <ul v-html="problemdetailInfo.description">
                                        <!-- <li>项目简介：</li>
                                        <li>
                                            对标开发，参考某品牌肉干产品，开发一款新型常温长保即食鸡肉干产品。
                                        </li>
                                        <li>技术需求：</li>
                                        <li>
                                            配方工艺符合标准法规要求；
                                            口感风味参考某品牌肉干，需通过客户测试。
                                        </li>
                                        <li>合作方式：</li>
                                        <li>
                                            详情请联系食品邦客服食品小邦，17653538951，微信同号
                                        </li> -->
                                    </ul>
                                    <div>
                                        <div
                                            @click="issueEv(0)"
                                            v-if="companyType == 2"
                                        >
                                            问题发布
                                        </div>
                                        <div
                                            @click="issueEv(1)"
                                            v-if="companyType == 1"
                                        >
                                            我来解答
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 内容区域右侧 -->
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import Left3 from "@/components/Science/Left3.vue"; //左侧
import { needCatelist, problemdetail, uselist } from "../../../network/data";
import Advertising from "@/components/Advertising.vue"; //广告图

export default {
    // 组件名称
    name: "demand",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 判断是否能发布问题，1==不能，2==能
            companyType: 0,
            // 存储企业会员登录返回的id
            memberId: "",
            // 当前tap索引
            // currentIndex: 2,
            // 技术难题分类id
            id: "",
            // 技术难题分类
            needCatelist: [],
            // 技术难题详情页数据
            problemdetailInfo: {},
            // 技术难题详情页左侧列表
            problemdetailList: [],

            // 技术难题左侧tap索引
            currentPuzzle: 0,

            // 关于学会tap数据
            machiningTap: [
                {
                    name: "技术难题",
                },
                {
                    name: "成果转化",
                },
                {
                    name: "就业招聘",
                },
                {
                    name: "招商引资",
                },
                {
                    name: "产学研合作",
                },
            ],

            // 技术难题
            // totalPage1: 100,
            // pagePrev1: 3,
            // pageNext1: 9,

            // // 成果转化分页
            // totalPage2: 100,
            // pagePrev2: 3,
            // pageNext2: 9,

            // // 就业招聘分页
            // totalPage3: 100,
            // pagePrev3: 3,
            // pageNext3: 9,

            // // 招商引资分页
            // totalPage4: 100,
            // pagePrev4: 3,
            // pageNext4: 9,

            // // 产学研合作分页
            // totalPage5: 100,
            // pagePrev5: 3,
            // pageNext5: 9,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query) {
            this.id = this.$route.query.id;
            this.needCatelistRe();
            this.problemdetailRe();
        }

        // 判断是否能发布问题，1==不能，2==能
        this.companyType = sessionStorage.getItem("companyType");

        // 存储企业会员登录返回的id
        this.memberId = sessionStorage.getItem("id");
    },
    methods: {
        // 跳转到发布页面
        issueEv(index) {
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: `/science/DemandIssue/`,
                    query: {
                        indexa: index,
                        lable_id: this.problemdetailInfo.lable_id,
                        id: this.problemdetailInfo.id,
                    },
                });
            }
        },
        // 获取技术难题分类
        needCatelistRe() {
            needCatelist().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.needCatelist = res.data;
                } else {
                    this.message(res.msg);
                }
            });
            uselist().then((res) => {
                if (res.code == 1) {
                    this.needCatelist = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取技术难题详情页数据
        problemdetailRe() {
            let data = {
                id: this.id,
            };
            problemdetail(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.problemdetailInfo = res.data.info;
                    this.problemdetailList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        machiningTapEv(index) {
            // this.surveyData = [];
            this.currentIndex = index;
        },

        // 技术难题分页点击事件
        pageChange2() {
            conosle.log("暂未设置");
        },

        // 技术难题左侧tap点击事件
        puzzleEv(item, index) {
            this.currentPuzzle = index;
            this.id = item.id;
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Left3,
        Nav,
        Advertising,
    },
};
</script>

<style lang="scss">
// .demandDetails {
//     width: 100%;

//     .minkave-wrap {
//         width: 70%;
//         height: 1000px;
//         margin: 0 auto;
//         margin-top: 35px;
//         // border: 1px solid red;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 15px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 0.5px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 20%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;

//                 .survey-title-cn {
//                     font-size: 10px;
//                     color: #0056b0;
//                     margin-right: 15px;
//                 }

//                 .survey-title-sn {
//                     height: 20px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     background-color: #0056b0;
//                 }
//             }

//             // > ul {
//             //     width: 80%;
//             //     list-style: none;
//             //     display: flex;
//             //     border: 1px solid red;

//             //     .active {
//             //         color: #0068d5;
//             //     }

//             //     li {
//             //         cursor: pointer;
//             //         margin-left: 10px;
//             //         padding-left: 10px;
//             //         font-size: 10px;
//             //         border-left: 1px solid #e9e9e9;
//             //     }
//             // }
//         }
//         /*关于学会tap end*/

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin-top: 15px;
//                 // height: 300px;
//                 // border: 1px solid red;

//                 // 内容区域左侧1(技术难题)
//                 .survey-content-left {
//                     display: flex;
//                     width: 68%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     .survey-detail-left {
//                         width: 26%;
//                         // border: 1px solid red;

//                         // 详情左侧内容
//                         .minkaveDetails-introduce5-left {
//                             width: 100%;
//                             // height: 216px;
//                             // border: 1px solid red;

//                             // 技术难题
//                             .minkaveDetails-introduce5-title {
//                                 > div:nth-child(1) {
//                                     display: flex;
//                                     align-items: center;
//                                     height: 30px;
//                                     line-height: 30px;
//                                     padding: 10px;
//                                     box-sizing: border-box;
//                                     // border: 1px solid red;
//                                     background-color: #0056b0;

//                                     > div:nth-child(1) {
//                                         width: 1.5px;
//                                         height: 14px;
//                                         background: #fff;
//                                     }

//                                     > span {
//                                         margin-top: -1.5px;
//                                         margin-left: 8px;
//                                         font-size: 11px;
//                                         color: #fff;
//                                     }
//                                 }

//                                 > ul {
//                                     list-style: none;

//                                     .puzzleActive {
//                                         color: #fff;
//                                         background-color: #8fc320;
//                                     }

//                                     li {
//                                         text-align: center;
//                                         height: 30px;
//                                         line-height: 30px;
//                                         border-bottom: 0.5px solid #f2f2f2;
//                                         letter-spacing: 1px;
//                                         font-size: 10px;
//                                     }
//                                 }
//                             }

//                             // 技术难题推荐
//                             .minkaveDetails-introduce5-title2 {
//                                 margin-top: 30px;
//                                 > div:nth-child(1) {
//                                     font-size: 11px;
//                                 }

//                                 > ul {
//                                     list-style: none;

//                                     li {
//                                         margin-top: 10px;
//                                         padding: 5px;
//                                         box-sizing: border-box;
//                                         border-radius: 4px;
//                                         font-size: 11px;
//                                         background-color: #f8f8f8;

//                                         > div:nth-child(1) {
//                                             font-size: 10px;
//                                         }

//                                         > div:nth-child(2) {
//                                             margin-top: 4px;
//                                             font-size: 9px;
//                                             color: #9f9f9f;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 详情右侧内容
//                     .survey-detail-right {
//                         width: 70%;
//                         margin-left: auto;
//                         // border: 1px solid red;

//                         .detail-right {
//                             width: 100%;
//                             font-size: 12px;
//                             background-color: #f8f8f8;

//                             .detail-right-place {
//                                 font-size: 10px;
//                                 padding: 5px;
//                                 box-sizing: border-box;
//                                 border-bottom: 0.5px solid #e6e6e6;
//                             }

//                             .detail-right-cont {
//                                 padding: 5px;

//                                 > div:nth-child(1) {
//                                     font-size: 12px;
//                                     margin: 6px 0;
//                                     margin-left: -5px;
//                                 }

//                                 > div:nth-child(2) {
//                                     font-size: 7px;
//                                     margin: 6px 0;
//                                 }

//                                 > div:nth-child(3) {
//                                     font-size: 7px;
//                                     padding: 6px 0;
//                                     border-bottom: 0.5px solid #e6e6e6;
//                                 }

//                                 > ul {
//                                     font-size: 8px !important;
//                                     // list-style: none;
//                                     // padding: 5px 0;

//                                     // // 奇数行
//                                     // li:nth-of-type(odd) {
//                                     //     font-size: 10px;
//                                     // }

//                                     // // 偶数行
//                                     // li:nth-of-type(even) {
//                                     //     font-size: 7px;
//                                     //     padding: 6px 0;
//                                     // }
//                                 }

//                                 > div:nth-child(5) {
//                                     display: flex;
//                                     margin: 20px 0;
//                                     // border: 1px solid red;

//                                     > div:nth-child(1) {
//                                         cursor: pointer;
//                                         font-size: 9px;
//                                         color: #fff;
//                                         padding: 3px 8px;
//                                         background-color: #40509e;
//                                         // border: 1px solid red;
//                                     }

//                                     > div:nth-child(2) {
//                                         cursor: pointer;
//                                         margin-left: 30px;
//                                         font-size: 9px;
//                                         color: #fff;
//                                         padding: 3px 8px;
//                                         background-color: #f9653f;
//                                         // border: 1px solid red;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 30%;
//                     // border: 1px solid red;
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .demandDetails {
//         width: 100%;

//         .minkave-wrap {
//             width: 70%;
//             height: 1000px;
//             margin: 0 auto;
//             margin-top: 35px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 0.5px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 20%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 15px;
//                     }

//                     .survey-title-sn {
//                         height: 20px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                     }
//                 }

//                 // > ul {
//                 //     width: 80%;
//                 //     list-style: none;
//                 //     display: flex;
//                 //     border: 1px solid red;

//                 //     .active {
//                 //         color: #0068d5;
//                 //     }

//                 //     li {
//                 //         cursor: pointer;
//                 //         margin-left: 10px;
//                 //         padding-left: 10px;
//                 //         font-size: 10px;
//                 //         border-left: 1px solid #e9e9e9;
//                 //     }
//                 // }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin-top: 15px;
//                     // height: 300px;
//                     // border: 1px solid red;

//                     // 内容区域左侧1(技术难题)
//                     .survey-content-left {
//                         display: flex;
//                         width: 68%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         .survey-detail-left {
//                             width: 26%;
//                             // border: 1px solid red;

//                             // 详情左侧内容
//                             .minkaveDetails-introduce5-left {
//                                 width: 100%;
//                                 // height: 216px;
//                                 // border: 1px solid red;

//                                 // 技术难题
//                                 .minkaveDetails-introduce5-title {
//                                     > div:nth-child(1) {
//                                         display: flex;
//                                         align-items: center;
//                                         height: 30px;
//                                         line-height: 30px;
//                                         padding: 10px;
//                                         box-sizing: border-box;
//                                         // border: 1px solid red;
//                                         background-color: #0056b0;

//                                         > div:nth-child(1) {
//                                             width: 1.5px;
//                                             height: 10px;
//                                             background: #fff;
//                                         }

//                                         > span {
//                                             margin-top: -1.5px;
//                                             margin-left: 6px;
//                                             font-size: 10px;
//                                             color: #fff;
//                                         }
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         .puzzleActive {
//                                             color: #fff;
//                                             background-color: #8fc320;
//                                         }

//                                         li {
//                                             text-align: center;
//                                             height: 20px;
//                                             line-height: 20px;
//                                             border-bottom: 0.5px solid #f2f2f2;
//                                             letter-spacing: 1px;
//                                             font-size: 8px;
//                                         }
//                                     }
//                                 }

//                                 // 技术难题推荐
//                                 .minkaveDetails-introduce5-title2 {
//                                     margin-top: 30px;
//                                     > div:nth-child(1) {
//                                         font-size: 10px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         li {
//                                             margin-top: 10px;
//                                             padding: 5px;
//                                             box-sizing: border-box;
//                                             border-radius: 4px;
//                                             font-size: 10px;
//                                             background-color: #f8f8f8;
//                                             // border: 1px solid red;

//                                             > div:nth-child(1) {
//                                                 font-size: 8px;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                                 // border: 1px solid red;
//                                             }

//                                             > div:nth-child(2) {
//                                                 margin-top: 4px;
//                                                 font-size: 7px;
//                                                 color: #9f9f9f;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 详情右侧内容
//                         .survey-detail-right {
//                             width: 70%;
//                             margin-left: auto;
//                             // border: 1px solid red;

//                             .detail-right {
//                                 width: 100%;
//                                 font-size: 12px;
//                                 background-color: #f8f8f8;

//                                 .detail-right-place {
//                                     font-size: 10px;
//                                     padding: 5px;
//                                     box-sizing: border-box;
//                                     border-bottom: 0.5px solid #e6e6e6;
//                                 }

//                                 .detail-right-cont {
//                                     padding: 5px;

//                                     > div:nth-child(1) {
//                                         font-size: 12px;
//                                         margin: 6px 0;
//                                         margin-left: -5px;
//                                     }

//                                     > div:nth-child(2) {
//                                         font-size: 7px;
//                                         margin: 6px 0;
//                                     }

//                                     > div:nth-child(3) {
//                                         font-size: 7px;
//                                         padding: 6px 0;
//                                         border-bottom: 0.5px solid #e6e6e6;
//                                     }

//                                     > ul {
//                                         font-size: 8px !important;
//                                         // list-style: none;
//                                         // padding: 5px 0;

//                                         // // 奇数行
//                                         // li:nth-of-type(odd) {
//                                         //     font-size: 10px;
//                                         // }

//                                         // // 偶数行
//                                         // li:nth-of-type(even) {
//                                         //     font-size: 7px;
//                                         //     padding: 6px 0;
//                                         // }
//                                     }

//                                     > div:nth-child(5) {
//                                         display: flex;
//                                         margin: 20px 0;
//                                         // border: 1px solid red;

//                                         > div:nth-child(1) {
//                                             cursor: pointer;
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #40509e;
//                                             // border: 1px solid red;
//                                         }

//                                         > div:nth-child(2) {
//                                             cursor: pointer;
//                                             margin-left: 30px;
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #f9653f;
//                                             // border: 1px solid red;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .demandDetails {
//         width: 100%;

//         .minkave-wrap {
//             width: 80%;
//             height: 1000px;
//             margin: 0 auto;
//             margin-top: 35px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 20%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 15px;
//                     }

//                     .survey-title-sn {
//                         height: 20px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                     }
//                 }

//                 // > ul {
//                 //     width: 80%;
//                 //     list-style: none;
//                 //     display: flex;
//                 //     border: 1px solid red;

//                 //     .active {
//                 //         color: #0068d5;
//                 //     }

//                 //     li {
//                 //         cursor: pointer;
//                 //         margin-left: 10px;
//                 //         padding-left: 10px;
//                 //         font-size: 10px;
//                 //         border-left: 1px solid #e9e9e9;
//                 //     }
//                 // }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     // height: 300px;
//                     // border: 1px solid red;

//                     // 内容区域左侧1(技术难题)
//                     .survey-content-left {
//                         display: flex;
//                         width: 68%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         .survey-detail-left {
//                             width: 26%;
//                             // border: 1px solid red;

//                             // 详情左侧内容
//                             .minkaveDetails-introduce5-left {
//                                 width: 100%;
//                                 // height: 216px;
//                                 // border: 1px solid red;

//                                 // 技术难题
//                                 .minkaveDetails-introduce5-title {
//                                     > div:nth-child(1) {
//                                         display: flex;
//                                         align-items: center;
//                                         height: 30px;
//                                         line-height: 30px;
//                                         padding: 10px;
//                                         box-sizing: border-box;
//                                         // border: 1px solid red;
//                                         background-color: #0056b0;

//                                         > div:nth-child(1) {
//                                             width: 1.5px;
//                                             height: 10px;
//                                             background: #fff;
//                                         }

//                                         > span {
//                                             margin-top: -1.5px;
//                                             margin-left: 6px;
//                                             font-size: 10px;
//                                             color: #fff;
//                                         }
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         .puzzleActive {
//                                             color: #fff;
//                                             background-color: #8fc320;
//                                         }

//                                         li {
//                                             text-align: center;
//                                             height: 20px;
//                                             line-height: 20px;
//                                             border-bottom: 0.5px solid #f2f2f2;
//                                             letter-spacing: 1px;
//                                             font-size: 8px;
//                                         }
//                                     }
//                                 }

//                                 // 技术难题推荐
//                                 .minkaveDetails-introduce5-title2 {
//                                     margin-top: 30px;
//                                     > div:nth-child(1) {
//                                         font-size: 10px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         li {
//                                             margin-top: 10px;
//                                             padding: 5px;
//                                             box-sizing: border-box;
//                                             border-radius: 4px;
//                                             font-size: 10px;
//                                             background-color: #f8f8f8;
//                                             // border: 1px solid red;

//                                             > div:nth-child(1) {
//                                                 font-size: 8px;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                                 // border: 1px solid red;
//                                             }

//                                             > div:nth-child(2) {
//                                                 margin-top: 4px;
//                                                 font-size: 7px;
//                                                 color: #9f9f9f;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 详情右侧内容
//                         .survey-detail-right {
//                             width: 70%;
//                             margin-left: auto;
//                             // border: 1px solid red;

//                             .detail-right {
//                                 width: 100%;
//                                 font-size: 12px;
//                                 background-color: #f8f8f8;

//                                 .detail-right-place {
//                                     font-size: 6px;
//                                     padding: 5px;
//                                     box-sizing: border-box;
//                                     border-bottom: 1px solid #e6e6e6;
//                                 }

//                                 .detail-right-cont {
//                                     padding: 5px;

//                                     > div:nth-child(1) {
//                                         font-size: 12px;
//                                         margin: 6px 0;
//                                         margin-left: -5px;
//                                     }

//                                     > div:nth-child(2) {
//                                         font-size: 7px;
//                                         margin: 6px 0;
//                                     }

//                                     > div:nth-child(3) {
//                                         font-size: 7px;
//                                         padding: 6px 0;
//                                         border-bottom: 0.5px solid #e6e6e6;
//                                     }

//                                     > ul {
//                                         list-style: none;
//                                         padding: 5px 0;

//                                         // 奇数行
//                                         li:nth-of-type(odd) {
//                                             font-size: 10px;
//                                         }

//                                         // 偶数行
//                                         li:nth-of-type(even) {
//                                             font-size: 6px;
//                                             padding: 6px 0;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }
//                                     }

//                                     > div:nth-child(5) {
//                                         display: flex;
//                                         margin: 10px 0;
//                                         // border: 1px solid red;

//                                         > div:nth-child(1) {
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #40509e;
//                                             // border: 1px solid red;
//                                         }

//                                         > div:nth-child(2) {
//                                             margin-left: 30px;
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #f9653f;
//                                             // border: 1px solid red;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
