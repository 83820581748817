<template>
    <!-- 关于学会页面 -->
    <div class="survey">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="survey-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">关于学会</div>
                    <div class="survey-title-sn">About the Institute</div>
                </div>
                <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in surveyTap"
                        @click="surveyTapEv(index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul>
                <div class="search" v-if="currentIndex==7">
                    <input type="text" v-model="keyword" />
                    <div @click="search">搜索</div>
                </div>
            </div>

            <!-- 内容区域,包含学会概况、学会章程、入会申请、通知通告、学会要闻、企业会员 -->
            <div
                class="survey-cont"
                v-if="
                    currentIndex == 0 || currentIndex == 1 || currentIndex == 4
                "
            >
                <div
                    :key="indexa"
                    class="survey-content"
                    v-for="(itema, indexa) in surveyData"
                >
                    <div class="survey-content-left">
                        <!-- 关于学会概况左侧数据 -->
                        <div
                            class="survey-left-abouts"
                            v-if="currentIndex == 0"
                            v-html="aboutsDe.content"
                        ></div>
                        <!-- 学会章程左侧数据 -->
                        <div
                            class="survey-left-process"
                            v-if="currentIndex == 1"
                            v-html="processDe.content"
                        ></div>
                        <!-- 入会申请左侧数据 -->
                        <div
                            class="survey-left-apply"
                            v-if="currentIndex == 4"
                            v-html="applyDe.content"
                        ></div>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>

            <!-- 内容区域,包含学会工作、分支机构 -->
            <div class="survey-cont" v-else>
                <div
                    :key="indexa"
                    class="survey-content"
                    v-for="(itema, indexa) in surveyData"
                >
                    <div class="survey-content-left">
                        <!-- 关于学会左侧内容 -->
                        <LearnLeft
                            v-if="
                                currentIndex == 2 &&
                                JSON.stringify(worksList) != ''
                            "
                            :worksList="worksList"
                            @page="worksPage"
                        ></LearnLeft>
                        <!-- 分支机构左侧内容 -->
                        <BranchLeft
                            v-if="
                                currentIndex == 3 &&
                                JSON.stringify(branchsList) != ''
                            "
                            :branchsList="branchsList"
                            @page="branchsPage"
                        ></BranchLeft>
                        <!-- 通告左侧内容 -->
                        <AnnunciateLeft
                            v-if="
                                currentIndex == 5 &&
                                JSON.stringify(noticesList) != ''
                            "
                            :noticesList="noticesList"
                            @page="noticesPage"
                        ></AnnunciateLeft>
                        <!-- 学会要闻左侧内容 -->
                        <NewsLeft
                            v-if="
                                currentIndex == 6 &&
                                JSON.stringify(newsList) != ''
                            "
                            :newsList="newsList"
                            @page="newsPage"
                        ></NewsLeft>
                        <!-- 企业会员左侧内容 -->
                        <MemberLeft
                            v-if="
                                currentIndex == 7 &&
                                JSON.stringify(companylistDe) != ''
                            "
                            :companylistDe="companylistDe"
                            @page="companyPage"
                        ></MemberLeft>
                        <!-- 内容区域 -->
                    </div>

                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import LearnLeft from "@/components/About/LearnLeft.vue"; //关于学会左侧组件
import BranchLeft from "@/components/About/BranchLeft.vue"; //分支机构左侧组件
import AnnunciateLeft from "@/components/About/AnnunciateLeft.vue"; //通告左侧组件
import NewsLeft from "@/components/About/NewsLeft.vue"; //学会要闻左侧组件
import MemberLeft from "@/components/About/MemberLeft.vue"; //企业会员左侧组件
import Right from "@/components/Right.vue"; //关于右侧组件
import Nav from "@/components/About/nav.vue"; //关于tap切换
import Advertising from "@/components/Advertising.vue"; //广告图
import HeadNav from "@/components/Nav.vue"; //导航
import {
    abouts,
    process,
    apply,
    works,
    branchs,
    notices,
    news,
    companylist,
    qieyeSearch
    
} from "../../../../network/data";
export default {
    name: "survey",
    data() {
        return {
            searchpagesize:4,
            searchpage:1,
            keyword:'',
            // 当前tap索引
            currentIndex: 1,
            // 关于学会tap数据
            surveyTap: [
                {
                    name: "学会概况",
                },
                {
                    name: "学会章程",
                },
                {
                    name: "学会工作",
                },
                {
                    name: "分支机构",
                },
                {
                    name: "入会申请",
                },
                {
                    name: "通知通告",
                },
                {
                    name: "学会要闻",
                },
                {
                    name: "企业会员",
                },
            ],
            // 内容区域数据
            surveyData: [
                {
                    name: "456",
                },
            ],
            // 关于学会概况数据
            aboutsDe: {},
            // 关于学会章程数据
            processDe: {},
            // 入会申请数据
            applyDe: {},
            // 学会工作数据
            worksList: {},
            // 学会工作分页数据
            worksParams: {
                page: 1,
                pageSize: 3,
            },
            // 分支机构数据
            branchsList: {},
            // 分支机构分页数据
            branchsParams: {
                page: 1,
                pageSize: 3,
            },
            // 通知通告数据
            noticesList: {},
            // 通知通告分页数据
            noticesParams: {
                page: 1,
                pageSize: 3,
            },
            // 学会要闻数据
            newsList: {},
            // 学会要闻分页数据
            newsParams: {
                page: 1,
                pageSize: 3,
            },
            // 企业会员数据
            companylistDe: {},
            // 企业会员分页数据
            companyParams: {
                page: 1,
                pageSize: 9,
            },
        };
    },
    computed: {},
    watch: {
        $route(to, from) {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        },
    },
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.currentIndex = this.$route.query.type;
            // this.surveyTapEv(this.$route.query.type);
        }

        // 获取关于学会概况数据
        this.aboutsRe();
        // 获取学会章程数据
        this.processRe();
        // 获取入会申请数据
        this.applyRe();
        // 获取学会工作数据
        this.worksRe();
        // 获取分支机构数据
        this.branchsRe();
        // 获取通知通告数据
        this.noticesRe();
        // 获取学会要闻数据
        this.newsRe();
        // 获取企业会员
        this.companylistRe();
    },
    methods: {
        // 搜索企业会员数据
        search() {
            let data ={
                page:this.companyParams.page,
                pagesize:this.companyParams.pageSize,
                keyword:this.keyword
                
            }
            qieyeSearch(data).then((res)=>{
                if(res.code==1) {
                    this.companylistDe =res.data
                    this.keyword=''
                }
            
        })
        },
        // 关于学会页面tap切换
        surveyTapEv(index) {
            // this.surveyData = [];
            this.currentIndex = index;
            console.log(this.currentIndex, "企业会员数据");
            if (this.currentIndex == 0) {
                this.aboutsRe();
            } else if (this.currentIndex == 1) {
                this.processRe();
            } else if (this.currentIndex == 2) {
                this.worksRe();
            } else if (this.currentIndex == 3) {
                this.branchsRe();
            } else if (this.currentIndex == 4) {
                this.applyRe();
            } else if (this.currentIndex == 5) {
                this.noticesRe();
            } else if (this.currentIndex == 6) {
                this.newsRe();
            } else if (this.currentIndex == 7) {
                this.companylistRe();
            }
        },
        // 获取关于学会概况数据
        aboutsRe() {
            abouts().then((res) => {
                if (res.code == 1) {
                    this.aboutsDe = res.data;
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取关于学会章程数据
        processRe() {
            process().then((res) => {
                if (res.code == 1) {
                    this.processDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取入会申请数据
        applyRe() {
            apply().then((res) => {
                if (res.code == 1) {
                    this.applyDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取学会工作数据
        worksRe() {
            let data = {
                menuid: 4,
                page: this.worksParams.page,
                pagesize: this.worksParams.pageSize,
            };
            works(data).then((res) => {
                if (res.code == 1) {
                    this.worksList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取分支机构数据
        branchsRe() {
            let data = {
                menuid: 5,
                page: this.branchsParams.page,
                pagesize: this.branchsParams.pageSize,
            };
            branchs(data).then((res) => {
                if (res.code == 1) {
                    this.branchsList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取通知通告数据
        noticesRe() {
            let data = {
                menuid: 7,
                page: this.noticesParams.page,
                pagesize: this.noticesParams.pageSize,
            };
            notices(data).then((res) => {
                if (res.code == 1) {
                    this.noticesList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取学会要闻数据
        newsRe() {
            let data = {
                menuid: 8,
                page: this.newsParams.page,
                pagesize: this.newsParams.pageSize,
            };
            news(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.newsList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },

        //获取企业会员数据
        companylistRe() {
            let data = {
                page: this.companyParams.page,
                pagesize: this.companyParams.pageSize,
            };
            companylist(data).then((res) => {
                if (res.code == 1) {
                    this.companylistDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 学会工作子组件分页事件
        worksPage(page) {
            this.worksParams.page = page;
            this.worksRe();
        },
        // 分支机构子组件分页事件
        branchsPage(page) {
            this.branchsParams.page = page;
            this.branchsRe();
        },
        // 通知通告子组件分页事件
        noticesPage(page) {
            this.noticesParams.page = page;
            this.noticesRe();
        },
        // 学会要闻子组件分页事件
        newsPage(page) {
            this.newsParams.page = page;
            this.newsRe();
        },
        // 企业会员子组件分页事件
        companyPage(page) {
            this.companyParams.page = page;
            this.companylistRe();
            this.search()
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        LearnLeft,
        BranchLeft,
        AnnunciateLeft,
        NewsLeft,
        Right,
        Nav,
        Advertising,
        MemberLeft,
        HeadNav,
    },
};
</script>

<style lang="scss" scoped>
// .survey {
//     width: 100%;
//     .survey-wrap {
//         width: 70%;
//         // height: 1000px;
//         margin: 0 auto;
//         margin-bottom: 40px;
//         // border: 1px solid red;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 35px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 1px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 24%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;

//                 .survey-title-cn {
//                     font-size: 13px;
//                     color: #0056b0;
//                     margin-right: 5px;
//                     // border: 1px solid red;
//                 }
//                 .survey-title-sn {
//                     height: 10px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     background-color: #0056b0;
//                 }
//             }

//             > ul {
//                 width: 70%;
//                 list-style: none;
//                 display: flex;

//                 .active {
//                     color: #0068d5;
//                 }

//                 li {
//                     cursor: pointer;
//                     text-align: center;
//                     margin-left: 5px;
//                     padding-left: 5px;
//                     font-size: 8px;
//                     border-left: 1px solid #e9e9e9;
//                     // border: 1px solid red;
//                 }
//             }
//             .search {
//                 margin-top: 1px;
//                 flex: 1;
//                 display: flex;
//                 align-items: center;
//                 height: 10px;

//                 > input {
//                     width: 50px;
//                     height: 10px !important;
//                 }
//                 > div {
//                     background-color: #0068d5;
//                     color: #fff;
//                     width: 20px;
//                     height: 12px;
//                     font-size: 8px;
//                     text-align: center;
//                 }
//             }
//         }
//         /*关于学会tap end*/

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin: 0 auto;
//                 // border: 1px solid red;

//                 // 内容区域左侧
//                 .survey-content-left {
//                     width: 72%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     // 学会概况
//                     .survey-left-abouts {
//                         margin-top: 20px;
//                         font-size: 10px;
//                     }
//                     // 学会章程
//                     .survey-left-process {
//                         margin-top: 20px;
//                         font-size: 10px;
//                     }

//                     // 入会申请
//                     .survey-left-apply {
//                         margin-top: 20px;
//                         font-size: 10px;
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 25%;
//                     // border: 1px solid red;
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .survey {
//         width: 100%;
//         .survey-wrap {
//             width: 70%;
//             // height: 1000px;
//             margin: 0 auto;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 35px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;
//                 .survey-nav-title {
//                     width: 24%;
//                     display: flex;
//                     align-items: center;

//                     .survey-title-cn {
//                         text-align: center;

//                         font-size: 8px;
//                         color: #0056b0;
//                         margin-right: 6px;
//                         // border: 1px solid red;
//                     }
//                     .survey-title-sn {
//                         height: 10px;
//                         font-size: 4px;
//                         color: #fff;
//                         padding: 0 6px;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         background-color: #0056b0;
//                     }
//                 }

//                 > ul {
//                     width: 60%;
//                     list-style: none;
//                     display: flex;
//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         width: 50px;
//                         margin-left: 3px;
//                         padding-left: 3px;
//                         font-size: 6px;
//                         border-left: 1px solid #e9e9e9;
//                     }
//                 }
//                 .search {
//                     margin-left: 2px;
//                     margin-top: 1px;
//                     flex: 1;
//                     display: flex;
//                     align-items: center;
//                     height: 10px;

//                     > input {
//                         width: 58px;
//                         height: 10px !important;
//                     }
//                     > div {
//                         background-color: #0068d5;
//                         color: #fff;
//                         width: 20px;
//                         height: 12px;
//                         font-size: 8px;
//                         text-align: center;
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;
//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin: 0 auto;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 72%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         // 学会概况
//                         .survey-left-abouts {
//                             margin-top: 15px;
//                             font-size: 10px;
//                         }
//                         // 学会章程
//                         .survey-left-process {
//                             margin-top: 15px;
//                             font-size: 10px;
//                         }

//                         // 入会申请
//                         .survey-left-apply {
//                             margin-top: 15px;
//                             font-size: 10px;
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 25%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .survey {
//         width: 100%;
//         .survey-wrap {
//             width: 80%;
//             // height: 700px;
//             margin: 0 auto;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 height: 30px;
//                 line-height: 30px;
//                 margin-top: 35px;
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 6px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 20%;
//                     display: flex;
//                     align-items: center;

//                     .survey-title-cn {
//                         width: 25px;
//                         font-size: 6px;
//                         color: #0056b0;
//                         margin-right: 6px;
//                         // border: 1px solid red;
//                     }
//                     .survey-title-sn {
//                         width: 40px;
//                         height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         background-color: #0056b0;
//                     }
//                 }

//                 > ul {
//                     width: 65%;
//                     list-style: none;
//                     display: flex;
//                     // border: 1px solid red;

//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         width: 30px;
//                         text-align: center;
//                         margin-left: 2px;
//                         padding-left: 2px;
//                         font-size: 7px;
//                         border-left: 1px solid #e9e9e9;
//                         // border: 1px solid red;
//                     }
//                 }
//                 .search {
//                     margin-top: 1px;
//                     flex: 1;
//                     display: flex;
//                     align-items: center;
//                     height: 10px;

//                     > input {
//                         width: 40px;
//                         height: 10px !important;
//                     }
//                     > div {
//                         background-color: #0068d5;
//                         color: #fff;
//                         width: 20px;
//                         height: 12px;
//                         font-size: 8px;
//                         text-align: center;
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin: 0 auto;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 66%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         // 学会概况
//                         .survey-left-abouts {
//                             margin-top: 10px;
//                             font-size: 8px;
//                         }
//                         // 学会章程
//                         .survey-left-process {
//                             margin-top: 10px;
//                             font-size: 8px;
//                         }

//                         // 入会申请
//                         .survey-left-apply {
//                             margin-top: 10px;
//                             font-size: 8px;
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
