<template>
    <div class="leftq">
        <div class="left-card">
            <ul class="ul2">
                <template v-if="JSON.stringify(memberlist) != ''">
                    <li
                        v-for="(itemb, indexb) in memberlist.data"
                        :key="indexb"
                        
                    >
                        <div class="science-card2">
                            <!-- 助企名家图片 -->
                            <div class="science-card2-img">
                                <img :src="itemb.image" alt="" />
                            </div>
                            <!-- 助企名家描述 -->
                            <div class="science-card2-title">
                                <div
                                    class="card2-title1"
                                    style="text-align: center"
                                >
                                    {{ itemb.name }}
                                </div>
                                <div class="card2-title2">
                                    {{ "职称：" + itemb.identityname }}
                                </div>
                                <div class="card2-title3">
                                    {{ "方向：" + itemb.skillname }}
                                </div>
                                <div class="card2-btn">
                                    <div @click="tankuang">咨询专家</div>
                                    <div @click="minkaveDetail(itemb, indexb)">专家主页</div>
                                </div>
                                <el-button type="text" @click="open"></el-button>
                            </div>
                        </div>
                    </li>
                </template>

                <!-- 左侧内容区域分页 -->
                <div class="pageDown">
                    <Pagination
                        :pageNo="searchParams.page"
                        :pageSize="searchParams.pageSize"
                        :total="total"
                        :continues="5"
                        @getPageNo="getPageEv"
                    />
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
    // 组件名称
    name: "left",
    // 组件参数 接收来自父组件的数据
    props: {
        memberlist: {
            type: Object,
            default: {},
        },
    },
    // 组件状态值
    data() {
        return {
            // 分页
            searchParams: {
                page: 1,
                pageSize: 5,
            },
            total: 10,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
        // 定义 total 侦听器，指向一个配置对象
        memberlist: {
            // handler 属性用来定义侦听器的 function 处理函数
            handler(newVal) {
                this.searchParams.page = newVal.current_page;
                if (newVal.per_page) {
                    this.searchParams.pageSize = newVal.per_page - 0;
                }
                if (newVal.total) {
                    this.total = newVal.total;
                }
            },
            // immediate 属性用来声明此侦听器，是否在页面初次加载完毕后立即调用
            immediate: true,
        },
    },
    // 组件方法
    created() {},
    methods: {
        tankuang(){
            this.$alert('请注册企业会员！', {
                confirmButtonText: '确定',
                });
        },
        // 分页触发事件
        getPageEv(index) {
            this.searchParams.page = index;
            this.$emit("page", index);
        },
        // 跳转到助企名家详情页
        minkaveDetail(item, index) {
            sessionStorage.setItem("specialist_id", item.id);
            this.$router.push({
                path: "/science/minkaveDetails",
                query: {
                    index: 0,
                },
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Pagination,
    },
};
</script>

<style lang="scss">
// .leftq {
//     width: 100%;

//     .left-card {
//         // 助企名家start
//         .ul2 {
//             display: flex;
//             justify-content: space-between;
//             flex-wrap: wrap;
//             margin-top: 10px;
//             list-style: none;

//             li {
//                 width: 49%;
//                 padding: 10px 8px;
//                 box-sizing: border-box;
//                 margin-bottom: 10px;
//                 // border: 1px solid orchid;

//                 .science-card2 {
//                     width: 100%;
//                     height: 90px;
//                     display: flex;
//                     padding: 5px;
//                     box-sizing: border-box;
//                     border: 1px solid #f5f5f5;

//                     // 助企名家图片
//                     .science-card2-img {
//                         width: 35%;
//                         height: 100%;
//                         margin-right: 10px;
//                         display: block;
//                         // line-height: 0;
//                         // border: 1px solid red;

//                         img {
//                             width: 100%;
//                             height: 100%;
//                             display: block;
//                         }
//                     }

//                     // 助企名家描述
//                     .science-card2-title {
//                         width: 60%;
//                         display: flex;
//                         flex-direction: column;
//                         // border: 1px solid red;

//                         .card2-title1 {
//                             font-size: 13px;
//                         }

//                         .card2-title2 {
//                             margin-top: 3px;
//                             font-size: 10px;
//                         }

//                         .card2-title3 {
//                             margin-top: 3px;
//                             font-size: 10px;
//                             color: #999999;
//                             white-space: nowrap;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }

//                         .card2-btn {
//                             display: flex;
//                             justify-content: space-between;
//                             margin-top: auto;

//                             > div {
//                                 padding: 3px;
//                                 color: #fff;
//                                 font-size: 8px;
//                                 background-color: #0056b0;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         // 助企名家end

//         // 左侧内容区域分页
//         .pageDown {
//             width: 100%;
//             margin: 20px auto;
//             text-align: start;
//             // border: 1px solid blue;

//             .el-pagination {
//                 display: flex;
//                 // border: 1px solid red !important;

//                 .btn-prev {
//                     width: 40px;
//                     height: 20px;
//                     color: black;
//                     box-sizing: border-box;
//                     border-radius: 0px !important;
//                     // border: 1px solid #337136 !important;

//                     > span {
//                         line-height: 20px;
//                         font-size: 10px !important;
//                     }
//                 }
//                 .el-pager {
//                     display: flex;
//                     .active {
//                         // background-color: #95bf97 !important;
//                     }
//                     li {
//                         display: flex;
//                         justify-content: center;
//                         height: 20px;
//                         width: 20px;
//                         line-height: 20px;
//                         font-size: 7px;
//                         color: black;
//                         padding: 0;
//                         box-sizing: border-box;
//                         border-radius: 0px !important;
//                         // border: 1px solid #337136 !important;
//                     }
//                 }

//                 .btn-next {
//                     width: 40px;
//                     height: 20px;
//                     color: black;
//                     box-sizing: border-box;
//                     border-radius: 0px !important;
//                     // border: 1px solid #337136 !important;

//                     > span {
//                         line-height: 20px;
//                         font-size: 10px !important;
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .leftq {
//         width: 100%;

//         .left-card {
//             // 助企名家start
//             .ul2 {
//                 display: flex;
//                 justify-content: space-between;
//                 flex-wrap: wrap;
//                 margin-top: 10px;
//                 list-style: none;

//                 li {
//                     width: 48%;
//                     padding: 10px 10px;
//                     box-sizing: border-box;
//                     margin-bottom: 10px;
//                     // border: 1px solid orchid;

//                     .science-card2 {
//                         width: 100%;
//                         height: 80px;
//                         display: flex;
//                         padding: 5px;
//                         border: 1px solid #f5f5f5;

//                         // 助企名家图片
//                         .science-card2-img {
//                             width: 45%;
//                             height: 100%;
//                             margin-right: 7px;
//                             display: block;
//                             // line-height: 0;
//                             // border: 1px solid red;

//                             img {
//                                 width: 100%;
//                                 height: 100%;
//                                 display: block;
//                             }
//                         }

//                         // 助企名家描述
//                         .science-card2-title {
//                             width: 50%;
//                             display: flex;
//                             flex-direction: column;
//                             // border: 1px solid red;

//                             .card2-title1 {
//                                 font-size: 13px;
//                             }

//                             .card2-title2 {
//                                 margin-top: 3px;
//                                 font-size: 10px;
//                             }

//                             .card2-title3 {
//                                 margin-top: 3px;
//                                 font-size: 8px;
//                                 color: #999999;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }

//                             .card2-btn {
//                                 display: flex;
//                                 justify-content: space-between;
//                                 margin-top: auto;

//                                 > div {
//                                     padding: 2px;
//                                     color: #fff;
//                                     font-size: 6px;
//                                     background-color: #0056b0;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//             // 助企名家end

//             // 左侧内容区域分页
//             .pageDown {
//                 width: 100%;
//                 margin: 20px auto;
//                 text-align: start;
//                 // border: 1px solid blue;

//                 .el-pagination {
//                     display: flex;
//                     // border: 1px solid red !important;

//                     .btn-prev {
//                         width: 40px;
//                         height: 20px;
//                         color: black;
//                         box-sizing: border-box;
//                         border-radius: 0px !important;
//                         // border: 1px solid #337136 !important;

//                         > span {
//                             line-height: 20px;
//                             font-size: 10px !important;
//                         }
//                     }
//                     .el-pager {
//                         display: flex;
//                         .active {
//                             // background-color: #95bf97 !important;
//                         }
//                         li {
//                             display: flex;
//                             justify-content: center;
//                             height: 20px;
//                             width: 20px;
//                             line-height: 20px;
//                             font-size: 7px;
//                             color: black;
//                             padding: 0;
//                             box-sizing: border-box;
//                             border-radius: 0px !important;
//                             // border: 1px solid #337136 !important;
//                         }
//                     }

//                     .btn-next {
//                         width: 40px;
//                         height: 20px;
//                         color: black;
//                         box-sizing: border-box;
//                         border-radius: 0px !important;
//                         // border: 1px solid #337136 !important;

//                         > span {
//                             line-height: 20px;
//                             font-size: 10px !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .leftq {
//         width: 100%;

//         .left-card {
//             // 助企名家start
//             .ul2 {
//                 display: flex;
//                 justify-content: space-between;
//                 flex-wrap: wrap;
//                 margin-top: 10px;
//                 list-style: none;

//                 li {
//                     width: 48%;
//                     // height: 100px;
//                     padding: 10px 3px;
//                     box-sizing: border-box;
//                     margin-bottom: 10px;
//                     // border: 1px solid orchid;

//                     .science-card2 {
//                         width: 100%;
//                         display: flex;

//                         // 助企名家图片
//                         .science-card2-img {
//                             width: 40%;
//                             height: 100%;
//                             margin-right: 10px;
//                             display: block;
//                             // line-height: 0;
//                             // border: 1px solid red;

//                             img {
//                                 width: 100%;
//                                 height: 100%;
//                                 display: block;
//                             }
//                         }

//                         // 助企名家描述
//                         .science-card2-title {
//                             width: 60%;
//                             display: flex;
//                             flex-direction: column;
//                             // border: 1px solid red;

//                             .card2-title1 {
//                                 font-size: 10px;
//                             }

//                             .card2-title2 {
//                                 margin-top: 3px;
//                                 font-size: 8px;
//                             }

//                             .card2-title3 {
//                                 margin-top: 3px;
//                                 font-size: 6px;
//                                 color: #999999;
//                             }

//                             .card2-btn {
//                                 display: flex;
//                                 justify-content: space-between;
//                                 margin-top: auto;

//                                 > div {
//                                     padding: 3px;
//                                     color: #fff;
//                                     font-size: 4px;
//                                     // background-color: #0056b0;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//             // 助企名家end

//             // 左侧内容区域分页
//             .pageDown {
//                 width: 100%;
//                 margin: 20px auto;
//                 text-align: start;
//                 // border: 1px solid blue;

//                 .el-pagination {
//                     display: flex;
//                     // border: 1px solid red !important;

//                     .btn-prev {
//                         width: 40px;
//                         height: 20px;
//                         color: black;
//                         box-sizing: border-box;
//                         border-radius: 0px !important;
//                         border: 1px solid #337136 !important;

//                         > span {
//                             line-height: 20px;
//                             font-size: 10px !important;
//                         }
//                     }
//                     .el-pager {
//                         display: flex;
//                         .active {
//                             background-color: #95bf97 !important;
//                         }
//                         li {
//                             display: flex;
//                             justify-content: center;
//                             height: 20px;
//                             width: 20px;
//                             line-height: 20px;
//                             font-size: 7px;
//                             color: black;
//                             padding: 0;
//                             box-sizing: border-box;
//                             border-radius: 0px !important;
//                             border: 1px solid #337136 !important;
//                         }
//                     }

//                     .btn-next {
//                         width: 40px;
//                         height: 20px;
//                         color: black;
//                         box-sizing: border-box;
//                         border-radius: 0px !important;
//                         border: 1px solid #337136 !important;

//                         > span {
//                             line-height: 20px;
//                             font-size: 10px !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
