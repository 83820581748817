<template>
    <div class="mNews">
        <Mheader :currentIndex="4"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <div class="introduces-wrap">
            <div class="introduces-cont">
                <!-- 新闻资讯标题 -->
                <div class="introduces-cont-title">
                    <div>新闻资讯</div>
                    <div>about us</div>
                </div>

                <!-- 新闻资讯内容区域 -->
                <div class="introduces-cont-box">
                    <ul>
                        <li
                            :key="index"
                            @click="goNewsDetail(item)"
                            v-for="(item, index) in companyNewsList"
                        >
                            <div class="introduces-item-left">
                                <img :src="item.image" alt="" />
                            </div>
                            <div class="introduces-item-right">
                                <div>{{ item.title }}</div>
                                <div>发布时间：{{ item.createtime }}</div>
                                <div>{{ item.description }}</div>
                            </div>
                        </li>
                    </ul>

                    <!-- 左侧内容区域分页 -->
                    <div class="pageDown">
                        <Pagination
                            :pageNo="searchParams.page"
                            :pageSize="searchParams.pageSize"
                            :total="total"
                            :continues="5"
                            @getPageNo="getPageEv"
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer";
import MeSwiper from "../../../components/Member/swiper.vue";
import { companyNews } from "../../../network/data";
import Pagination from "@/components/Pagination";
export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前导航栏id
            menu_id: "",
            // 分页
            searchParams: {
                page: 1,
                pageSize: 6,
            },
            total: 10,
            // 新闻列表数据
            companyNewsList: [],
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;

            // 获取新闻列表数据
            this.companyNewsRe();
        }
    },
    methods: {
        // 获取新闻列表数据
        companyNewsRe() {
            let data = {
                id: sessionStorage.getItem("id"),
                menuid: this.menu_id,
                page: this.searchParams.page,
                pagesize: this.searchParams.pageSize,
            };
            companyNews(data).then((res) => {
                if (res.code == 1) {
                    console.log(res.data);
                    this.searchParams.page = res.data.list.current_page;
                    this.searchParams.pageSize = res.data.list.per_page;
                    this.total = res.data.list.total;
                    this.companyNewsList = res.data.list.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 分页点击事件
        getPageEv(e) {
            this.searchParams.page = e;
            this.companyNewsRe();
        },
        // 跳转到新闻详情页
        goNewsDetail(item) {
            this.$router.push({
                path: "/mNewsDetail",
                query: {
                    id: item.id,
                    menu_id: this.menu_id,
                },
            });
            window.scrollTo(0, 0);
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        Pagination,
        MeSwiper,
    },
};
</script>

<style lang="scss">
// .mNews {
//     width: 100%;

//     img {
//         display: block;
//         height: 100%;
//         width: 100%;
//     }

//     // 版心
//     .introduces-wrap {
//         width: 70%;
//         height: 800px;
//         margin: 0 auto;

//         .introduces-cont {
//             width: 100%;
//             height: 100%;
//             margin-top: 20px;
//             // border: 1px solid red;

//             // 新闻资讯标题
//             .introduces-cont-title {
//                 text-align: center;

//                 > div:nth-child(1) {
//                     font-size: 12px;
//                 }

//                 > div:nth-child(2) {
//                     font-size: 10px;
//                     color: #999999;
//                 }
//             }

//             // 新闻资讯内容区域
//             .introduces-cont-box {
//                 height: 100%;
//                 margin: 20px 0;

//                 > ul {
//                     list-style: none;

//                     li {
//                         display: flex;
//                         width: 100%;
//                         height: 90px;
//                         padding: 10px;
//                         margin-bottom: 20px;
//                         box-sizing: border-box;
//                         box-shadow: 2px 2px 10px #e1e0e0;
//                         // border: 1px solid red;

//                         // 新闻资讯内容区域左侧
//                         .introduces-item-left {
//                             width: 18%;
//                             height: 100%;

//                             img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }

//                         // 新闻资讯内容区域右侧
//                         .introduces-item-right {
//                             display: flex;
//                             flex-direction: column;
//                             width: 80%;
//                             height: 100%;
//                             margin-left: auto;

//                             > div:nth-child(1) {
//                                 font-size: 12px;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }

//                             > div:nth-child(2) {
//                                 margin-top: 5px;
//                                 font-size: 10px;
//                                 color: #b3b3b3;
//                             }

//                             > div:nth-child(3) {
//                                 margin-top: 15px;
//                                 font-size: 8px;
//                                 color: #808080;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }
//                         }
//                     }
//                 }

//                 // 左侧内容区域分页
//                 .pageDown {
//                     display: flex;
//                     justify-content: center;
//                 }
//             }
//         }
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) {
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .mNews {
//         width: 100%;

//         img {
//             display: block;
//             height: 100%;
//             width: 100%;
//         }

//         // 版心
//         .introduces-wrap {
//             width: 80%;
//             // height: 495px;
//             margin: 0 auto;
//             margin-bottom: 20px;

//             .introduces-cont {
//                 width: 100%;
//                 height: 100%;
//                 margin-top: 20px;
//                 // border: 1px solid red;

//                 // 新闻资讯标题
//                 .introduces-cont-title {
//                     text-align: center;

//                     > div:nth-child(1) {
//                         font-size: 12px;
//                     }

//                     > div:nth-child(2) {
//                         font-size: 10px;
//                     }
//                 }

//                 // 新闻资讯内容区域
//                 .introduces-cont-box {
//                     height: 100%;

//                     > ul {
//                         list-style: none;

//                         li {
//                             display: flex;
//                             width: 100%;
//                             height: 80px;
//                             padding: 5px;
//                             margin-bottom: 10px;
//                             box-sizing: border-box;
//                             box-shadow: 2px 2px 10px #e1e0e0;
//                             // border: 1px solid red;

//                             // 新闻资讯内容区域左侧
//                             .introduces-item-left {
//                                 width: 23%;
//                                 height: 100%;

//                                 img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             // 新闻资讯内容区域右侧
//                             .introduces-item-right {
//                                 display: flex;
//                                 flex-direction: column;
//                                 width: 76%;
//                                 height: 100%;
//                                 margin-left: auto;

//                                 > div:nth-child(1) {
//                                     font-size: 12px;
//                                 }

//                                 > div:nth-child(2) {
//                                     margin-top: 5px;
//                                     font-size: 10px;
//                                     color: #b3b3b3;
//                                 }

//                                 > div:nth-child(3) {
//                                     margin-top: 8px;
//                                     font-size: 10px;
//                                     color: #808080;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 2; //行数
//                                     -webkit-box-orient: vertical;
//                                 }
//                             }
//                         }
//                     }

//                     // 左侧内容区域分页
//                     .pageDown {
//                         width: 70%;
//                         margin: 20px auto;
//                         text-align: start;
//                         // border: 1px solid blue;
//                     }
//                 }
//             }
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
