<template>
    <div class="index">
        <Header></Header>
        <HeadNav></HeadNav>
        <Search></Search>

        <div class="index-wrap">
            <!-- 顶部图片 -->
            <HeadImg></HeadImg>

            <!-- 通告 -->
            <div class="index-annunciate">
                <div class="index-annunciate-left">
                    <Swiper :pic_src="pic_src"></Swiper>
                </div>
                <div class="index-annunciate-right">
                    <div class="index-right-hed">
                        <div class="index-text">通知通告</div>
                        <div class="index-text1" @click="goAnnunciateMore()">
                            更多》
                        </div>
                    </div>
                    <div class="index-right-list">
                        <ul>
                            <li :key="index" @click="goAnnunciateDetail(item.id, index)"
                                v-for="(item, index) in noticelistData">
                                <div class="index-list-data">
                                    {{ item.year }}<span>{{ item.day }}</span>
                                </div>
                                <div class="index-list-text">
                                    {{ item.title }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- 产业智造 -->
            <div class="index-industry">
                <!-- 产业制造左侧 -->
                <div class="index-industry-left">
                    <!-- 头部 -->
                    <div class="index-induleft">
                        <div class="index-indutitle">产业智造</div>
                        <div class="index-induright">
                            <ul>
                                <li :key="indexj" @click="industryTapEv(indexj)" v-for="(itemj, indexj) in makeTapDe"
                                    :class="industryCurrent == indexj
                                        ? 'industryActive'
                                        : ''
                                        ">
                                    {{ itemj.name }}
                                </li>
                                <span @click="goMachining">更多</span>
                            </ul>
                        </div>
                    </div>

                    <!-- 内容区域 -->
                    <div class="index-industry-body">
                        <!-- 左侧 -->
                        <div class="index-industryB-list">
                            <div v-if="makeListFrontLeft[0]" @click="goMade">
                                <img :src="makeListFrontLeft[0].image" />
                            </div>
                            <ul>
                                <li :key="index" v-for="(item, index) in makeListFrontLeft"
                                    @click="goMadeDetail(item, index)">
                                    <div class="index-list-title">
                                        {{ item.title }}
                                    </div>
                                    <div class="index-list-text">
                                        {{ item.subtitle }}
                                    </div>
                                    <div class="index-list-text">
                                        {{ item.description }}
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <!-- 右侧 -->
                        <div class="index-industryB-list">
                            <div v-if="makeListFrontRight[0]" @click="goMades">
                                <img :src="makeListFrontRight[0].image" />
                            </div>
                            <ul>
                                <li :key="index" v-for="(item, index) in makeListFrontRight"
                                    @click="goMadeDetail(item, index)">
                                    <div class="index-list-title">
                                        {{ item.title }}
                                    </div>
                                    <div class="index-list-text">
                                        {{ item.subtitle }}
                                    </div>
                                    <div class="index-list-text">
                                        {{ item.description }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- 产业制造右侧 -->
                <div class="index-industry-right">
                    <!-- 产业制造右侧头部 -->
                    <div class="index-indright">
                        <div class="index-indright-L">关于学会</div>
                        <div class="index-indright-R">更多</div>
                    </div>
                    <!-- 产业制造右侧文字详情 -->
                    <div class="index-indright-text" @click="goAboutSummarize">
                        <div>
                            河南省食品科学技术学会，是经中华人民共
                            和国民政部批准登记的国家级社团组织，总 社团组
                        </div>
                        <span>【详情】</span>
                    </div>
                    <!-- 入会申请、培训报名…… -->
                    <div class="index-indright-list">
                        <ul>
                            <li v-for="(item, index) in indexImg" :key="index" @click="ImageClick(item)">
                                <div>
                                    <div>
                                        <img src="../../assets/pc/Index/indexImg1.png" />
                                    </div>
                                    <span>{{ item.name }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- 产业制造右侧图片 -->
                    <div class="index-indright-img">
                        <img :src="image.image" />
                    </div>
                </div>
            </div>

            <!-- 企业会员 -->
            <div class="member">
                <div class="index-member">
                    <div class="index-member-title">企业会员</div>
                    <div class="index-memberright">
                        <ul>
                            <li :key="indexe" :class="companys1Current == indexe
                                ? 'companys1Active'
                                : ''
                                " @click="companys1Ev(indexe)" v-for="(iteme, indexe) in companysTapDe1">
                                {{ iteme.name }}
                            </li>
                        </ul>
                        <span @click="advertising">更多</span>
                    </div>
                </div>
                <!-- 会员滚动 -->
                <div class="attract">
                    <ul>
                        <li @click="member(item)" v-for="(item, index) in itemList">
                            <img @dragstart.prevent :src="item.logo" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- 科学赋能 -->
        <div class="index-science">
            <div class="index-science-wrap">
                <!-- 科学赋能左侧 -->
                <div class="index-science-left">
                    <!-- 科学赋能标题 -->
                    <div class="iScience-left-title">
                        <div class="iScience-title-text">
                            <span>科技</span>赋能
                        </div>
                        <div class="iScience-title-ms">
                            提供一食品科技研究服务系统
                        </div>
                    </div>

                    <!-- 科学赋能标题左侧侧tap切换 -->
                    <div class="iScience-left-tap">
                        <ul>
                            <li :key="indexa" v-for="(itema, indexa) in science" @click="scienceEv(indexa)" :class="scienceCurrent == indexa
                                ? 'scienceActive'
                                : ''
                                ">
                                {{ itema.name }}
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- 科学赋能右侧 -->
                <div class="index-science-right">
                    <div class="science-right-tap">
                        <ul>
                            <li @click="scienceTile(index)" v-for="(item, index) in scienceTop" :class="scienceTileCurrent == index
                                ? 'avtiveli'
                                : ''
                                " :key="index">
                                {{ item.name }}
                            </li>
                        </ul>
                        <span @click="more">更多></span>
                    </div>

                    <div class="science-right-cont">
                        <!-- 科技资源布局 -->
                        <ul v-if="scienceCurrent == 0" class="ul1">
                            <li v-for="(itemb, indexb) in scienceList" @click="goCollege(itemb.id)" :key="indexb">
                                <div class="science-card1">
                                    <img :src="itemb.logo"></img>
                                    <div>
                                        <b>{{ itemb.name }}</b>
                                        <!-- <span>Red-eyed tree f these hypotheses have been confirmed.</span> -->
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- 助企名家 -->
                        <ul v-if="scienceCurrent == 1" class="ul2">
                            <li v-for="(itemb, indexb) in scienceList" :key="indexb" @click="goSpecialistDetail(itemb)">
                                <div class="science-card2">
                                    <!-- 助企名家图片 -->
                                    <div class="science-card2-img">
                                        <img :src="itemb.image" alt="" />
                                    </div>
                                    <!-- 助企名家描述 -->
                                    <div class="science-card2-title">
                                        <div class="card2-title1">
                                            {{ itemb.name }}
                                        </div>
                                        <div class="card2-title2">
                                            {{ itemb.identityname }}
                                        </div>
                                        <div class="card2-title3">
                                            {{ itemb.skillname }}
                                        </div>
                                        <div class="card2-btn">
                                            <div>咨询专家</div>
                                            <div>专家主页</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- 助企活动 -->
                        <ul v-if="scienceCurrent == 2" class="ul3">
                            <li v-for="(itemb, indexb) in scienceList" :key="indexb" @click="goActivityDetail(indexb)">
                                <div class="science-card3">
                                    <!-- 助企活动图片 -->
                                    <img :src="itemb.image" alt="" />
                                    <!-- 助企活动标题 -->
                                    <div class="science-card3-title">
                                        {{ itemb.title }}
                                    </div>
                                    <!-- 助企活动描述 -->
                                    <div class="science-card3-ms">
                                        {{ itemb.description }}
                                    </div>
                                    <!-- 助企活动底部样式 -->
                                    <div class="science-card3-footer">
                                        <div class="science-card3-footer1">
                                            报名中
                                        </div>
                                        <div class="science-card3-footer3">
                                            <img src="../../assets/pc/Index/clock.png" alt="" />
                                            <div>{{ itemb.htime }}</div>
                                        </div>
                                        <!-- <div class="science-card3-footer2">
                                            <img
                                                src="../../assets/pc/Index/clock.png"
                                                alt=""
                                            />
                                            <div>{{itemb.htime}}</div>
                                        </div> -->
                                        <div class="science-card3-footer3">
                                            <img src="../../assets/pc/Index/location.png" alt="" />
                                            <div>上海</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- 产品需求 -->
                        <ul v-if="scienceCurrent == 3" class="ul4">
                            <li :key="indexb" v-for="(itemb, indexb) in scienceList" @click="goInviteDetail(itemb)">
                                <!-- 技术难题 -->
                                <div class="science-card4" v-show="scienceTileCurrent == 0">
                                    <img src="../../assets/pc/Index/qf.png"></img>
                                    <div class="science-card4-start">
                                        <!-- <div>未解决</div> -->
                                        <div>{{ itemb.company }}</div>
                                    </div>
                                    <div class="science-card4-tag0">
                                        <span>发布单位：</span>
                                        <div>{{ itemb.company }}</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>合作方式：{{ itemb.lable }}</div>
                                        <div>应用领域：{{ itemb.lablename }}</div>
                                    </div>
                                    <div class="science-card4-tag5">
                                        <div>应用领域：{{ itemb.usename }}</div>
                                    </div>
                                    <div class="science-card4-tag4">
                                        <div>需求描述：<div>{{ itemb.content }}</div>
                                        </div>
                                    </div>
                                    <div class="science-card4-tag2">
                                        <div> 回答 | {{ itemb.answernum }}</div>
                                        <div class="science-card4-tag3">
                                            <div v-if="companyType == 2" @click="issueEv(0)">
                                                <img src="../../assets/pc/Index/fj.png" />
                                                <div>发布难题</div>
                                            </div>
                                            <div @click="issueEv(1)" v-if="companyType == 1">
                                                <img src="../../assets/pc/Index/bj.png" />
                                                <div>我来答</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 成果转化 -->
                                <div class="science-card4" v-show="scienceTileCurrent == 1">
                                    <div class="survey-left2-title1">
                                        {{ itemb.title }}
                                    </div>
                                    <div class="survey-left2-title2">

                                        <div>成果类型：{{ itemb.catename }}</div>
                                        <div>完成单位：{{ itemb.authenticate }}</div>
                                    </div>
                                    <div class="survey-left2-title2">
                                        <div>成果阶段：{{ itemb.stage }}</div>
                                        <div>应用行业：{{ itemb.usedomain }}</div>
                                    </div>
                                    <div class="survey-left2-title2">
                                        <div>使用方式：{{ itemb.makeway }}</div>
                                        <div>技术领域：{{ itemb.technosphere }}</div>
                                    </div>
                                    <div class="survey-left2-footer">

                                        <div @click="resultDetil(itemb.id)">
                                            <img src="../../assets/pc/Index/eye.png" alt="" />
                                            <span>查看详情</span>
                                        </div>
                                        <div @click="resultIssue" v-if="companyType == 1">
                                            <img src="../../assets/pc/Index/bj.png" alt="" />
                                            <span>发布推广</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- 就业招聘 -->
                                <div class="science-card4" @click="inviteDetail(itemb)" v-show="scienceTileCurrent == 2">
                                    <div class="science-card4-start">
                                        <div>就业</div>
                                        <div>在寻找</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>姓名：{{ itemb.oldyear }}</div>
                                        <div>年龄：{{ itemb.oldyear }}</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>就业方向：{{ itemb.catename }}</div>
                                        <div>专业学历：{{ itemb.education }}</div>
                                    </div>
                                    <div class="science-card4-tag1">
                                        <div>联系方式：{{ itemb.phone }}</div>
                                        <div>工作经验：{{ itemb.workyear }}</div>
                                    </div>
                                    <div class="science-card4-tag3">
                                        <div class="science-card4-data">
                                            **回答 |
                                            <span>{{ itemb.time }}</span>
                                        </div>
                                        <div @click="obtain" v-if="companyType == 1">
                                            <img src="../../assets/pc/Index/fj.png" alt="" />
                                            <div>就业发布</div>
                                        </div>
                                        <!-- <div
                                            @click="bring"
                                            v-if="companyType == 2"
                                        >
                                            <img
                                                src="../../assets/pc/Index/bj.png"
                                                alt=""
                                            />
                                            <div>招聘发布</div>
                                        </div> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="index-wrap">
            <!-- 行业标准/政策法规 -->
            <div class="index-hye">
                <!-- 行业标准 -->
                <div class="index-hye-left">
                    <!-- 行业标准头部 -->
                    <div class="index-hye-title">
                        <div class="index-hyetitle">行业标准</div>
                        <!-- 行业标准头部tap -->
                        <div class="index-hyeright">
                            <span @click="goNormStandard">更多》</span>
                        </div>
                    </div>

                    <!-- 行业标准内容区 -->
                    <div class="index-hye-body">
                        <ul>
                            <li :key="indexc" v-for="(itemc, indexc) in normsList">
                                <!-- 行业标准列表左侧内容 -->
                                <div class="index-list-left">
                                    <div class="index-list-top">

                                        <div class="index-list-top2">
                                            {{ itemc.standardno }}
                                            {{ itemc.title }}
                                        </div>
                                        <!-- 行业标准列表右侧内容 -->
                                        <div class="index-list-right">
                                            <div class="down iconfont icon-xiazaidaoru"
                                                @click.stop="download(itemc.attachfile)">
                                                <span>下载</span>
                                            </div>
                                            <div class="check" @click="
                                                goStandardDetail(
                                                    itemc,
                                                    indexc
                                                )
                                                ">
                                                <span class="iconfont icon-sousuo">查看</span>
                                            </div>
                                            <div class="collect" @click="collectPop">
                                                <span class="iconfont icon-zengjia">
                                                    收藏</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="index-list-bottom">
                                        <span style="background-color: #0056b0; color: #ffffff;"> 标签：{{ itemc.lable[0] }}
                                            {{ itemc.lable[1] }}</span>
                                        发布日期：{{ itemc.releasetime }}|
                                        实施日期：{{ itemc.carrytime }} |

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 行业标准 -->
                <div class="index-policy-left">
                    <!-- 行业标准头部 -->
                    <div class="index-policy-title">
                        <div class="index-policytitle">政策法规</div>
                        <!-- 行业标准头部tap -->
                        <div class="index-policyright">
                            <span>更多》</span>
                        </div>
                    </div>

                    <!-- 行业标准内容区 -->
                    <div class="index-policy-body">
                        <ul>
                            <li v-for="(item, index) in lawsDe" :key="index">
                                <div>
                                    <img :src="item.image"></img>
                                </div>
                                <div>
                                    <div>{{ item.title }}</div>
                                    <div>{{ item.description }}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- 企业会员2 -->
            <div class="member">
                <div class="index-member">
                    <div class="index-member-title">企业会员</div>
                    <div class="index-memberright">
                        <ul>
                            <li :key="indexe" :class="companys2Current == indexe
                                ? 'companys1Active'
                                : ''
                                " @click="companys2Ev(indexe)" v-for="(iteme, indexe) in companysTapDe2">
                                {{ iteme.name }}
                            </li>
                        </ul>
                        <span @click="advertising">更多</span>
                    </div>
                </div>
                <!-- 会员2滚动 -->
                <div class="attract">
                    <ul>
                        <li @click="member(item)" v-for="(item, index) in itemList2">
                            <img @dragstart.prevent :src="item.logo" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>



        <!-- 产业观察 -->
        <div class="index-observe">
            <div class="index-observe-wrap">
                <!-- 产业观察头部 -->
                <div class="index-observe-title">
                    <div>产业观察</div>
                    <ul>
                        <li :key="indexh" @click="observeTapEv(indexh)" v-for="(itemh, indexh) in observeTapDe">
                            <div :class="observeCurrent == indexh
                                ? 'observeActive'
                                : ''
                                ">
                                {{ itemh.name }}
                            </div>
                            <div></div>
                        </li>
                    </ul>
                </div>

                <!-- 产业观察内容区域 -->
                <div class="index-observe-cont">
                    <!-- 产业观察内容左侧 -->
                    <div class="index-observe-left">
                        <ul>
                            <li :key="indexf" v-for="(itemf, indexf) in observeListLeft"
                                @click="goObserveDetail(itemf, indexf)">
                                <div style="background-color: #0056b0; width:10px; height:10px; border-radius: 50%; "></div>
                                <div>{{ itemf.title }}</div>
                                <div>{{ itemf.subtitle }}</div>
                                <div>{{ itemf.description }}</div>
                            </li>
                        </ul>
                    </div>
                    <!-- 产业观察内容右侧 -->
                    <div class="index-observe-right">
                        <div :key="indexg" class="index-box-item" v-for="(itemg, indexg) in observeListRight"
                            @click="goObserveDetail(itemg, indexg)">

                            <div class="index-right-box">
                                <img :src="itemg.image" />
                                <div class="index-right-text">
                                    <span style="display:block;font-size:20px;font-weight: 700;">{{ itemg.title }}</span>
                                    <span>{{ itemg.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="index-wrap">
            <!-- 智能金融 -->
            <div class="index-digifax">
                <div class="attract1">
                    <div class="attract1-title">
                        <div>产业专题</div>
                        <!-- <div>更多》</div> -->
                    </div>
                    <vue-seamless-scroll :data="itemList1" :class-option="optionLeft" class="seamless-warp2">
                        <ul class="item">
                            <li v-for="(item, index) in itemList1" :key="index" @click="goMadeDetail(item)">
                                <div class="index-digifax-img">
                                    <img :src="item.image" />
                                </div>
                                <div class="index-digifax-content">
                                    <div class="index-content-ms">
                                        <span>{{ item.title }}</span>
                                    </div>
                                    <!-- <div
                                        class="index-content-icon iconfont icon-jiantou_xiangyouliangci_o"
                                    ></div> -->
                                </div>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </div>

            <!-- 食品咨询 -->
            <div class="index-food">
                <!-- 产业制造左侧 -->
                <div class="index-industry-left">
                    <!-- 头部 -->
                    <div class="index-induleft">
                        <div class="index-indutitle">食品咨询</div>
                        <div class="index-memberright">
                            <ul>
                                <li v-for="(item, index) in foodCrumbs" :key="index" @click="crumbsEv(index)"
                                    :class="crumbsEvCurrent == index ? 'companys1Active' : ''">{{ item.name }}</li>
                            </ul>
                            <span @click="foodDetail">更多</span>
                        </div>
                    </div>

                    <!-- 内容区域 -->
                    <div class="index-industry-body">
                        <div @click="goCousultDetail(itemd.id)" :key="indexd" class="index-industryB-list"
                            v-for="(itemd, indexd) in foodCrumbs[crumbsEvCurrent].list">
                            <div v-if="indexd <= 2">
                                <img :src="itemd.image" />
                            </div>
                            <ul>
                                <li>
                                    <div class="index-list-title">
                                        <span></span>
                                        {{ itemd.title }}{{ itemd.id }}
                                    </div>
                                    <div class="index-list-text">
                                        {{ itemd.subtitle }}
                                    </div>
                                    <div class="index-list-text">
                                        {{ itemd.description }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 会员3 -->
            <ul class="index-member3">
                <li v-for="(item, index) in advlistDe" :key="index">
                    <img :src="item.advimage"></img>
                </li>
            </ul>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import BScroll from "better-scroll";
import Header from "@/components/Header.vue"; //顶部
import Search from "@/components/Search.vue"; //搜索
import Footer from "@/components/Footer.vue"; //底部
import HeadImg from "@/components/HeadImg.vue"; //头部图片
import HeadNav from "@/components/Nav.vue"; //导航
import Swiper from "@/components/Swiper.vue"
import download from "@/common/download.js";
import vueSeamlessScroll from 'vue-seamless-scroll'
import {
    noticelist,
    makeold,
    norms,
    observe,
    foods,
    companys,
    science,
    make,
    advlist,
    navlist,
    laws,
    downcompanys,
    advimagenew,
    noticeimage,
    schoolMenu
} from "../../network/data";
export default {
    // 组件名称
    name: "name",
    // 组件参数 接收来自父组件的数据
    // 局部注册的组件
    components: {
        Header,
        Search,
        Footer,
        HeadImg,
        HeadNav,
        Swiper,

        vueSeamlessScroll
    },
    props: {},
    // 组件状态值
    data() {
        return {
            image: '',//广告图
            // 判断是否显示发布难题按钮，1==显示,2==不显示
            companyType: 0,
            // 首页科学赋能左侧tap当前索引
            scienceCurrent: 0,
            // 首页科技赋能头部标题索引,默认为0
            scienceTileCurrent: 0,
            // 首页产业制造顶部tap当前索引
            industryCurrent: 0,
            // 首页行业标tap当前索引
            normsCurrent: 0,
            // 首页产业观察当前索引
            observeCurrent: 0,
            // 首页企业会员1当前索引
            companys1Current: 0,
            // 首页企业会员2当前索引
            companys2Current: 0,
            // 通知公告列表数据
            noticelistData: [],
            // 产业智造数据
            makeTapDe: [],
            makeListFrontLeft: [],
            makeListFrontRight: [],
            normsList: [],
            // 首页产业观察数据
            observeTapDe: [],
            observeListLeft: [],
            observeListRight: [],
            // 首页食品资讯数据
            foodsList: [],
            // 首页企业会员1数据
            companysTapDe1: [],
            // 首页企业会员2数据
            companysTapDe2: [],
            // 学院动态轮播图数据
            pic_src: [],
            // 科学赋能左侧tap
            science: [
                {
                    name: "科技资源",
                },
                {
                    name: "助企名家",
                },
                {
                    name: "助企活动",
                },
                {
                    name: "产业需求",
                },
            ],
            scienceTop: [],
            // 科技赋能右侧数据
            scienceList: [
                {
                    name: '郑州科技学院',
                    img: 'http://47.92.152.75/01Img/ce.jpg'
                },
                {
                    name: '郑州科技学院',
                    img: 'http://47.92.152.75/01Img/ce.jpg'
                }
            ],
            // 判断是否显示数智金融蓝色箭头
            showArrows: "",
            // 产业观察tap切换数据
            scienceTap: [],
            // 会员1
            itemList: [],
            // 会员2
            itemList2: [],
            //数智金融
            itemList1: [],
            // 广告图数据
            advlistDe: [],
            // 首页政策法规数据
            lawsDe: [],
            // 当前食品咨询面包屑
            crumbsEvCurrent: 0,
            // 食品咨询面包屑
            foodCrumbs: [],
            // 首页食品咨询图片
            foodsImg: '',
            // 关于学会图标
            indexImg: [

                { icon: '../../assets/pc/Index/indexImg1.png', name: '学会章程' ,id:1},
                { icon: '../../assets/pc/Index/indexImg2.png', name: '分支机构' ,id:2},
                { icon: '../../assets/pc/Index/indexImg3.png', name: '证书查询' ,id:3},
                { icon: '../../assets/pc/Index/indexImg4.png', name: '成果鉴定' ,id:4}
            ]
        };
    },

    // 侦听器
    watch: {},
    computed: {
        optionLeft() {
            return {
                direction: 2,
                limitMoveNum: 2,
            };
        },
    },
    // 组件方法
    created() {

        // 判断是否显示发布难题按钮
        this.companyType = sessionStorage.getItem("companyType");
        // 获取首页通知公告列表
        this.noticelistRe();
        // 获取首页产业智造数据
        this.makeRe();
        // 获取首页行业标准数据
        this.normsRe();
        // 获取首页产业观察数据
        this.observeRe();
        // 获取首页食品资讯数据
        this.foodsRe();
        // 获取首页企业会员数据
        this.companysRe();
        // 获取数智金融数据
        this.makeRewe();
        // 获取科技赋能数据
        this.scienceRe();
        // 获取广告图数据
        this.advlistRe()
        // 获取政策法规数据
        this.lawsRe()
        // 获取首页下面企业会员
        this.downcompanysRe()
        //获取广告图
        this.getImage()
        this.getbanner()
    },
    methods: {
        ImageClick(item){
            if(item.id == 3){
                this.$router.push({
                    path: "/certificate",
                });
            }
        },
        //轮播图
        getbanner() {
            noticeimage().then(res => {
                if (res.code == 1) {
                    this.pic_src = res.data
                }
            })
        },
        //获取广告图
        getImage() {
            let data = {
                type: 1
            }
            advimagenew(data).then(res => {
                if (res.code == 1) {
                    this.image = res.data.indexright

                }
            })
        },
        // 获取首页科技赋能数据
        scienceRe() {
            science().then((res) => {
                if (res.code == 1) {
                    this.scienceList =
                        res.data.cate[this.scienceCurrent].list[
                            this.scienceTileCurrent
                        ].list;
                    this.scienceTop = res.data.cate[this.scienceCurrent].list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取首页通知公告列表
        noticelistRe() {
            noticelist().then((res) => {
                if (res.code == 1) {
                    this.noticelistData = res.data;

                    // this.pic_src = this.noticelistData.map(v=>{

                    //     return v.image
                    // });

                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取首页产业智造数据
        makeRe() {
            makeold().then((res) => {
                if (res.code == 1) {
                    console.log(res, '4444444444444444444444444444444')
                    this.makeTapDe = res.data;
                    // 截取首页产业制造左侧数据
                    this.makeListFrontLeft = res.data[
                        this.industryCurrent
                    ].list.slice(0, 2);
                    // 截取首页产业制造右侧数据
                    this.makeListFrontRight = res.data[
                        this.industryCurrent
                    ].list.slice(2, 4);
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取首页行业标准数据
        normsRe() {
            norms().then((res) => {
                if (res.code == 1) {
                    this.normsTapDe = res.data;
                    // 截取0-5数据
                    this.normsList = res.data[this.normsCurrent].list.slice(
                        0,
                        5
                    );
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取首页产业观察数据
        observeRe() {
            observe().then((res) => {
                if (res.code == 1) {
                    this.observeTapDe = res.data;
                    // console.log(this.observeTapDe, "产业观察数据");
                    // 截取首页产业观察左侧数据
                    this.observeListLeft = res.data[
                        this.observeCurrent
                    ].list.slice(0, 4);
                    // 截取首页产业观察右侧数据
                    this.observeListRight = res.data[
                        this.observeCurrent
                    ].list.slice(4, 7);
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取首页食品资讯数据
        foodsRe() {
            foods().then((res) => {
                if (res.code == 1) {
                    console.log(res, 'ressssss')
                    this.foodCrumbs = res.data
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取首页企业会员数据
        companysRe() {
            companys().then((res) => {
                if (res.code == 1) {
                    this.companysTapDe1 = res.data;
                    this.itemList = res.data[this.companys1Current].list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取首页下面企业会员
        downcompanysRe() {
            downcompanys().then((res) => {
                if (res.code == 1) {
                    this.companysTapDe2 = res.data;
                    this.itemList2 = res.data[this.companys2Current].list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取数智金融数据
        makeRewe() {
            make().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.itemList1 = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取首页广告图数据
        advlistRe() {
            advlist().then((res) => {
                if (res.code == 1) {
                    this.advlistDe = res.data
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取首页政策法规
        lawsRe() {
            laws().then((res) => {
                if (res.code == 1) {
                    // console.log(res.data,'政策法规数据');
                    this.lawsDe = res.data
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 跳转到企业会员首页
        member(item) {
            sessionStorage.setItem("id", item.id);
            // 待更换
            // sessionStorage.setItem("id", 1);
            this.$router.push({
                path: "/mIndex",
            });
            window.scrollTo(0, 0);
        },
        // 赋能左侧tap点击事件
        scienceEv(index) {
            this.scienceCurrent = index;
            this.scienceTileCurrent = 0;
            this.scienceRe();
        },
        // 跳转到企业会员广告页
        advertising() {
            this.$router.push({
                path: "/about/survey",
                query: {
                    typeid: 1,
                    type: 7,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到成果转化详情页
        resultDetil(id) {
            // cole.log(id, "成果id");
            this.$router.push({
                path: `/science/demandResultDetail`,
                query: {
                    id: id,
                    type: 2,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到成果转化发布推广
        resultIssue() {
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: "/science/demandResultIssue/",
                    query: {
                        // indexa: index,
                    },
                });
                window.scrollTo(0, 0);
            }
        },
        // 跳转到产业制造详情页
        goMadeDetail(item, index) {
            // console.log(item, 12345);
            this.$router.push({
                path: `/special/specialDetail/${index}`,
                // path: `/made/madeDetail/${index}`,
                query: {
                    ids: item.id,
                    menu_id: item.menu_id,
                },
            });
            window.scrollTo(0, 0);
        },

        goMade() {
            this.$router.push({
                path: `/made/madeDetail/0`,
                query: {
                    ids: this.makeListFrontLeft[0].id,
                    menu_id: this.makeListFrontLeft[0].menu_id,
                },
            });
            window.scrollTo(0, 0);
        },
        goMades() {

            this.$router.push({
                path: `/made/madeDetail/0`,
                query: {
                    ids: this.makeListFrontRight[0].id,
                    menu_id: this.makeListFrontRight[0].menu_id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到通告详情页
        goAnnunciateDetail(id, index) {
            this.$router.push({
                path: `/about/annunciateDetail/${index}`,
                query: {
                    ids: id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 通告更多跳转
        goAnnunciateMore() {
            this.$router.push({
                path: `/about/survey`,
                query: {
                    type: 5,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到关于学会-学会概述
        goAboutSummarize() {
            this.$router.push({
                path: `/about/survey`,
                query: {
                    type: 0,
                },
            });
            window.scrollTo(0, 0);
        },

        // 跳转到助企名家详情页
        goSpecialistDetail(item) {
            sessionStorage.setItem("specialist_id", item.id);
            this.$router.push({
                path: "/science/minkaveDetails",
                query: {
                    index: 0,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到助企下级界面--详情页
        goActivityDetail(type) {
            this.$router.push({
                path: `/science/activitysub/`,
            });
            window.scrollTo(0, 0);
        },
        // 跳转到招聘详情页
        goInviteDetail(item) {
            this.$router.push({
                path: "/science/demandDetails",
                query: {
                    id: item.id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到成果转化详情页
        // goResultDetil(type) {
        //     this.$router.push({
        //         path: `/science/demandResultDetail/${type}`,
        //     });
        // },
        // 跳转行业标准页
        goNormStandard() {
            this.$router.push({
                path: "/norm/standard",
            });
            window.scrollTo(0, 0);
        },
        // 跳转到行业标准详情页
        goStandardDetail(item, type) {
            this.$router.push({
                path: `/norm/standardDetail/${type}`,
                query: {
                    ids: item.id,
                    menu_id: item.menu_id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到产业制造更多
        goMachining() {
            this.$router.push({
                path: `/made/machining`,
                query: {
                    type: 0,
                    menuid: this.makeTapDe[0].id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到食品资讯详情页
        goCousultDetail(id) {
            this.$router.push({
                path: "/consult/cousultDetail",
                query: {
                    id: id,
                    type: 1,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到产业观察详情页
        goObserveDetail(item, type) {
            this.$router.push({
                path: `/observe/fleshDetail/${type}`,
                query: {
                    ids: item.id,
                    menu_id: item.menu_id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到食品咨询
        foodDetail() {
            this.$router.push({
                path: `/consult/cousultHot`,
                query: {
                    type: 0,
                    menuid: 44,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到发布页面
        issueEv(index) {
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: `/science/DemandIssue/`,
                    query: {
                        indexa: index,
                        lable_id: "",
                    },
                });
            }
            window.scrollTo(0, 0);
        },
        // 跳转到招聘详情页
        inviteDetail(item) {
            this.$router.push({
                path: "/science/demandInviteDetail",
                query: {
                    id: item.id,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到就业发布
        obtain() {
            // 判断是否登录
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
                window.scrollTo(0, 0);
            } else {
                this.$router.push({
                    path: "/science/recruit",
                    query: {
                        indexa: 0,
                        lable_id: "",
                    },
                });
                window.scrollTo(0, 0);
            }
        },
        // 科技赋能更多跳转
        more() {
            let path = "";
            let type = "";
            if (this.scienceCurrent == 0) {
                path = "/advertising";
                type = 2;
            } else if (this.scienceCurrent == 1) {
                path = "/science/minkave";
            } else if (this.scienceCurrent == 2) {
                path = "/science/activity";
            } else if (this.scienceCurrent == 3) {
                path = "/science/demand";
            }
            this.$router.push({
                path: path,
                query: {
                    type: type,
                },
            });
            window.scrollTo(0, 0);
        },
        // 跳转到招聘发布
        bring() {
            // 判断是否登录
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: "/science/recruit",
                    query: {
                        indexa: 1,
                        lable_id: "",
                    },
                });
                window.scrollTo(0, 0);
            }
        },
        // 首页科技赋能top切换
        scienceTile(index) {
            this.scienceTileCurrent = index;
            this.scienceRe();
        },
        // 首页产业制造tap切换
        industryTapEv(index) {
            this.industryCurrent = index;
            this.makeRe();
        },
        // 首页行业标准tap切换
        normsTapEv(index) {
            this.normsCurrent = index;
            this.normsRe();
        },
        // 首页产业观察tap切换
        observeTapEv(index) {
            this.observeCurrent = index;
            this.observeRe();
        },
        // 首页企业会员1tap切换
        companys1Ev(index) {
            this.companys1Current = index;
            this.companysRe();
        },
        // 首页企业会员2tap切换
        companys2Ev(index) {
            this.companys2Current = index;
            this.downcompanysRe();
        },
        //食品咨询面包屑切换
        crumbsEv(index) {
            this.crumbsEvCurrent = index
            this.foodsList = []
            this.foodsRe()
        },
        // 跳转到学院
        goCollege(id) {
            // sessionStorage.setItem("school_id", id);
            // 待更换
            sessionStorage.setItem("school_id", id);
            this.$router.push("/academyIndex");
            window.scroll(0, 0);
        },
        collectPop() {
            this.$message.warning({
                message: "已收藏",
                duration: 1000,
                customClass: "myBox",
            });
        },
        download(url) {
            download(url);
        },
    },
    mounted() { },

};
</script>

<style lang="scss">
// .myBox {
//     min-width: 130px !important;
//     height: 25px !important;
//     padding: 3px !important;
//     text-align: center !important;
//     display: flex !important;
//     justify-content: center !important;

//     .el-message__icon {
//         font-size: 10px;
//     }

//     .el-message__content {
//         font-size: 8px;
//     }
// }

// //轮播图背景色
// .el-carousel__item {
//     background-color: #fff !important;
// }

// .index {
//     width: 100%;

//     /*科学赋能 start*/
//     .index-science {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         height: 300px;
//         margin: 20px 0;
//         background: url("../../assets/pc/Index/ce1.png") no-repeat;
//         background-size: 100% 100%;
//         // border: 1px solid red;

//         .index-science-wrap {
//             width: 70%;
//             margin: 0 auto;
//             display: flex;
//             justify-content: space-between;

//             // 科学赋能左侧
//             .index-science-left {
//                 width: 20%;
//                 height: 100%;
//                 // border: 1px solid red;

//                 // 科学赋能左侧头部
//                 .iScience-left-title {
//                     .iScience-title-text {
//                         font-size: 18px;
//                         margin-bottom: 3px;
//                         // font-weight: 700;

//                         >span {
//                             color: #0056b0;
//                         }
//                     }

//                     .iScience-title-ms {
//                         letter-spacing: 2px;
//                         font-size: 8px;
//                         color: #999999;
//                     }
//                 }

//                 // 科学赋能左侧tap栏
//                 .iScience-left-tap {

//                     // border: 1px solid red;
//                     ul {
//                         list-style: none;
//                         display: flex;
//                         flex-direction: column;
//                         // border: 1px solid red;

//                         .scienceActive {
//                             color: #fff;
//                             background-color: #0068d5;
//                         }

//                         .scienceActive::before {
//                             background-color: #fff;
//                         }

//                         li::before {
//                             // 小圆点在这里
//                             content: "";
//                             width: 3px;
//                             height: 3px;
//                             border-radius: 50%;
//                             margin-right: 5px;
//                             background-color: #333333;
//                             display: block;
//                         }

//                         li {
//                             display: flex;
//                             align-items: center;
//                             width: 100%;
//                             height: 20px;
//                             line-height: 20px;
//                             margin-top: 20px;
//                             padding-left: 10px;
//                             box-sizing: border-box;
//                             font-size: 10px;
//                             border-bottom-left-radius: 10px;
//                             border-top-right-radius: 10px;
//                             cursor: pointer;
//                             border: 1px dashed #999999;
//                         }
//                     }
//                 }
//             }

//             // 科学赋能右侧
//             .index-science-right {
//                 width: 76%;
//                 height: 240px;
//                 padding: 5px 5px 0 5px;
//                 box-sizing: border-box;
//                 box-shadow: 0 0 10px #b1adad;
//                 background-color: #fff;
//                 // border: 1px solid red;

//                 // 科学赋能右侧顶部tap
//                 .science-right-tap {
//                     display: flex;
//                     align-items: center;
//                     justify-content: flex-end;

//                     >ul {
//                         display: flex;
//                         margin-right: 10px;
//                         // border: 1px solid red;

//                         .avtiveli {
//                             color: #0056b0;
//                         }

//                         li {
//                             cursor: pointer;
//                             margin-left: 20px;
//                             font-size: 10px;
//                             list-style: none;
//                         }
//                     }

//                     >span:hover {
//                         color: #0056b0;
//                     }

//                     >span {
//                         cursor: pointer;
//                         font-size: 8px;
//                     }
//                 }

//                 // 科学赋能右侧内容区域
//                 .science-right-cont {

//                     // 科技资源布局start
//                     .ul1 {
//                         display: flex;
//                         justify-content: space-between;
//                         flex-wrap: wrap;
//                         margin-top: 10px;
//                         list-style: none;

//                         li:hover {
//                             box-shadow: 0 20px 20px -15px #cbc9c9;
//                         }

//                         li {
//                             cursor: pointer;
//                             width: 32%;
//                             height: 90px;
//                             margin-bottom: 10px;
//                             background-color: #fff;
//                             border: 1px solid #f2f2f2;

//                             .science-card1:hover div {
//                                 top: 0;
//                             }

//                             .science-card1 {
//                                 width: 100%;
//                                 height: 100%;
//                                 position: relative;
//                                 overflow: hidden;

//                                 >img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }

//                                 >div {
//                                     display: block;
//                                     width: 100%;
//                                     height: 100%;
//                                     position: absolute;
//                                     left: 0;
//                                     top: 70px;
//                                     /*使用RGBA颜色值*/
//                                     background-color: rgba(0, 0, 0, 0.3);
//                                     font-size: 8px;
//                                     color: #FFFFFF;
//                                     padding: 0;
//                                     margin: 0;
//                                     line-height: 16px;
//                                     transition: all 0.6s ease-in-out;

//                                     >b {
//                                         display: block;
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 科技资源布局end

//                     // 助企名家start
//                     .ul2 {
//                         display: flex;
//                         justify-content: space-between;
//                         flex-wrap: wrap;
//                         margin-top: 10px;
//                         padding: 0 5px;
//                         list-style: none;

//                         li:hover {
//                             box-shadow: 0 20px 20px -15px #cbc9c9;
//                         }

//                         li {
//                             width: 32%;
//                             height: 90px;
//                             padding: 10px 8px;
//                             box-sizing: border-box;
//                             margin-bottom: 10px;
//                             background-color: #fff;
//                             cursor: pointer;
//                             border: 1px solid #f2f2f2;

//                             .science-card2 {
//                                 width: 100%;
//                                 display: flex;

//                                 // 助企名家图片
//                                 .science-card2-img {
//                                     width: 60px;
//                                     height: 70px;
//                                     // height: 100%;
//                                     margin-right: 10px;
//                                     display: block;
//                                     // line-height: 0;
//                                     // border: 1px solid red;

//                                     img {
//                                         width: 100%;
//                                         height: 100%;
//                                         display: block;
//                                     }
//                                 }

//                                 // 助企名家描述
//                                 .science-card2-title {
//                                     width: 60%;
//                                     display: flex;
//                                     flex-direction: column;
//                                     // border: 1px solid red;

//                                     .card2-title1 {
//                                         font-size: 11px;
//                                     }

//                                     .card2-title2 {
//                                         margin-top: 3px;
//                                         font-size: 8px;
//                                     }

//                                     .card2-title3 {
//                                         margin-top: 3px;
//                                         font-size: 7px;
//                                         color: #999999;
//                                     }

//                                     .card2-btn {
//                                         display: flex;
//                                         justify-content: space-between;
//                                         margin-top: auto;

//                                         >div {
//                                             text-align: center;
//                                             width: 30px;
//                                             height: 10px;
//                                             line-height: 10px;
//                                             padding: 1px;
//                                             color: #fff;
//                                             font-size: 6px;
//                                             background-color: #0056b0;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 助企名家end

//                     // 助企活动start
//                     .ul3 {
//                         display: flex;
//                         justify-content: space-between;
//                         flex-wrap: wrap;
//                         margin-top: 10px;
//                         // list-style: none;

//                         li:hover {
//                             box-shadow: 0 20px 20px -15px #cbc9c9;
//                         }

//                         li {
//                             width: 32%;
//                             height: 95px;
//                             box-sizing: border-box;
//                             margin-bottom: 10px;
//                             background-color: #fff;
//                             cursor: pointer;
//                             border: 1px solid #f2f2f2;

//                             .science-card3 {
//                                 display: flex;
//                                 flex-direction: column;

//                                 // 助企活动图片
//                                 >img {
//                                     width: 100%;
//                                     height: 50px;
//                                 }

//                                 // 助企活动标题
//                                 .science-card3-title {
//                                     padding: 1px;
//                                     font-size: 7px;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 // 助企活动描述
//                                 .science-card3-ms {
//                                     padding: 1px;
//                                     font-size: 6px;
//                                     color: #999999;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 2; //行数
//                                     -webkit-box-orient: vertical;
//                                     border-bottom: 0.5px dashed #ededed;
//                                 }

//                                 // 助企活动底部样式
//                                 .science-card3-footer {
//                                     display: flex;
//                                     justify-content: space-between;
//                                     padding: 1px;

//                                     .science-card3-footer1 {
//                                         text-align: center;
//                                         width: 20px;
//                                         height: 8px;
//                                         line-height: 8px;
//                                         color: #fff;
//                                         font-size: 6px;
//                                         background-color: #333333;
//                                     }

//                                     .science-card3-footer2 {
//                                         display: flex;
//                                         align-items: center;

//                                         >img {
//                                             width: 6px;
//                                             height: 6px;
//                                         }

//                                         >div {
//                                             margin-left: 3px;
//                                             font-size: 6px;
//                                             color: #999999;
//                                         }
//                                     }

//                                     .science-card3-footer3 {
//                                         display: flex;
//                                         align-items: center;

//                                         >img {
//                                             width: 6px;
//                                             height: 7px;
//                                         }

//                                         >div {
//                                             margin-left: 3px;
//                                             font-size: 6px;
//                                             color: #999999;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 助企活动end

//                     //产品需求start
//                     .ul4 {
//                         display: flex;
//                         justify-content: space-between;
//                         flex-wrap: wrap;
//                         margin-top: 10px;
//                         list-style: none;

//                         li:hover {
//                             box-shadow: 0 20px 20px -15px #cbc9c9;
//                         }

//                         li {
//                             position: relative;
//                             width: 32%;
//                             height: 90px;
//                             box-sizing: border-box;
//                             margin-bottom: 10px;
//                             background-color: #fff;
//                             cursor: pointer;
//                             border: 1px solid #f2f2f2;

//                             // 技术难题
//                             .science-card4:nth-child(1) {
//                                 padding: 4px;
//                                 box-sizing: border-box;

//                                 >img {
//                                     position: absolute;
//                                     top: 0px;
//                                     left: 0px;
//                                     width: 20px;
//                                     height: 20px;
//                                 }

//                                 .science-card4-start {
//                                     width: 100px;
//                                     display: flex;
//                                     justify-content: space-between;

//                                     >div:nth-child(1) {
//                                         font-size: 9px;
//                                         margin-left: 30px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }
//                                 }

//                                 .science-card4-tag0 {
//                                     margin-top: 6px;
//                                     font-size: 7px;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 .science-card4-tag5 {
//                                     display: none;
//                                 }

//                                 .science-card4-tag4 {
//                                     margin-top: 3px;
//                                     font-size: 7px;

//                                     >div {
//                                         width: 100%;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         display: -webkit-box;
//                                         -webkit-line-clamp: 2; //行数
//                                         -webkit-box-orient: vertical;
//                                     }
//                                 }

//                                 .science-card4-tag1 {
//                                     display: flex;
//                                     margin-top: 3px;
//                                     font-size: 7px;

//                                     >div:nth-child(1) {
//                                         margin-right: auto;
//                                     }
//                                 }

//                                 .science-card4-tag2 {
//                                     display: flex;
//                                     align-items: center;
//                                     margin-top: 3px;
//                                     font-size: 7px;
//                                     color: #c1c1c1;

//                                     >div:nth-child(1) {
//                                         font-size: 7px;
//                                         color: #c1c1c1;
//                                     }

//                                     .science-card4-tag3 {
//                                         display: flex;
//                                         justify-content: space-around;
//                                         margin-top: 3px;
//                                         font-size: 6px;
//                                         cursor: pointer;
//                                         margin-left: auto;

//                                         >div:nth-child(1) {
//                                             display: flex;

//                                             img {
//                                                 width: 10px;
//                                                 height: 10px;
//                                                 margin-right: 5px;
//                                             }
//                                         }

//                                         >div:nth-child(2) {
//                                             display: flex;

//                                             img {
//                                                 width: 9px;
//                                                 height: 9px;
//                                                 // margin-top: 3px;
//                                                 margin-right: 5px;
//                                             }
//                                         }
//                                     }
//                                 }

//                             }

//                             // 成果转化
//                             .science-card4:nth-child(2) {
//                                 padding: 6px;
//                                 box-sizing: border-box;
//                                 font-size: 10px;

//                                 .survey-left2-title1 {
//                                     font-size: 12px;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 .survey-left2-title2 {
//                                     display: flex;
//                                     font-size: 6px;
//                                     margin-top: 6px;

//                                     >div:nth-child(1) {
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     >div:nth-child(2) {
//                                         margin-left: auto;
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }
//                                 }

//                                 .survey-left2-footer {
//                                     display: flex;
//                                     margin-top: 10px;
//                                     font-size: 6px;


//                                     >div:nth-child(1) {
//                                         display: flex;
//                                         align-items: center;
//                                         margin-right: 10px;
//                                         margin-left: auto;
//                                         cursor: pointer;
//                                         // border: 1px solid red;

//                                         >img {
//                                             width: 9px;
//                                             height: 6px;
//                                             margin-right: 2px;
//                                         }
//                                     }

//                                     >div:nth-child(2) {
//                                         display: flex;
//                                         align-items: center;
//                                         cursor: pointer;

//                                         >img {
//                                             width: 9px;
//                                             height: 6px;
//                                             margin-right: 2px;
//                                         }
//                                     }
//                                 }
//                             }

//                             // 就业招聘
//                             .science-card4:nth-child(3) {
//                                 margin-top: 4px;
//                                 padding: 6px;
//                                 box-sizing: border-box;
//                                 font-size: 10px;

//                                 .science-card4-start {
//                                     display: flex;

//                                     >div {
//                                         text-align: center;
//                                         width: 30px;
//                                         // height:5px;
//                                         font-size: 7px;
//                                         margin-right: 3px;
//                                         color: #fff;
//                                         background-color: #5daa00;
//                                     }
//                                 }

//                                 .science-card4-tag1 {
//                                     display: flex;
//                                     margin-top: 4px;
//                                     font-size: 7px;

//                                     >div:nth-child(1) {
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     >div:nth-child(2) {
//                                         margin-left: auto;
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }
//                                 }

//                                 .science-card4-tag3 {
//                                     display: flex;
//                                     align-items: center;
//                                     margin-top: 7px;
//                                     font-size: 7px;

//                                     >div:nth-child(1) {
//                                         cursor: pointer;
//                                         font-size: 6px;
//                                     }

//                                     >div:nth-child(2) {
//                                         cursor: pointer;
//                                         display: flex;
//                                         align-items: center;
//                                         margin-left: auto;
//                                         margin-right: 2px;

//                                         img {
//                                             width: 7px;
//                                             height: 7px;
//                                             margin-right: 5px;
//                                         }
//                                     }

//                                     >div:nth-child(3) {
//                                         cursor: pointer;
//                                         display: flex;
//                                         align-items: center;

//                                         img {
//                                             width: 7px;
//                                             height: 7px;
//                                             // margin-top: 3px;
//                                             margin-right: 5px;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     //产品需求end
//                 }
//             }
//         }
//     }

//     /*科学赋能 end*/

//     /*产业观察 start*/
//     .index-observe {
//         width: 100%;
//         height: 310px;
//         background: url("../../assets/pc/Index/science3.png") no-repeat;
//         background-size: 100% 100%;
//         // border: 1px solid red;

//         .index-observe-wrap {
//             width: 70%;
//             height: 100%;
//             margin: 0 auto;
//             box-sizing: border-box;

//             // 产业观察头部tap切换
//             .index-observe-title {
//                 width: 100%;
//                 height: 13%;
//                 display: flex;
//                 padding: 10px 0;
//                 color: #ffffff;
//                 box-sizing: border-box;
//                 // border: 1px solid red;

//                 >div:nth-child(1) {
//                     width: 20%;
//                     font-size: 13px;
//                     margin-right: auto;
//                     margin-left: 10px;
//                 }

//                 >ul {
//                     height: 100%;
//                     list-style: none;
//                     display: flex;

//                     li {
//                         display: flex;
//                         justify-content: center;
//                         width: 60px;
//                         font-size: 10px;
//                         // margin-left: 10px;
//                         cursor: pointer;
//                         // border: 1px solid red;

//                         .observeActive {
//                             border-bottom: 1px solid #fff;
//                             // border: 1px solid red;
//                         }

//                         >div:nth-child(1) {
//                             margin-right: 10px;
//                             // border: 1px solid red;
//                         }

//                         >div:nth-child(2) {
//                             height: 70%;
//                             width: 1px;
//                             // margin-left: 10px;
//                             background-color: #fff;
//                         }
//                     }

//                     li:last-child {
//                         >div:nth-child(2) {
//                             display: none;
//                         }
//                     }
//                 }
//             }

//             // 产业观察内容区
//             .index-observe-cont {
//                 width: 100%;
//                 height: 87%;
//                 display: flex;
//                 justify-content: space-between;
//                 padding: 6px 15px;
//                 box-sizing: border-box;
//                 background-color: #ffffff;

//                 // 产业观察内容区左侧
//                 .index-observe-left {
//                     width: 48%;

//                     >ul {
//                         list-style: none;

//                         li {
//                             position: relative;
//                             padding: 6px 0;
//                             margin-top: 4px;
//                             cursor: pointer;
//                             border-bottom: 1px solid #dcdcdc;

//                             // border: 1px solid red;
//                             >div:nth-child(1) {
//                                 position: absolute;
//                                 top: 10px;
//                                 left: -10px;
//                             }

//                             >div:nth-child(2) {
//                                 font-size: 11px;
//                                 margin-bottom: 7px;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }

//                             >div:nth-child(3) {
//                                 font-size: 8px;
//                                 color: #666666;
//                                 margin-bottom: 3px;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                                 // border: 1px solid red;
//                             }

//                             >div:nth-child(4) {
//                                 font-size: 8px;
//                                 color: #666666;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }
//                         }
//                     }
//                 }

//                 // 产业观察内容区右侧
//                 .index-observe-right {
//                     width: 48%;

//                     .index-box-item {
//                         cursor: pointer;
//                         height: 30%;
//                         margin: 5px 0;
//                         // border: 1px solid red;

//                         // > div:nth-child(1) {
//                         //     font-size: 11px;
//                         // }

//                         .index-right-box {
//                             display: flex;

//                             >img {
//                                 width: 100px;
//                                 height: 100%;
//                                 // border: 1px solid red;
//                             }

//                             >div {
//                                 height: 100%;
//                                 font-size: 8px;
//                                 margin-left: 10px;
//                                 margin-top: 6px;
//                                 color: #333333;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                                 display: -webkit-box;
//                                 -webkit-line-clamp: 6; //行数
//                                 -webkit-box-orient: vertical;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     /*产业观察 end*/

//     .index-wrap {
//         width: 70%;
//         margin: 0 auto;
//         // height: 30px;
//         // border: 1px solid red;

//         /*通告 start*/
//         .index-annunciate {
//             width: 100%;
//             height: 185px;
//             display: flex;
//             justify-content: space-between;
//             margin-top: 13px;
//             // border: 1px solid red;

//             // 左侧
//             .index-annunciate-left {
//                 width: 62%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧
//             .index-annunciate-right {
//                 width: 35%;
//                 height: 100%;
//                 // border: 1px solid blue;

//                 // 头部
//                 .index-right-hed {
//                     display: flex;
//                     align-items: center;
//                     justify-content: space-between;
//                     height: 13%;
//                     padding: 0 5px 3px 10px;
//                     background: url("../../assets/pc/Index/annunciate1.png") no-repeat;
//                     background-size: 100% 100%;
//                     cursor: pointer;

//                     .index-text {
//                         font-size: 10px;
//                         color: #fff;
//                     }

//                     .index-text1 {
//                         font-size: 10px;
//                         color: #fff;
//                     }
//                 }

//                 // 列表
//                 .index-right-list {
//                     // margin-top: 10px;

//                     >ul {
//                         list-style: none;

//                         li:hover {
//                             color: #0056b0;
//                         }

//                         li {
//                             display: flex;
//                             align-items: center;
//                             height: 30%;
//                             padding: 3.3% 0;
//                             cursor: pointer;
//                             box-sizing: border-box;
//                             border-bottom: 0.5px solid #cccccc;
//                             // border: 1px solid red;

//                             .index-list-data {
//                                 padding: 2px 3px;
//                                 box-sizing: border-box;
//                                 height: 25px;
//                                 width: 30px;
//                                 margin-right: 10px;
//                                 font-size: 7px;
//                                 color: #1681f3;
//                                 border-radius: 3px;
//                                 background-color: #b5d9f9;
//                                 // border: 1px solid red;

//                                 span {
//                                     display: block;
//                                 }
//                             }

//                             .index-list-text {
//                                 font-size: 9px;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                                 display: -webkit-box;
//                                 -webkit-line-clamp: 2; //行数
//                                 -webkit-box-orient: vertical;
//                             }
//                         }

//                         >li:last-child {
//                             border: none;
//                         }
//                     }
//                 }
//             }
//         }

//         /*通告 end*/

//         /*产业智造 start*/
//         .index-industry {
//             width: 100%;
//             height: 270px;
//             display: flex;
//             justify-content: space-between;
//             margin-top: 30px;
//             // border: 1px solid black;

//             // 产品制造左侧
//             .index-industry-left {
//                 width: 68%;
//                 height: 100%;
//                 cursor: pointer;
//                 // border: 1px solid red;

//                 // 头部
//                 .index-induleft {
//                     width: 100%;
//                     height: 21px;
//                     display: flex;
//                     border-bottom: 1.5px solid #0056b0;

//                     .index-indutitle {
//                         width: 70px;
//                         height: 100%;
//                         font-size: 12px;
//                         color: #fff;
//                         padding: 3px 0 0 7px;
//                         box-sizing: border-box;
//                         background: url("../../assets/pc/Index/industry.png") no-repeat;
//                         background-size: 100% 100%;
//                         // border: 1px solid red;
//                     }

//                     .index-induright {
//                         width: 78%;
//                         margin-left: auto;
//                         margin-top: 4px;
//                         // border: 1px solid red;

//                         ul {
//                             width: 100%;
//                             list-style: none;
//                             display: flex;
//                             justify-content: flex-end;
//                             // border: 1px solid red;

//                             li {
//                                 text-align: center;
//                                 width: 50px;
//                                 font-size: 10px;
//                                 margin-left: 3px;
//                             }

//                             .industryActive {
//                                 color: #0056b0;
//                             }

//                             >span {
//                                 display: block;
//                                 text-align: end;
//                                 width: 40px;
//                                 font-size: 10px;
//                                 color: #1681f3;
//                             }
//                         }
//                     }
//                 }

//                 // 内容区
//                 .index-industry-body {
//                     width: 100%;
//                     display: flex;
//                     justify-content: space-between;
//                     margin-top: 10px;

//                     // 列表(左右列表共用样式)
//                     .index-industryB-list {
//                         width: 48%;
//                         height: 105px;
//                         cursor: pointer;
//                         ;

//                         >div {
//                             width: 100%;
//                             height: 100%;

//                             img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }

//                         ul {
//                             list-style: none;
//                             margin-top: 7px;

//                             li {
//                                 list-style: none;
//                                 border-bottom: 0.7px solid #dcdcdc;

//                                 .index-list-title {
//                                     font-size: 10px;
//                                     font-weight: 700;
//                                     padding: 5px 0;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 .index-list-text {
//                                     // padding: 3px;
//                                     font-size: 8px;
//                                     line-height: 12px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 >.index-list-text:nth-child(3) {
//                                     padding-bottom: 5px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             // 产品智造右侧
//             .index-industry-right {
//                 width: 30%;
//                 height: 100%;
//                 // border: 1px solid red;

//                 // 产业制造右侧头部
//                 .index-indright {
//                     display: flex;
//                     align-items: center;
//                     box-sizing: border-box;
//                     justify-content: space-between;
//                     border-bottom: 1.5px solid #dcdcdc;

//                     .index-indright-L {
//                         box-sizing: border-box;
//                         margin-bottom: -1.5px;
//                         padding-bottom: 4.8px;
//                         border-bottom: 1.5px solid #0056b0;
//                         font-size: 12px;
//                         font-weight: 700;
//                         color: #0056b0;
//                     }

//                     .index-indright-R {
//                         font-size: 8px;
//                         color: #0056b0;
//                     }
//                 }

//                 // 产业制造右侧文字详情
//                 .index-indright-text {
//                     margin-top: 10px;
//                     font-size: 8px;
//                     cursor: pointer;

//                     // border: 1px solid red;
//                     >div {
//                         color: #666666;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         display: -webkit-box;
//                         -webkit-line-clamp: 2; //行数
//                         -webkit-box-orient: vertical;
//                     }

//                     >span {
//                         color: #1f78d9;
//                     }
//                 }

//                 // 入会申请、培训报名……
//                 .index-indright-list {
//                     margin-top: 10px;

//                     ul {
//                         list-style: none;
//                         display: flex;
//                         flex-wrap: wrap;
//                         justify-content: space-between;

//                         li {
//                             width: 46%;
//                             height: 23px;
//                             line-height: 23px;
//                             margin-bottom: 10px;
//                             background-color: #0068d5;

//                             >div {
//                                 display: flex;
//                                 align-items: center;
//                                 justify-content: center;
//                                 // border: 1px solid red;

//                                 >div {
//                                     width: 10px;
//                                     height: 10px;
//                                     text-align: center;
//                                     line-height: 0;
//                                     margin-top: -5px;
//                                     margin-right: 5px;
//                                     // border: 1px solid red;

//                                     img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }
//                                 }

//                                 >span {
//                                     font-size: 8px;
//                                     color: #fff;
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 产业制造右侧图片
//                 .index-indright-img {
//                     width: 100%;
//                     height: 110px;
//                     border: 1px solid blanchedalmond;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//         }

//         /*产业制造 end*/

//         /*企业会员 start*/
//         .member {
//             width: 100%;
//             margin: 30px 0;
//             // border: 1px solid red;

//             // 企业会员头部
//             .index-member {
//                 width: 100%;
//                 display: flex;
//                 border-bottom: 1px solid #0056b0;

//                 .index-member-title {
//                     width: 70px;
//                     height: 23px;
//                     font-size: 12px;
//                     color: #fff;
//                     padding: 4px 0 0 7px;
//                     box-sizing: border-box;
//                     background: url("../../assets/pc/Index/industry.png") no-repeat;
//                     background-size: 100% 100%;
//                 }

//                 .index-memberright {
//                     display: flex;
//                     width: 86%;
//                     margin-left: auto;

//                     // border: 1px solid red;
//                     ul {
//                         width: 70%;
//                         list-style: none;
//                         display: flex;
//                         justify-content: flex-end;
//                         margin-top: 5px;
//                         // border: 1px solid red;

//                         .companys1Active {
//                             color: #0068d5;
//                         }

//                         li {
//                             cursor: pointer;
//                             text-align: center;
//                             width: 55px;
//                             font-size: 10px;
//                             margin-left: 4px;
//                             // border: 1px solid red;
//                         }

//                         li:last-child {
//                             width: 70px;
//                         }
//                     }

//                     >span {
//                         width: 8%;
//                         text-align: end;
//                         margin-left: 10px;
//                         margin-top: 3.5px;
//                         cursor: pointer;
//                         font-size: 10px;
//                         color: #1681f3;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*会员 start*/
//             .attract {
//                 // margin-top: 100px;
//                 width: 100%;
//                 // height: 250px;
//                 background-size: cover;
//                 // border:1px solid red;

//                 >ul {
//                     display: flex;
//                     flex-wrap: wrap;
//                     list-style: none;

//                     >li {
//                         width: 15%;
//                         height: 110px;
//                         margin-top: 13px;
//                         margin-right: 1.489999%;
//                         // border:1px solid blue;

//                         >img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }

//                     >li:nth-child(6) {
//                         margin-right: 0px;
//                     }
//                 }
//             }

//             /*会员 end*/
//         }

//         /*企业会员 end*/

//         /*行业标准/政策法规 start*/
//         .index-hye {
//             width: 100%;
//             display: flex;
//             justify-content: space-between;

//             // 行业标准顶部
//             .index-hye-left {
//                 width: 49%;

//                 // 行业标准头部
//                 .index-hye-title {
//                     display: flex;
//                     border-bottom: 1px solid #0056b0;

//                     .index-hyetitle {
//                         width: 70px;
//                         height: 23px;
//                         font-size: 12px;
//                         color: #fff;
//                         padding: 5px 0 0 7px;
//                         box-sizing: border-box;
//                         background: url("../../assets/pc/Index/industry.png") no-repeat;
//                         background-size: 100% 100%;
//                     }

//                     // 行业标准头部tap
//                     .index-hyeright {
//                         width: 85%;
//                         display: flex;
//                         align-items: center;
//                         justify-content: flex-end;
//                         margin-left: auto;

//                         ul {
//                             list-style: none;
//                             display: flex;
//                             justify-content: flex-end;
//                             // border: 1px solid blue;

//                             .normsActive {
//                                 color: #0056b0;
//                             }

//                             li {
//                                 font-size: 10px;
//                                 margin-left: 30px;
//                                 cursor: pointer;
//                                 // border: 1px solid red;
//                             }
//                         }

//                         >span {
//                             margin-left: 15px;
//                             font-size: 10px;
//                             cursor: pointer;
//                             color: #0056b0;
//                         }
//                     }
//                 }

//                 // 行业标准内容区
//                 .index-hye-body {
//                     margin-top: 15px;
//                     margin-bottom: 10px;

//                     >ul {
//                         list-style: none;

//                         li {
//                             display: flex;
//                             align-items: center;
//                             margin-top: 8px;
//                             padding-bottom: 5px;
//                             border-bottom: 0.5px solid #dedede;
//                             // border: 1px solid red;

//                             // 行业标准列表左侧内容
//                             .index-list-left {
//                                 width: 100%;
//                                 height: 100%;
//                                 // border: 1px solid orange;

//                                 .index-list-top {
//                                     width: 100%;
//                                     display: flex;
//                                     align-items: center;

//                                     .index-list-top1 {
//                                         width: 50px;
//                                         height: 15px;
//                                         text-align: center;
//                                         line-height: 15px;
//                                         margin-right: 5px;
//                                         color: #fff;
//                                         font-size: 9px;
//                                         background-color: #5daa00;
//                                     }

//                                     .index-list-top2 {
//                                         width: 35%;
//                                         font-size: 9px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     // 行业标准列表右侧内容
//                                     .index-list-right {
//                                         cursor: pointer;
//                                         display: flex;
//                                         margin-left: auto;
//                                         width: 100px;
//                                         height: 100%;
//                                         color: #666666;
//                                         // border: 1px solid orange;

//                                         .down {
//                                             width: 40px;
//                                             height: 15px;
//                                             text-align: center;
//                                             line-height: 15px;
//                                             font-size: 8px;
//                                             margin-right: 5px;
//                                             background-color: #fff;
//                                             border: 1px solid #dedede;

//                                             >span {
//                                                 font-size: 8px;
//                                             }
//                                         }

//                                         .check {
//                                             cursor: pointer;
//                                             width: 40px;
//                                             height: 15px;
//                                             display: flex;
//                                             align-items: center;
//                                             justify-content: center;
//                                             margin-right: 5px;
//                                             background-color: #fff;
//                                             border: 1px solid #dedede;

//                                             >img {
//                                                 width: 7px;
//                                                 height: 7px;
//                                                 margin-right: 5px;
//                                                 display: block;
//                                             }

//                                             >span {
//                                                 font-size: 8px;
//                                             }
//                                         }

//                                         .collect {
//                                             cursor: pointer;
//                                             margin-left: auto;
//                                             width: 40px;
//                                             height: 15px;
//                                             text-align: center;
//                                             line-height: 15px;
//                                             font-size: 8px;
//                                             background-color: #fff;
//                                             border: 1px solid #dedede;

//                                             >span {
//                                                 font-size: 8px;
//                                             }
//                                         }
//                                     }
//                                 }

//                                 .index-list-bottom {
//                                     font-size: 9px;
//                                     margin-top: 10px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             /*行业标准 end*/

//             /*政策法规 start*/
//             .index-policy-left {
//                 width: 49%;

//                 // 政策法规头部
//                 .index-policy-title {
//                     display: flex;
//                     border-bottom: 1px solid #0056b0;

//                     .index-policytitle {
//                         width: 70px;
//                         height: 23px;
//                         font-size: 12px;
//                         color: #fff;
//                         padding: 5px 0 0 7px;
//                         box-sizing: border-box;
//                         background: url("../../assets/pc/Index/industry.png") no-repeat;
//                         background-size: 100% 100%;
//                     }

//                     // 政策法规头部tap
//                     .index-policyright {
//                         width: 85%;
//                         display: flex;
//                         align-items: center;
//                         justify-content: flex-end;
//                         margin-left: auto;

//                         ul {
//                             list-style: none;
//                             display: flex;
//                             justify-content: flex-end;
//                             // border: 1px solid blue;

//                             .normsActive {
//                                 color: #0056b0;
//                             }

//                             li {
//                                 font-size: 10px;
//                                 margin-left: 30px;
//                                 cursor: pointer;
//                                 // border: 1px solid red;
//                             }
//                         }

//                         >span {
//                             margin-left: 15px;
//                             font-size: 10px;
//                             cursor: pointer;
//                             color: #0056b0;
//                         }
//                     }
//                 }

//                 //政策法规内容区
//                 .index-policy-body {
//                     >ul {
//                         list-style: none;
//                         margin-top: 5px !important;
//                         // border:1px solid red;

//                         >li {
//                             display: flex;
//                             align-items: center;
//                             padding: 6.5px 0;
//                             border-bottom: 0.5px solid #dedede;

//                             >div:nth-child(1) {
//                                 width: 55px;
//                                 display: flex;
//                                 align-items: center;

//                                 >img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             >div:nth-child(2) {
//                                 display: flex;
//                                 flex-direction: column;
//                                 margin-left: 5px;
//                                 width: 80%;
//                                 font-size: 10px;

//                                 >div:nth-child(1) {
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 >div:nth-child(2) {
//                                     margin-top: auto;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 2; //行数
//                                     -webkit-box-orient: vertical;

//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             /*政策法规 end*/
//         }

//         /*数智金融 start*/
//         .index-digifax {
//             width: 100%;
//             height: 150px;
//             margin: 0 auto;
//             margin-top: 20px;
//             // border: 1px solid red;

//             // 横向滚动
//             .attract1 {
//                 display: flex;
//                 width: 100%;

//                 .attract1-title {
//                     display: flex;
//                     text-align: center;
//                     flex-direction: column;
//                     width: 25px;
//                     margin: 10px 0;
//                     padding: 10px 4px 10px 0;
//                     margin-right: 5px;
//                     background-color: #0056b0;

//                     >div:nth-child(1) {
//                         width: 100%;
//                         text-align: center;
//                         writing-mode: vertical-rl;
//                         font-size: 15px;
//                         color: #fff;
//                     }

//                     >div:nth-child(2) {
//                         width: 100%;
//                         text-align: center;
//                         margin-top: auto;
//                         margin-left: 2px;
//                         font-size: 8px;
//                         color: #fff;
//                     }
//                 }

//                 .seamless-warp2 {
//                     overflow: hidden;
//                     width: 94%;

//                     ul.item {
//                         list-style: none;
//                         display: flex;
//                         width: 100%;
//                         height: 100%;

//                         li {
//                             width: 130px;
//                             margin: 10px;
//                             margin-right: 10px;
//                             font-size: 10px;
//                             box-shadow: 2px 2px 10px #e1e0e0;

//                             .index-digifax-img {
//                                 width: 100%;
//                                 height: 90px;

//                                 img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             .index-digifax-content {
//                                 display: flex;
//                                 align-items: center;
//                                 padding: 0 10px;
//                                 margin-top: 10px;
//                                 margin-bottom: 10px;
//                                 // border: 1px solid red;

//                                 .index-content-ms {
//                                     font-size: 8px;
//                                     line-height: 15px;
//                                     font-weight: 600;

//                                     // border: 1px solid red;
//                                     >span {
//                                         display: block;
//                                     }
//                                 }

//                                 .index-content-icon:hover {
//                                     color: #1681f3;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             // 横向滚动
//         }

//         /*数智金融 end*/

//         /*食品咨询 start*/
//         .index-food {
//             width: 100%;
//             display: flex;
//             // justify-content: space-between;
//             margin-top: 50px;
//             // height: 160px;
//             // border:1px solid red;

//             // 食品咨询item
//             .index-industry-left {
//                 width: 100%;

//                 // 头部
//                 .index-induleft {
//                     display: flex;
//                     border-bottom: 1px solid #0056b0;

//                     .index-indutitle {
//                         width: 70px;
//                         height: 23px;
//                         font-size: 12px;
//                         color: #fff;
//                         padding: 4px 0 0 7px;
//                         box-sizing: border-box;
//                         background: url("../../assets/pc/Index/industry.png") no-repeat;
//                         background-size: 100% 100%;
//                     }

//                     .index-memberright {
//                         display: flex;
//                         width: 74%;
//                         margin-left: auto;

//                         // border: 1px solid red;
//                         ul {
//                             width: 90%;
//                             list-style: none;
//                             display: flex;
//                             justify-content: flex-end;
//                             margin-top: 5px;


//                             .companys1Active {
//                                 color: #0068d5;
//                             }

//                             li {
//                                 cursor: pointer;
//                                 text-align: center;
//                                 width: 55px;
//                                 font-size: 10px;
//                                 margin-left: 4px;

//                             }

//                             li:last-child {
//                                 width: 70px;
//                             }
//                         }

//                         >span {
//                             width: 8%;
//                             text-align: end;
//                             margin-left: 10px;
//                             margin-top: 3.5px;
//                             cursor: pointer;
//                             font-size: 10px;
//                             color: #1681f3;
//                             // border: 1px solid red;
//                         }
//                     }
//                 }

//                 // 内容区
//                 .index-industry-body {
//                     display: flex !important;
//                     // justify-content: space-between;
//                     margin-top: 20px;
//                     flex-wrap: wrap !important;
//                     // border:1px solid red;


//                     // 列表(左右列表共用样式)

//                     .index-industryB-list {
//                         width: 32%;
//                         height: 100%;
//                         cursor: pointer;


//                         >div {
//                             width: 100%;
//                             height: 90px;
//                             overflow: hidden;

//                             img {
//                                 width: 97%;
//                                 height: 100%;
//                             }
//                         }

//                         >ul {
//                             list-style: none;

//                             li:first-child {
//                                 margin-left: 0;
//                             }

//                             li {
//                                 list-style: none;
//                                 cursor: pointer;
//                                 height: 70px;
//                                 margin-right: 10px;
//                                 border-bottom: 0.5px solid #dcdcdc;

//                                 .index-list-title {
//                                     >span {
//                                         display: inline-block;
//                                         width: 7px;
//                                         height: 7px;
//                                         background-color: #0056b0;
//                                         border-radius: 50%;
//                                     }

//                                     font-size: 12px;
//                                     padding: 5px 0;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 .index-list-text:nth-child(2) {
//                                     padding: 3px 0;
//                                     font-size: 8px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     // border: 1px solid red;
//                                 }

//                                 .index-list-text:nth-child(3) {
//                                     // padding: 3px;
//                                     font-size: 8px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     padding-bottom: 10px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         /*食品咨询 end*/

//         /*会员3 start*/
//         .index-member3 {
//             display: flex;
//             justify-content: space-between;
//             width: 100%;
//             margin: 30px 0;
//             list-style: none;

//             >li {
//                 width: 13.5%;
//                 height: 40px;

//                 >img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//         }

//         /*会员3 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {

//     //轮播图背景色
//     .el-carousel__item {
//         background-color: #fff !important;
//     }

//     .index {
//         width: 100%;

//         /*科学赋能 start*/
//         .index-science {
//             width: 100%;
//             display: flex;
//             align-items: center;
//             height: 300px;
//             margin: 20px 0;
//             background: url("../../assets/pc/Index/ce1.png") no-repeat;
//             background-size: 100% 100%;
//             // border: 1px solid red;

//             .index-science-wrap {
//                 width: 70%;
//                 height: 240px;
//                 margin: 0 auto;
//                 display: flex;
//                 justify-content: space-between;

//                 // 科学赋能左侧
//                 .index-science-left {
//                     width: 20%;
//                     height: 100%;
//                     // border: 1px solid red;

//                     // 科学赋能左侧头部
//                     .iScience-left-title {
//                         .iScience-title-text {
//                             font-size: 18px;
//                             margin-bottom: 3px;
//                             // font-weight: 700;

//                             >span {
//                                 color: #0056b0;
//                             }
//                         }

//                         .iScience-title-ms {
//                             letter-spacing: 2px;
//                             font-size: 8px;
//                             color: #999999;
//                         }
//                     }

//                     // 科学赋能左侧tap栏
//                     .iScience-left-tap {

//                         // border: 1px solid red;
//                         ul {
//                             list-style: none;
//                             display: flex;
//                             flex-direction: column;
//                             // border: 1px solid red;

//                             .scienceActive {
//                                 color: #fff;
//                                 background-color: #0068d5;
//                             }

//                             .scienceActive::before {
//                                 background-color: #fff;
//                             }

//                             li::before {
//                                 // 小圆点在这里
//                                 content: "";
//                                 width: 3px;
//                                 height: 3px;
//                                 border-radius: 50%;
//                                 margin-right: 5px;
//                                 background-color: #333333;
//                                 display: block;
//                             }

//                             li {
//                                 display: flex;
//                                 align-items: center;
//                                 width: 100%;
//                                 height: 20px;
//                                 line-height: 20px;
//                                 margin-top: 20px;
//                                 padding-left: 10px;
//                                 box-sizing: border-box;
//                                 font-size: 10px;
//                                 border-bottom-left-radius: 10px;
//                                 border-top-right-radius: 10px;
//                                 cursor: pointer;
//                                 border: 1px dashed #999999;
//                             }
//                         }
//                     }
//                 }

//                 // 科学赋能右侧
//                 .index-science-right {
//                     width: 76%;
//                     height: 100%;
//                     padding: 5px 5px 0 5px;
//                     box-sizing: border-box;
//                     box-shadow: 0 0 10px #b1adad;
//                     background-color: #fff;
//                     // border: 1px solid red;

//                     // 科学赋能右侧顶部tap
//                     .science-right-tap {
//                         display: flex;
//                         align-items: center;
//                         justify-content: flex-end;

//                         >ul {
//                             display: flex;
//                             margin-right: 10px;
//                             // border: 1px solid red;

//                             .avtiveli {
//                                 color: #0056b0;
//                             }

//                             li {
//                                 margin-left: 20px;
//                                 font-size: 10px;
//                                 list-style: none;
//                             }
//                         }

//                         >span {
//                             font-size: 8px;
//                         }
//                     }

//                     // 科学赋能右侧内容区域
//                     .science-right-cont {

//                         // 科技资源布局start
//                         .ul1 {
//                             display: flex;
//                             justify-content: space-between;
//                             flex-wrap: wrap;
//                             margin-top: 10px;
//                             list-style: none;

//                             li:hover {
//                                 box-shadow: 0 20px 20px -15px #cbc9c9;
//                             }

//                             li {
//                                 cursor: pointer;
//                                 width: 32%;
//                                 height: 90px;
//                                 margin-bottom: 10px;
//                                 background-color: #fff;

//                                 border: 1px solid #f2f2f2;

//                                 .science-card1 {
//                                     width: 100%;
//                                     // display: flex;
//                                     // padding: 10px;
//                                     // box-sizing: border-box;
//                                     border-bottom: 1px solid #f2f2f2;
//                                     // border: 1px solid red;

//                                     >img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }

//                                     // .science-card-img {
//                                     //     width: 40px;
//                                     //     height: 40px;
//                                     //     margin-right: 10px;

//                                     //     img {
//                                     //         width: 100%;
//                                     //         height: 100%;
//                                     //     }
//                                     // }

//                                     // .science-card-text {
//                                     //     width: 50%;
//                                     //     display: flex;
//                                     //     flex-direction: column;
//                                     //     .science-card-title {
//                                     //         font-size: 8px;
//                                     //         margin-bottom: auto;
//                                     //         // border: 1px solid red;
//                                     //     }
//                                     //     .science-card-phone {
//                                     //         font-size: 6px;
//                                     //         color: #999999;
//                                     //         // border: 1px solid red;
//                                     //     }
//                                     // }
//                                 }

//                                 // .science-card-details {
//                                 //     display: flex;
//                                 //     margin-top: 10px;
//                                 //     font-size: 8px;
//                                 //     justify-content: center;
//                                 //     // border: 1px solid red;
//                                 // }
//                             }
//                         }

//                         // 科技资源布局end

//                         // 助企名家start
//                         .ul2 {
//                             display: flex;
//                             justify-content: space-between;
//                             flex-wrap: wrap;
//                             margin-top: 10px;
//                             padding: 0 5px;
//                             list-style: none;

//                             li:hover {
//                                 box-shadow: 0 20px 20px -15px #cbc9c9;
//                             }

//                             li {
//                                 width: 32%;
//                                 height: 70px;
//                                 padding: 10px 2px;
//                                 box-sizing: border-box;
//                                 margin-bottom: 20px;
//                                 background-color: #fff;
//                                 cursor: pointer;
//                                 border: 1px solid #f2f2f2;
//                                 // border: 1px solid red;

//                                 .science-card2 {
//                                     width: 100%;
//                                     display: flex;

//                                     // 助企名家图片
//                                     .science-card2-img {
//                                         width: 80px;
//                                         height: 50px;
//                                         // height: 100%;
//                                         margin-right: 5px;
//                                         display: block;
//                                         // line-height: 0;
//                                         // border: 1px solid red;

//                                         img {
//                                             width: 100%;
//                                             height: 100%;
//                                             display: block;
//                                         }
//                                     }

//                                     // 助企名家描述
//                                     .science-card2-title {
//                                         width: 60%;
//                                         display: flex;
//                                         flex-direction: column;
//                                         // border: 1px solid red;

//                                         .card2-title1 {
//                                             font-size: 11px;
//                                         }

//                                         .card2-title2 {
//                                             margin-top: 3px;
//                                             font-size: 8px;
//                                         }

//                                         .card2-title3 {
//                                             margin-top: 3px;
//                                             font-size: 7px;
//                                             color: #999999;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }

//                                         .card2-btn {
//                                             display: flex;
//                                             justify-content: space-between;
//                                             margin-top: auto;

//                                             >div {
//                                                 text-align: center;
//                                                 width: 30px;
//                                                 height: 10px;
//                                                 line-height: 10px;
//                                                 padding: 1px;
//                                                 color: #fff;
//                                                 font-size: 6px;
//                                                 background-color: #0056b0;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 助企名家end

//                         // 助企活动start
//                         .ul3 {
//                             display: flex;
//                             justify-content: space-between;
//                             align-content: center;
//                             flex-wrap: wrap;
//                             margin-top: 10px;
//                             list-style: none;

//                             li:hover {
//                                 box-shadow: 0 20px 20px -15px #cbc9c9;
//                             }

//                             li {
//                                 width: 32%;
//                                 height: 100px;
//                                 box-sizing: border-box;
//                                 margin-bottom: 10px;
//                                 background-color: #fff;
//                                 // cursor: pointer;
//                                 border: 1px solid #f2f2f2;

//                                 .science-card3 {
//                                     display: flex;
//                                     flex-direction: column;

//                                     // 助企活动图片
//                                     >img {
//                                         width: 100%;
//                                         height: 50px;
//                                     }

//                                     // 助企活动标题
//                                     .science-card3-title {
//                                         padding: 1px;
//                                         font-size: 7px;
//                                     }

//                                     // 助企活动描述
//                                     .science-card3-ms {
//                                         padding: 1px;
//                                         font-size: 6px;
//                                         color: #999999;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         display: -webkit-box;
//                                         -webkit-line-clamp: 2; //行数
//                                         -webkit-box-orient: vertical;
//                                         border-bottom: 0.5px dashed #ededed;
//                                     }

//                                     // 助企活动底部样式
//                                     .science-card3-footer {
//                                         display: flex;
//                                         justify-content: space-between;
//                                         padding: 1px;

//                                         .science-card3-footer1 {
//                                             text-align: center;
//                                             width: 20px;
//                                             height: 8px;
//                                             line-height: 8px;
//                                             color: #fff;
//                                             font-size: 6px;
//                                             background-color: #333333;
//                                         }

//                                         .science-card3-footer2 {
//                                             display: none;
//                                             align-items: center;

//                                             >img {
//                                                 width: 6px;
//                                                 height: 6px;
//                                             }

//                                             >div {
//                                                 margin-left: 3px;
//                                                 font-size: 6px;
//                                                 color: #999999;
//                                             }
//                                         }

//                                         .science-card3-footer3 {
//                                             display: flex;
//                                             align-items: center;

//                                             >img {
//                                                 width: 6px;
//                                                 height: 7px;
//                                             }

//                                             >div {
//                                                 margin-left: 3px;
//                                                 font-size: 6px;
//                                                 color: #999999;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 助企活动end

//                         //产品需求start
//                         .ul4 {
//                             display: flex;
//                             justify-content: space-between;
//                             flex-wrap: wrap;
//                             margin-top: 10px;
//                             list-style: none;

//                             li:hover {
//                                 box-shadow: 0 20px 20px -15px #cbc9c9;
//                             }

//                             li {
//                                 position: relative;
//                                 width: 32%;
//                                 height: 90px;
//                                 box-sizing: border-box;
//                                 margin-bottom: 10px;
//                                 background-color: #fff;
//                                 // box-shadow: 0 20px 20px -15px #cbc9c9;
//                                 cursor: pointer;
//                                 border: 1px solid #f2f2f2;

//                                 // 技术难题
//                                 .science-card4:nth-child(1) {
//                                     padding: 4px;
//                                     box-sizing: border-box;

//                                     >img {
//                                         position: absolute;
//                                         top: 0px;
//                                         left: 0px;
//                                         width: 20px;
//                                         height: 20px;
//                                     }

//                                     .science-card4-start {
//                                         display: flex;
//                                         justify-content: space-between;

//                                         >div:nth-child(1) {
//                                             font-size: 9px;
//                                             margin-left: 30px;
//                                         }

//                                     }

//                                     .science-card4-tag0 {
//                                         margin-top: 3px;
//                                         font-size: 7px;
//                                     }

//                                     .science-card4-tag4 {
//                                         width: 100%;
//                                         margin-top: 3px;
//                                         font-size: 7px;

//                                         >div {
//                                             width: 100%;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                             display: -webkit-box;
//                                             -webkit-line-clamp: 1; //行数
//                                             -webkit-box-orient: vertical;
//                                         }
//                                     }

//                                     .science-card4-tag1 {
//                                         display: flex;
//                                         margin-top: 3px;
//                                         font-size: 7px;

//                                         >div:nth-child(1) {
//                                             margin-right: auto;
//                                         }

//                                         >div:nth-child(2) {
//                                             display: none;
//                                         }
//                                     }

//                                     .science-card4-tag5 {
//                                         display: block;
//                                         margin-top: 3px;
//                                         font-size: 7px;
//                                     }

//                                     .science-card4-tag2 {
//                                         display: flex;
//                                         align-items: center;
//                                         margin-top: 3px;
//                                         font-size: 7px;
//                                         color: #c1c1c1;

//                                         >div:nth-child(1) {
//                                             font-size: 7px;
//                                             color: #c1c1c1;
//                                         }

//                                         .science-card4-tag3 {
//                                             display: flex;
//                                             justify-content: space-around;
//                                             margin-top: 3px;
//                                             font-size: 6px;
//                                             cursor: pointer;
//                                             margin-left: auto;

//                                             >div:nth-child(1) {
//                                                 display: flex;

//                                                 img {
//                                                     width: 10px;
//                                                     height: 10px;
//                                                     margin-right: 5px;
//                                                 }
//                                             }

//                                             >div:nth-child(2) {
//                                                 display: flex;

//                                                 img {
//                                                     width: 9px;
//                                                     height: 9px;
//                                                     // margin-top: 3px;
//                                                     margin-right: 5px;
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }

//                                 // 成果转化
//                                 .science-card4:nth-child(2) {
//                                     padding: 3px;
//                                     box-sizing: border-box;
//                                     font-size: 10px;

//                                     .survey-left2-title1 {
//                                         font-size: 12px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     .survey-left2-title2 {
//                                         display: flex;
//                                         font-size: 6px;
//                                         margin-top: 6px;

//                                         >div:nth-child(1) {
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }

//                                         >div:nth-child(2) {
//                                             margin-left: auto;
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }
//                                     }

//                                     .survey-left2-footer {
//                                         display: flex;
//                                         margin-top: 10px;
//                                         font-size: 6px;

//                                         >div:nth-child(1) {
//                                             display: none;
//                                             font-size: 6px;
//                                             margin-right: 2px;
//                                             // border: 1px solid red;
//                                         }

//                                         >div:nth-child(2) {
//                                             display: flex;
//                                             align-items: center;
//                                             margin-right: 2px;
//                                             margin-left: 0px;
//                                             cursor: pointer;
//                                             // border: 1px solid red;

//                                             >img {
//                                                 width: 9px;
//                                                 height: 6px;
//                                                 // margin-right: 2px;
//                                             }
//                                         }

//                                         >div:nth-child(3) {
//                                             display: flex;
//                                             align-items: center;
//                                             cursor: pointer;
//                                             margin-left: auto;

//                                             >img {
//                                                 width: 9px;
//                                                 height: 6px;
//                                                 // margin-right: 2px;
//                                             }
//                                         }
//                                     }
//                                 }

//                                 // 就业招聘
//                                 .science-card4:nth-child(3) {
//                                     padding: 6px;
//                                     margin-top: 2px;
//                                     box-sizing: border-box;
//                                     font-size: 10px;

//                                     .science-card4-start {
//                                         display: flex;

//                                         >div {
//                                             text-align: center;
//                                             width: 30px;
//                                             // height:5px;
//                                             font-size: 7px;
//                                             margin-right: 3px;
//                                             color: #fff;
//                                             background-color: #5daa00;
//                                         }
//                                     }

//                                     .science-card4-tag1 {
//                                         display: flex;
//                                         margin-top: 6px;
//                                         font-size: 7px;

//                                         >div:nth-child(1) {
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }

//                                         >div:nth-child(2) {
//                                             margin-left: auto;
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }
//                                     }

//                                     .science-card4-tag3 {
//                                         display: flex;
//                                         align-items: center;
//                                         margin-top: 7px;
//                                         font-size: 5px;
//                                         // border: 1px solid #e6e6e6;

//                                         >div:nth-child(1) {
//                                             display: none;
//                                             cursor: pointer;
//                                             font-size: 6px;
//                                         }

//                                         >div:nth-child(2) {
//                                             cursor: pointer;
//                                             display: flex;
//                                             align-items: center;
//                                             margin-right: 2px;
//                                             margin-left: 0;
//                                             // border: 1px solid red;

//                                             img {
//                                                 width: 6px;
//                                                 height: 6px;
//                                                 margin-right: 2px;
//                                             }
//                                         }

//                                         >div:nth-child(3) {
//                                             cursor: pointer;
//                                             display: flex;
//                                             margin-left: auto;

//                                             img {
//                                                 width: 9px;
//                                                 height: 9px;
//                                                 // margin-top: 3px;
//                                                 margin-right: 5px;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         //产品需求end
//                     }
//                 }
//             }
//         }

//         /*科学赋能 end*/
//         /*产业观察 start*/
//         .index-observe {
//             width: 100%;
//             height: 310px;
//             background: url("../../assets/pc/Index/science3.png") no-repeat;
//             background-size: 100% 100%;

//             .index-observe-wrap {
//                 width: 70%;
//                 height: 100%;
//                 margin: 0 auto;
//                 box-sizing: border-box;

//                 // 产业观察头部tap切换
//                 .index-observe-title {
//                     width: 100%;
//                     height: 13%;
//                     display: flex;
//                     padding: 10px 0;
//                     color: #ffffff;
//                     box-sizing: border-box;
//                     // border: 1px solid red;

//                     >div:nth-child(1) {
//                         width: 20%;
//                         font-size: 13px;
//                         margin-right: auto;
//                         margin-left: 10px;
//                     }

//                     >ul {
//                         height: 100%;
//                         list-style: none;
//                         display: flex;

//                         li {
//                             display: flex;
//                             justify-content: center;
//                             width: 60px;
//                             font-size: 10px;
//                             // margin-left: 10px;
//                             cursor: pointer;
//                             // border: 1px solid red;

//                             .observeActive {
//                                 border-bottom: 1px solid #fff;
//                                 // border: 1px solid red;
//                             }

//                             >div:nth-child(1) {
//                                 margin-right: 10px;
//                                 // border: 1px solid red;
//                             }

//                             >div:nth-child(2) {
//                                 height: 70%;
//                                 width: 1px;
//                                 // margin-left: 10px;
//                                 background-color: #fff;
//                             }
//                         }

//                         li:last-child {
//                             >div:nth-child(2) {
//                                 display: none;
//                             }
//                         }
//                     }
//                 }

//                 // 产业观察内容区
//                 .index-observe-cont {
//                     width: 100%;
//                     height: 87%;
//                     display: flex;
//                     justify-content: space-between;
//                     padding: 6px 15px;
//                     box-sizing: border-box;
//                     background-color: #ffffff;

//                     // 产业观察内容区左侧
//                     .index-observe-left {
//                         width: 48%;

//                         >ul {
//                             list-style: none;

//                             li {
//                                 padding: 7px 0;
//                                 margin-top: 4px;
//                                 cursor: pointer;
//                                 border-bottom: 1px solid #dcdcdc;

//                                 >div:nth-child(2) {
//                                     font-size: 11px;
//                                     margin-bottom: 7px;
//                                 }

//                                 >div:nth-child(3) {
//                                     font-size: 8px;
//                                     color: #666666;
//                                 }

//                                 >div:nth-child(4) {
//                                     font-size: 8px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }
//                             }
//                         }
//                     }

//                     // 产业观察内容区右侧
//                     .index-observe-right {
//                         width: 48%;

//                         .index-box-item {
//                             cursor: pointer;
//                             height: 30%;
//                             margin: 5px 0;

//                             // > div:nth-child(1) {
//                             //     font-size: 11px;
//                             // }

//                             .index-right-box {
//                                 display: flex;

//                                 >img {
//                                     width: 100px;
//                                     height: 100%;
//                                 }

//                                 >div {
//                                     height: 100%;
//                                     font-size: 8px;
//                                     margin-left: 10px;
//                                     margin-top: 6px;
//                                     color: #333333;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 6; //行数
//                                     -webkit-box-orient: vertical;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         /*产业观察 end*/

//         .index-wrap {
//             width: 70%;
//             margin: 0 auto;
//             // height: 30px;
//             // border: 1px solid red;

//             /*通告 start*/
//             .index-annunciate {
//                 width: 100%;
//                 height: 185px;
//                 display: flex;
//                 justify-content: space-between;
//                 margin-top: 13px;
//                 // border: 1px solid red;

//                 // 左侧
//                 .index-annunciate-left {
//                     width: 62%;
//                     height: 100%;
//                     // border: 1px solid red;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧
//                 .index-annunciate-right {
//                     width: 35%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     // 头部
//                     .index-right-hed {
//                         display: flex;
//                         align-items: center;
//                         justify-content: space-between;
//                         height: 13%;
//                         padding: 0 5px 3px 10px;
//                         background: url("../../assets/pc/Index/annunciate1.png") no-repeat;
//                         background-size: 100% 100%;
//                         cursor: pointer;

//                         .index-text {
//                             font-size: 10px;
//                             color: #fff;
//                         }

//                         .index-text1 {
//                             font-size: 10px;
//                             color: #fff;
//                         }
//                     }

//                     // 列表
//                     .index-right-list {
//                         // margin-top: 10px;

//                         >ul {
//                             list-style: none;

//                             li:hover {
//                                 color: #0056b0;
//                             }

//                             li {
//                                 display: flex;
//                                 align-items: center;
//                                 height: 30%;
//                                 padding: 3.3% 0;
//                                 cursor: pointer;
//                                 box-sizing: border-box;
//                                 border-bottom: 0.5px solid #cccccc;
//                                 // border: 1px solid red;

//                                 .index-list-data {
//                                     padding: 2px 3px;
//                                     box-sizing: border-box;
//                                     height: 25px;
//                                     width: 30px;
//                                     margin-right: 10px;
//                                     font-size: 7px;
//                                     color: #1681f3;
//                                     border-radius: 3px;
//                                     background-color: #b5d9f9;
//                                     // border: 1px solid red;

//                                     span {
//                                         display: block;
//                                     }
//                                 }

//                                 .index-list-text {
//                                     font-size: 9px;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 2; //行数
//                                     -webkit-box-orient: vertical;
//                                 }
//                             }

//                             >li:last-child {
//                                 border: none;
//                             }
//                         }
//                     }
//                 }
//             }

//             /*通告 end*/

//             /*产业智造 start*/
//             .index-industry {
//                 width: 100%;
//                 height: 270px;
//                 display: flex;
//                 justify-content: space-between;
//                 margin-top: 30px;
//                 // border: 1px solid black;

//                 // 产品制造左侧
//                 .index-industry-left {
//                     width: 68%;
//                     height: 100%;
//                     cursor: pointer;
//                     // border: 1px solid red;

//                     // 头部
//                     .index-induleft {
//                         width: 100%;
//                         height: 21px;
//                         display: flex;
//                         border-bottom: 1.5px solid #0056b0;

//                         .index-indutitle {
//                             width: 70px;
//                             height: 100%;
//                             font-size: 12px;
//                             color: #fff;
//                             padding: 3px 0 0 7px;
//                             box-sizing: border-box;
//                             background: url("../../assets/pc/Index/industry.png") no-repeat;
//                             background-size: 100% 100%;
//                             // border: 1px solid red;
//                         }

//                         .index-induright {
//                             width: 78%;
//                             margin-left: auto;
//                             margin-top: 4px;
//                             // border: 1px solid red;

//                             ul {
//                                 width: 100%;
//                                 list-style: none;
//                                 display: flex;
//                                 justify-content: flex-end;
//                                 // border: 1px solid red;

//                                 li {
//                                     text-align: center;
//                                     width: 35px;
//                                     font-size: 8px;
//                                     margin-left: 2px;
//                                 }

//                                 .industryActive {
//                                     color: #0056b0;
//                                 }

//                                 >span {
//                                     display: block;
//                                     width: 20px;
//                                     font-size: 8.5px;
//                                     color: #1681f3;
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区
//                     .index-industry-body {
//                         width: 100%;
//                         display: flex;
//                         justify-content: space-between;
//                         margin-top: 10px;
//                         // border: 1px solid red;

//                         // 列表(左右列表共用样式)
//                         .index-industryB-list {
//                             width: 48%;
//                             height: 105px;
//                             cursor: pointer;
//                             // border: 1px solid red;

//                             >div {
//                                 width: 100%;
//                                 height: 100%;

//                                 img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             ul {
//                                 margin-top: 7px;

//                                 li {
//                                     border-bottom: 0.7px solid #dcdcdc;

//                                     .index-list-title {
//                                         font-size: 10px;
//                                         font-weight: 700;
//                                         padding: 5px 0;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     .index-list-text {
//                                         // padding: 3px;
//                                         font-size: 8px;
//                                         line-height: 12px;
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     >.index-list-text:nth-child(3) {
//                                         padding-bottom: 5px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 产品智造右侧
//                 .index-industry-right {
//                     width: 30%;
//                     height: 100%;
//                     // border: 1px solid red;

//                     // 产业制造右侧头部
//                     .index-indright {
//                         display: flex;
//                         align-items: center;
//                         box-sizing: border-box;
//                         justify-content: space-between;
//                         border-bottom: 1.5px solid #dcdcdc;

//                         .index-indright-L {
//                             box-sizing: border-box;
//                             margin-bottom: -1.5px;
//                             padding-bottom: 4.8px;
//                             border-bottom: 1.5px solid #0056b0;
//                             font-size: 12px;
//                             font-weight: 700;
//                             color: #0056b0;
//                         }

//                         .index-indright-R {
//                             font-size: 8px;
//                             color: #0056b0;
//                         }
//                     }

//                     // 产业制造右侧文字详情
//                     .index-indright-text {
//                         margin-top: 10px;
//                         font-size: 8px;
//                         cursor: pointer;

//                         // border: 1px solid red;
//                         >div {
//                             color: #666666;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                             display: -webkit-box;
//                             -webkit-line-clamp: 2; //行数
//                             -webkit-box-orient: vertical;
//                         }

//                         >span {
//                             color: #1f78d9;
//                         }
//                     }

//                     // 入会申请、培训报名……
//                     .index-indright-list {
//                         margin-top: 10px;

//                         ul {
//                             list-style: none;
//                             display: flex;
//                             flex-wrap: wrap;
//                             justify-content: space-between;

//                             li {
//                                 width: 46%;
//                                 height: 23px;
//                                 line-height: 23px;
//                                 margin-bottom: 10px;
//                                 background-color: #0068d5;

//                                 >div {
//                                     display: flex;
//                                     align-items: center;
//                                     justify-content: center;
//                                     // border: 1px solid red;

//                                     >div {
//                                         width: 10px;
//                                         height: 10px;
//                                         text-align: center;
//                                         line-height: 0;
//                                         margin-top: -5px;
//                                         margin-right: 5px;
//                                         // border: 1px solid red;

//                                         img {
//                                             width: 100%;
//                                             height: 100%;
//                                         }
//                                     }

//                                     >span {
//                                         font-size: 8px;
//                                         color: #fff;
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 产业制造右侧图片
//                     .index-indright-img {
//                         width: 100%;
//                         height: 110px;
//                         border: 1px solid blanchedalmond;

//                         img {
//                             width: 100%;
//                             height: 100%;
//                         }
//                     }
//                 }
//             }

//             /*产业制造 end*/

//             /*企业会员 start*/
//             .member {
//                 width: 100%;
//                 margin: 30px 0;
//                 // border: 1px solid red;

//                 // 企业会员头部
//                 .index-member {
//                     width: 100%;
//                     display: flex;
//                     border-bottom: 1px solid #0056b0;

//                     .index-member-title {
//                         width: 70px;
//                         height: 23px;
//                         font-size: 12px;
//                         color: #fff;
//                         padding: 4px 0 0 7px;
//                         box-sizing: border-box;
//                         background: url("../../assets/pc/Index/industry.png") no-repeat;
//                         background-size: 100% 100%;
//                     }

//                     .index-memberright {
//                         display: flex;
//                         width: 74%;
//                         margin-left: auto;

//                         // border: 1px solid red;
//                         ul {
//                             width: 90%;
//                             list-style: none;
//                             display: flex;
//                             justify-content: flex-end;
//                             margin-top: 5px;
//                             // border: 1px solid red;

//                             .companys1Active {
//                                 color: #0068d5;
//                             }

//                             li {
//                                 cursor: pointer;
//                                 text-align: center;
//                                 width: 70px;
//                                 font-size: 10px;
//                                 margin-left: 4px;
//                                 // border: 1px solid red;
//                             }

//                             li:last-child {
//                                 width: 90px;
//                             }
//                         }

//                         >span {
//                             width: 8%;
//                             text-align: end;
//                             margin-left: 10px;
//                             margin-top: 4px;
//                             cursor: pointer;
//                             font-size: 10px;
//                             color: #1681f3;
//                             // border: 1px solid red;
//                         }
//                     }
//                 }

//                 /*会员 start*/
//                 .attract {
//                     // margin-top: 100px;
//                     width: 100%;
//                     // height: 190px;
//                     background-size: cover;
//                     // border:1px solid red;

//                     >ul {
//                         display: flex;
//                         flex-wrap: wrap;
//                         list-style: none;

//                         >li {
//                             width: 15%;
//                             height: 70px;
//                             margin-top: 13px;
//                             margin-right: 1.4%;
//                             // border:1px solid blue;
//                         }

//                         >li:nth-child(6) {
//                             margin-right: 0px;
//                         }
//                     }
//                 }

//                 /*会员 end*/
//             }

//             /*企业会员 end*/

//             /*行业标准 start*/
//             .index-hye {
//                 width: 100%;
//                 display: flex;
//                 justify-content: space-between;

//                 // margin-top: 0px;
//                 // height: 600px;
//                 // 行业标准顶部
//                 .index-hye-left {
//                     width: 49%;
//                     // border: 1px solid red;

//                     // 行业标准头部
//                     .index-hye-title {
//                         display: flex;
//                         border-bottom: 1px solid #0056b0;

//                         .index-hyetitle {
//                             width: 70px;
//                             height: 23px;
//                             font-size: 12px;
//                             color: #fff;
//                             padding: 5px 0 0 7px;
//                             box-sizing: border-box;
//                             background: url("../../assets/pc/Index/industry.png") no-repeat;
//                             background-size: 100% 100%;
//                         }

//                         // 行业标准头部tap
//                         .index-hyeright {
//                             width: 85%;
//                             display: flex;
//                             align-items: center;
//                             justify-content: flex-end;
//                             margin-left: auto;

//                             // border: 1px solid red;
//                             ul {
//                                 list-style: none;
//                                 display: flex;
//                                 justify-content: flex-end;
//                                 // border: 1px solid blue;

//                                 .normsActive {
//                                     color: #0056b0;
//                                 }

//                                 li {
//                                     font-size: 10px;
//                                     margin-left: 30px;
//                                     cursor: pointer;
//                                     // border: 1px solid red;
//                                 }
//                             }

//                             >span {
//                                 margin-left: 15px;
//                                 font-size: 10px;
//                                 cursor: pointer;
//                                 color: #0056b0;
//                             }
//                         }
//                     }

//                     // 行业标准内容区
//                     .index-hye-body {
//                         margin-top: 15px;
//                         margin-bottom: 10px;

//                         >ul {
//                             list-style: none;

//                             li {
//                                 display: flex;
//                                 align-items: center;
//                                 margin-top: 8px;
//                                 padding-bottom: 5px;
//                                 border-bottom: 0.5px solid #dedede;
//                                 // border: 1px solid red;

//                                 // 行业标准列表左侧内容
//                                 .index-list-left {
//                                     width: 100%;
//                                     height: 100%;
//                                     // border: 1px solid orange;

//                                     .index-list-top {
//                                         width: 100%;
//                                         display: flex;
//                                         align-items: center;

//                                         .index-list-top1 {
//                                             width: 50px;
//                                             height: 15px;
//                                             text-align: center;
//                                             line-height: 15px;
//                                             margin-right: 5px;
//                                             color: #fff;
//                                             font-size: 9px;
//                                             background-color: #5daa00;
//                                         }

//                                         .index-list-top2 {
//                                             width: 35%;
//                                             font-size: 9px;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;

//                                         }

//                                         // 行业标准列表右侧内容
//                                         .index-list-right {
//                                             display: flex;
//                                             margin-left: auto;
//                                             width: 100px;
//                                             height: 100%;
//                                             color: #666666;
//                                             // border: 1px solid orange;

//                                             .down {
//                                                 width: 40px;
//                                                 height: 15px;
//                                                 text-align: center;
//                                                 line-height: 15px;
//                                                 font-size: 8px;
//                                                 margin-right: 5px;
//                                                 background-color: #fff;
//                                                 border: 1px solid #dedede;

//                                                 >span {
//                                                     font-size: 8px;
//                                                 }
//                                             }

//                                             .check {
//                                                 cursor: pointer;
//                                                 width: 40px;
//                                                 height: 15px;
//                                                 display: flex;
//                                                 align-items: center;
//                                                 justify-content: center;
//                                                 margin-right: 5px;
//                                                 background-color: #fff;
//                                                 border: 1px solid #dedede;

//                                                 >img {
//                                                     width: 7px;
//                                                     height: 7px;
//                                                     margin-right: 5px;
//                                                     display: block;
//                                                 }

//                                                 >span {
//                                                     font-size: 8px;
//                                                 }
//                                             }

//                                             .collect {
//                                                 cursor: pointer;
//                                                 margin-left: auto;
//                                                 width: 40px;
//                                                 height: 15px;
//                                                 text-align: center;
//                                                 line-height: 15px;
//                                                 font-size: 8px;
//                                                 background-color: #fff;
//                                                 border: 1px solid #dedede;

//                                                 >span {
//                                                     font-size: 8px;
//                                                 }
//                                             }
//                                         }
//                                     }

//                                     .index-list-bottom {
//                                         font-size: 9px;
//                                         margin-top: 10px;
//                                         color: #666666;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 /*行业标准 end*/

//                 /*政策法规 start*/
//                 .index-policy-left {
//                     width: 49%;

//                     // 政策法规头部
//                     .index-policy-title {
//                         display: flex;
//                         border-bottom: 1px solid #0056b0;

//                         .index-policytitle {
//                             width: 70px;
//                             height: 23px;
//                             font-size: 12px;
//                             color: #fff;
//                             padding: 5px 0 0 7px;
//                             box-sizing: border-box;
//                             background: url("../../assets/pc/Index/industry.png") no-repeat;
//                             background-size: 100% 100%;
//                         }

//                         // 政策法规头部tap
//                         .index-policyright {
//                             width: 85%;
//                             display: flex;
//                             align-items: center;
//                             justify-content: flex-end;
//                             margin-left: auto;

//                             ul {
//                                 list-style: none;
//                                 display: flex;
//                                 justify-content: flex-end;
//                                 // border: 1px solid blue;

//                                 .normsActive {
//                                     color: #0056b0;
//                                 }

//                                 li {
//                                     font-size: 10px;
//                                     margin-left: 30px;
//                                     cursor: pointer;
//                                     // border: 1px solid red;
//                                 }
//                             }

//                             >span {
//                                 margin-left: 15px;
//                                 font-size: 10px;
//                                 cursor: pointer;
//                                 color: #0056b0;
//                             }
//                         }
//                     }

//                     //政策法规内容区
//                     .index-policy-body {
//                         >ul {
//                             list-style: none;

//                             >li {
//                                 display: flex;
//                                 align-items: center;
//                                 padding: 6.5px 0;
//                                 border-bottom: 0.5px solid #dedede;

//                                 >div:nth-child(1) {
//                                     width: 60px;

//                                     >img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }
//                                 }

//                                 >div:nth-child(2) {
//                                     display: flex;
//                                     flex-direction: column;
//                                     margin-left: 5px;
//                                     width: 80%;
//                                     font-size: 10px;

//                                     >div:nth-child(1) {
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     >div:nth-child(2) {
//                                         margin-top: auto;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         display: -webkit-box;
//                                         -webkit-line-clamp: 2; //行数
//                                         -webkit-box-orient: vertical;

//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 /*政策法规 end*/
//             }


//             /*数智金融 start*/
//             .index-digifax {
//                 width: 100%;
//                 height: 150px;
//                 margin: 0 auto;
//                 margin-top: 20px;
//                 // border: 1px solid red;

//                 // 横向滚动
//                 .attract1 {
//                     display: flex;
//                     width: 100%;

//                     .attract1-title {
//                         display: flex;
//                         text-align: center;
//                         flex-direction: column;
//                         width: 25px;
//                         margin: 10px 0;
//                         padding: 10px 4px 10px 0;
//                         margin-right: 5px;
//                         background-color: #0056b0;

//                         >div:nth-child(1) {
//                             width: 100%;
//                             text-align: center;
//                             writing-mode: vertical-rl;
//                             font-size: 15px;
//                             color: #fff;
//                         }

//                         >div:nth-child(2) {
//                             width: 100%;
//                             text-align: center;
//                             margin-top: auto;
//                             margin-left: 2px;
//                             font-size: 8px;
//                             color: #fff;
//                         }
//                     }

//                     .seamless-warp2 {
//                         overflow: hidden;
//                         width: 94%;

//                         ul.item {
//                             list-style: none;
//                             display: flex;
//                             width: 100%;
//                             height: 100%;

//                             li {
//                                 width: 130px;
//                                 // height: 100%;
//                                 margin: 10px;
//                                 margin-right: 10px;
//                                 font-size: 10px;
//                                 box-shadow: 2px 2px 10px #e1e0e0;

//                                 .index-digifax-img {
//                                     width: 100%;
//                                     height: 90px;

//                                     img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }
//                                 }

//                                 .index-digifax-content {
//                                     display: flex;
//                                     align-items: center;
//                                     padding: 0 10px;
//                                     margin-top: 10px;
//                                     margin-bottom: 10px;
//                                     // border: 1px solid red;

//                                     .index-content-ms {
//                                         font-size: 8px;

//                                         line-height: 15px;

//                                         // border: 1px solid red;
//                                         >span {
//                                             display: block;
//                                         }
//                                     }

//                                     .index-content-icon:hover {
//                                         color: #1681f3;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 横向滚动
//             }

//             /*数智金融 end*/

//             /*食品咨询 start*/
//             .index-food {
//                 width: 100%;
//                 display: flex;
//                 // justify-content: space-between;
//                 margin-top: 40px;
//                 // height: 240px;


//                 // 食品咨询item
//                 .index-industry-left {
//                     width: 100%;
//                     // border: 1px solid red;

//                     // 头部
//                     .index-induleft {
//                         display: flex;
//                         border-bottom: 1px solid #0056b0;

//                         .index-indutitle {
//                             width: 70px;
//                             height: 23px;
//                             font-size: 12px;
//                             color: #fff;
//                             padding: 4px 0 0 7px;
//                             box-sizing: border-box;
//                             background: url("../../assets/pc/Index/industry.png") no-repeat;
//                             background-size: 100% 100%;
//                         }

//                         .index-memberright {
//                             display: flex;
//                             width: 74%;
//                             margin-left: auto;

//                             // border: 1px solid red;
//                             ul {
//                                 width: 90%;
//                                 list-style: none;
//                                 display: flex;
//                                 justify-content: flex-end;
//                                 margin-top: 5px;
//                                 // border: 1px solid red;

//                                 .companys1Active {
//                                     color: #0068d5;
//                                 }

//                                 li {
//                                     cursor: pointer;
//                                     text-align: center;
//                                     width: 70px;
//                                     font-size: 10px;
//                                     margin-left: 4px;
//                                     // border: 1px solid red;
//                                 }

//                                 li:last-child {
//                                     width: 90px;
//                                 }
//                             }

//                             >span {
//                                 width: 8%;
//                                 text-align: end;
//                                 margin-left: 10px;
//                                 margin-top: 4px;
//                                 cursor: pointer;
//                                 font-size: 10px;
//                                 color: #1681f3;
//                                 // border: 1px solid red;
//                             }
//                         }
//                     }

//                     // 内容区
//                     .index-industry-body {
//                         display: flex;
//                         // justify-content: space-between;
//                         margin-top: 20px;

//                         // 列表(左右列表共用样式)
//                         .index-industryB-list {
//                             width: 32%;
//                             height: 100%;
//                             cursor: pointer;

//                             >div {
//                                 // margin-left:10px;
//                                 width: 100%;
//                                 height: 70px;

//                                 img {
//                                     width: 97%;
//                                     height: 100%;
//                                 }
//                             }

//                             >ul {
//                                 list-style: none;
//                                 // margin-left:10px;
//                                 // border: 1px solid red;

//                                 // li:first-child{
//                                 //     margin-left:0
//                                 // }

//                                 li {
//                                     height: 70px;
//                                     cursor: pointer;
//                                     margin-right: 3px;
//                                     border-bottom: 0.5px solid #dcdcdc;

//                                     .index-list-title {
//                                         >span {
//                                             display: inline-block;
//                                             width: 7px;
//                                             height: 7px;
//                                             background-color: #0056b0;
//                                             border-radius: 50%;
//                                         }

//                                         font-size: 12px;
//                                         padding: 5px 0;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     .index-list-text:nth-child(2) {
//                                         padding: 3px 0;
//                                         font-size: 8px;
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     .index-list-text:nth-child(3) {
//                                         // padding: 3px;
//                                         font-size: 8px;
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         padding-bottom: 10px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             /*食品咨询 end*/

//             /*会员3 start*/
//             .index-member3 {
//                 display: flex;
//                 justify-content: space-between;
//                 width: 100%;
//                 // margin-bottom:20px;
//                 list-style: none;

//                 >li {
//                     width: 13.5%;
//                     height: 40px;

//                     >img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//             }

//             /*会员3 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .el-carousel__item:nth-child(2n + 1) {
//         background-color: #fff;
//     }

//     .el-carousel--vertical {
//         height: 20px !important;
//     }

//     .index {
//         width: 100%;

//         /*科学赋能 start*/
//         .index-science {
//             width: 100%;
//             height: 270px;
//             margin: 20px 0px;
//             // border:1px solid red;

//             .index-science-wrap {
//                 width: 80%;
//                 height: 240px;
//                 margin: 0 auto;

//                 // 科学赋能左侧
//                 .index-science-left {
//                     width: 16%;
//                     height: 100%;

//                     // 科学赋能左侧头部
//                     .iScience-left-title {
//                         .iScience-title-text {
//                             font-size: 15px;
//                         }

//                         .iScience-title-ms {
//                             font-size: 4px;
//                         }
//                     }

//                     // 科学赋能左侧tap栏
//                     .iScience-left-tap {
//                         ul {
//                             li {
//                                 width: 100%;
//                                 height: 30px;
//                                 line-height: 40px;
//                                 margin-top: 10px;
//                                 padding-left: 10px;
//                                 font-size: 8px;
//                                 cursor: pointer;
//                             }
//                         }
//                     }
//                 }

//                 // 科学赋能右侧
//                 .index-science-right {
//                     width: 81%;
//                     height: 100%;
//                     padding: 10px;

//                     // 科学赋能右侧顶部tap
//                     .science-right-tap {
//                         >ul {
//                             margin-right: 10px;

//                             li {
//                                 margin-left: 12px;
//                                 font-size: 9px;
//                             }
//                         }

//                         >span {
//                             font-size: 8px;
//                         }
//                     }

//                     // 科学赋能右侧内容区域
//                     .science-right-cont {

//                         // 科技资源布局start
//                         .ul1 {
//                             margin-top: 10px;

//                             li {
//                                 width: 32%;
//                                 height: 80px;
//                                 margin-bottom: 10px;

//                                 .science-card1 {
//                                     width: 100%;
//                                     // padding: 5px;

//                                     >img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }

//                                     // .science-card-img {
//                                     //     width: 40%;
//                                     // }

//                                     // .science-card-text {
//                                     //     width: 60%;
//                                     //     .science-card-title {
//                                     //         font-size: 7px;
//                                     //     }
//                                     //     .science-card-phone {
//                                     //         font-size: 6px;
//                                     //     }
//                                     // }
//                                 }

//                                 // .science-card-details {
//                                 //     margin-top: 10px;
//                                 //     font-size: 8px;
//                                 // }
//                             }
//                         }

//                         // 科技资源布局end

//                         // 助企名家start
//                         .ul2 {
//                             margin-top: 10px;

//                             li {
//                                 width: 33%;
//                                 height: 80px;
//                                 padding: 8px 4px;
//                                 margin-bottom: 10px;
//                                 cursor: pointer;

//                                 .science-card2 {
//                                     width: 100%;

//                                     // 助企名家图片
//                                     .science-card2-img {
//                                         width: 40%;
//                                         height: 60px;
//                                         margin-right: 5px;

//                                         img {
//                                             width: 100%;
//                                             height: 100%;
//                                         }
//                                     }

//                                     // 助企名家描述
//                                     .science-card2-title {
//                                         width: 60%;

//                                         .card2-title1 {
//                                             font-size: 9px;
//                                         }

//                                         .card2-title2 {
//                                             margin-top: 3px;
//                                             font-size: 7px;
//                                         }

//                                         .card2-title3 {
//                                             margin-top: 3px;
//                                             font-size: 4px;
//                                         }

//                                         .card2-btn {
//                                             >div {
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                                 font-size: 4px;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 助企名家end

//                         // 助企活动start
//                         .ul3 {
//                             margin-top: 10px;

//                             li {
//                                 width: 32%;
//                                 height: 86px;
//                                 margin-bottom: 10px;
//                                 cursor: pointer;

//                                 .science-card3 {

//                                     // 助企活动图片
//                                     >img {
//                                         width: 100%;
//                                         height: 50px;
//                                     }

//                                     // 助企活动标题
//                                     .science-card3-title {
//                                         padding: 3px;
//                                         font-size: 9px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     // 助企活动描述
//                                     .science-card3-ms {
//                                         padding: 1px;
//                                         font-size: 7px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     // 助企活动底部样式
//                                     .science-card3-footer {
//                                         display: none;
//                                         padding: 4px;

//                                         .science-card3-footer1 {
//                                             width: 30px;
//                                             height: 13px;
//                                             line-height: 13px;
//                                             font-size: 6px;
//                                         }

//                                         .science-card3-footer2 {
//                                             >img {
//                                                 width: 6px;
//                                                 height: 6px;
//                                             }

//                                             >div {
//                                                 margin-left: 3px;
//                                                 font-size: 6px;
//                                             }
//                                         }

//                                         .science-card3-footer3 {
//                                             display: flex;
//                                             align-items: center;

//                                             >img {
//                                                 width: 6px;
//                                                 height: 7px;
//                                             }

//                                             >div {
//                                                 margin-left: 3px;
//                                                 font-size: 6px;
//                                                 color: #999999;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 助企活动end

//                         //产品需求start
//                         .ul4 {
//                             margin-top: 10px;

//                             li {
//                                 position: relative;
//                                 width: 32%;
//                                 height: 90px;
//                                 margin-bottom: 10px;
//                                 cursor: pointer;

//                                 // 技术难题
//                                 .science-card4:nth-child(1) {
//                                     padding: 4px;
//                                     box-sizing: border-box;

//                                     >img {
//                                         position: absolute;
//                                         top: 0px;
//                                         left: 0px;
//                                         width: 20px;
//                                         height: 20px;
//                                     }

//                                     .science-card4-start {
//                                         display: flex;
//                                         justify-content: space-between;

//                                         >div:nth-child(1) {
//                                             font-size: 9px;
//                                             margin-left: 30px;
//                                         }

//                                     }

//                                     .science-card4-tag0 {
//                                         margin-top: 3px;
//                                         font-size: 7px;
//                                     }

//                                     .science-card4-tag4 {
//                                         margin-top: 3px;
//                                         font-size: 7px;

//                                         >div {
//                                             width: 50px;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }
//                                     }

//                                     .science-card4-tag1 {
//                                         display: flex;
//                                         margin-top: 3px;
//                                         font-size: 7px;

//                                         >div:nth-child(1) {
//                                             margin-right: auto;
//                                         }

//                                         >div:nth-child(2) {
//                                             display: none;
//                                         }
//                                     }

//                                     .science-card4-tag5 {
//                                         display: block;
//                                         margin-top: 3px;
//                                         font-size: 7px;
//                                     }

//                                     .science-card4-tag2 {
//                                         display: flex;
//                                         align-items: center;
//                                         margin-top: 3px;
//                                         font-size: 7px;
//                                         color: #c1c1c1;

//                                         >div:nth-child(1) {
//                                             display: none;
//                                         }

//                                         .science-card4-tag3 {
//                                             display: flex;
//                                             justify-content: space-around;
//                                             margin-top: 3px;
//                                             font-size: 6px;
//                                             cursor: pointer;
//                                             margin-left: auto;

//                                             >div:nth-child(1) {
//                                                 display: flex;

//                                                 img {
//                                                     width: 10px;
//                                                     height: 10px;
//                                                     margin-right: 5px;
//                                                 }
//                                             }

//                                             >div:nth-child(2) {
//                                                 display: flex;

//                                                 img {
//                                                     width: 9px;
//                                                     height: 9px;
//                                                     // margin-top: 3px;
//                                                     margin-right: 5px;
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }

//                                 // 成果转化
//                                 .science-card4:nth-child(2) {
//                                     padding: 3px;
//                                     box-sizing: border-box;
//                                     font-size: 10px;

//                                     .survey-left2-title1 {
//                                         font-size: 12px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     .survey-left2-title2 {
//                                         display: flex;
//                                         font-size: 6px;
//                                         margin-top: 4px;

//                                         >div:nth-child(1) {
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }

//                                         >div:nth-child(2) {
//                                             margin-left: auto;
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }
//                                     }

//                                     .survey-left2-footer {
//                                         display: flex;
//                                         margin-top: 8px;
//                                         font-size: 6px;

//                                         >div:nth-child(1) {
//                                             display: none;
//                                             font-size: 6px;
//                                             margin-right: 2px;
//                                             // border: 1px solid red;
//                                         }

//                                         >div:nth-child(2) {
//                                             display: flex;
//                                             align-items: center;
//                                             margin-right: 2px;
//                                             margin-left: 0px;
//                                             cursor: pointer;
//                                             // border: 1px solid red;

//                                             >img {
//                                                 width: 9px;
//                                                 height: 6px;
//                                                 // margin-right: 2px;
//                                             }
//                                         }

//                                         >div:nth-child(3) {
//                                             display: flex;
//                                             align-items: center;
//                                             cursor: pointer;
//                                             margin-left: auto;

//                                             >img {
//                                                 width: 9px;
//                                                 height: 6px;
//                                                 // margin-right: 2px;
//                                             }
//                                         }
//                                     }
//                                 }

//                                 // 就业招聘
//                                 .science-card4:nth-child(3) {
//                                     padding: 6px;
//                                     margin-top: 10px;
//                                     box-sizing: border-box;
//                                     font-size: 10px;

//                                     .science-card4-start {
//                                         display: flex;

//                                         >div {
//                                             text-align: center;
//                                             width: 30px;
//                                             // height:5px;
//                                             font-size: 7px;
//                                             margin-right: 3px;
//                                             color: #fff;
//                                             background-color: #5daa00;
//                                         }
//                                     }

//                                     .science-card4-tag1 {
//                                         display: flex;
//                                         margin-top: 4px;
//                                         font-size: 7px;

//                                         >div:nth-child(1) {
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }

//                                         >div:nth-child(2) {
//                                             margin-left: auto;
//                                             color: #666666;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }
//                                     }

//                                     .science-card4-tag3 {
//                                         display: flex;
//                                         align-items: center;
//                                         margin-top: 8px;
//                                         font-size: 5px;

//                                         >div:nth-child(1) {
//                                             display: none;
//                                             cursor: pointer;
//                                             font-size: 6px;
//                                         }

//                                         >div:nth-child(2) {
//                                             cursor: pointer;
//                                             display: flex;
//                                             margin-right: 2px;
//                                             margin-left: 0;
//                                             // border: 1px solid red;

//                                             img {
//                                                 width: 10px;
//                                                 height: 10px;
//                                                 margin-right: 5px;
//                                             }
//                                         }

//                                         >div:nth-child(3) {
//                                             cursor: pointer;
//                                             display: flex;
//                                             margin-left: auto;

//                                             img {
//                                                 width: 9px;
//                                                 height: 9px;
//                                                 // margin-top: 3px;
//                                                 margin-right: 5px;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         //产品需求end
//                     }
//                 }
//             }
//         }

//         /*科学赋能 end*/

//         /*产业观察 start*/
//         .index-observe {
//             width: 100%;
//             height: 248px;
//             background: url("../../assets/pc/Index/science3.png") no-repeat;
//             background-size: 100% 100%;

//             .index-observe-wrap {
//                 width: 80%;
//                 height: 100%;
//                 margin: 0 auto;
//                 box-sizing: border-box;

//                 // 产业观察头部tap切换
//                 .index-observe-title {
//                     width: 100%;
//                     height: 13%;
//                     display: flex;
//                     padding: 10px 0;
//                     color: #ffffff;
//                     box-sizing: border-box;
//                     // border: 1px solid red;

//                     >div:nth-child(1) {
//                         width: 20%;
//                         font-size: 13px;
//                         margin-right: auto;
//                         margin-left: 8px;
//                     }

//                     >ul {
//                         height: 100%;
//                         list-style: none;
//                         display: flex;

//                         li {
//                             width: 40px;
//                             display: flex;
//                             font-size: 6px;
//                             margin-left: 10px;
//                             cursor: pointer;
//                             // border: 1px solid red;

//                             .observeActive {
//                                 border-bottom: 1px solid #fff;
//                                 // border: 1px solid red;
//                             }

//                             >div:nth-child(1) {
//                                 margin-right: 10px;
//                                 // border: 1px solid red;
//                             }

//                             >div:nth-child(2) {
//                                 height: 70%;
//                                 width: 1px;
//                                 // margin-left: 10px;
//                                 background-color: #fff;
//                             }
//                         }

//                         li:last-child {
//                             >div:nth-child(2) {
//                                 display: none;
//                             }
//                         }
//                     }
//                 }

//                 // 产业观察内容区
//                 .index-observe-cont {
//                     width: 100%;
//                     height: 87%;
//                     display: flex;
//                     justify-content: space-between;
//                     padding: 3px 10px;
//                     box-sizing: border-box;
//                     background-color: #ffffff;

//                     // 产业观察内容区左侧
//                     .index-observe-left {
//                         width: 48%;

//                         >ul {
//                             list-style: none;

//                             li {
//                                 padding: 7px 0;
//                                 margin-top: 2px;
//                                 cursor: pointer;
//                                 border-bottom: 1px solid #dcdcdc;

//                                 >div:nth-child(2) {
//                                     font-size: 9px;
//                                     margin-bottom: 5px;
//                                 }

//                                 >div:nth-child(3) {
//                                     font-size: 6px;
//                                     color: #666666;
//                                 }

//                                 >div:nth-child(4) {
//                                     font-size: 6px;
//                                     color: #666666;
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }
//                             }
//                         }
//                     }

//                     // 产业观察内容区右侧
//                     .index-observe-right {
//                         width: 48%;

//                         .index-box-item {
//                             cursor: pointer;
//                             height: 30%;
//                             margin: 5px 0;

//                             // > div:nth-child(1) {
//                             //     font-size: 11px;
//                             //     white-space: nowrap;
//                             //     overflow: hidden;
//                             //     text-overflow: ellipsis;
//                             // }

//                             .index-right-box {
//                                 display: flex;

//                                 >img {
//                                     width: 80px;
//                                     height: 100%;
//                                 }

//                                 >div {
//                                     height: 100%;
//                                     font-size: 8px;
//                                     margin-left: 10px;
//                                     margin-top: 6px;
//                                     color: #333333;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 4; //行数
//                                     -webkit-box-orient: vertical;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }

//         /*产业观察 end*/

//         .index-wrap {
//             width: 80%;
//             margin: 0 auto;

//             /*通告 start*/
//             .index-annunciate {
//                 margin-top: 10px;
//                 height: 130px;

//                 // 左侧
//                 .index-annunciate-left {
//                     width: 60%;
//                 }

//                 // 右侧
//                 .index-annunciate-right {
//                     width: 38%;
//                     height: 100%;
//                     display: flex;
//                     flex-direction: column;
//                     cursor: pointer;

//                     .index-right-hed {
//                         display: flex;
//                         align-items: center;
//                         justify-content: space-between;
//                         height: 13%;
//                         padding: 0 5px 3px 10px;
//                         background: url("../../assets/pc/Index/annunciate1.png") no-repeat;
//                         background-size: 100% 100%;
//                         cursor: pointer;

//                         .index-text {
//                             font-size: 8px;
//                             color: #fff;
//                         }

//                         .index-text1 {
//                             font-size: 8px;
//                             color: #fff;
//                         }
//                     }

//                     // 列表
//                     .index-right-list {
//                         margin-top: auto;

//                         >ul {
//                             li {
//                                 padding: 1.5% 0;
//                                 border-bottom: 0.5px solid #cccccc;

//                                 .index-list-data {
//                                     height: 20px;
//                                     font-size: 6px;
//                                     // border: 1px solid red;
//                                 }

//                                 .index-list-text {
//                                     font-size: 8px;
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             /*通告 end*/

//             /*产业制造 start*/
//             .index-industry {
//                 height: 200px;
//                 margin-top: 15px;

//                 // 产品制造左侧
//                 .index-industry-left {

//                     // 头部
//                     .index-induleft {
//                         border-bottom: 1.6px solid #0056b0;

//                         .index-indutitle {
//                             width: 23%;
//                             font-size: 11px;
//                         }

//                         .index-induright {
//                             ul {
//                                 li {
//                                     text-align: center;
//                                     width: 40px;
//                                     // margin-left: 5px;
//                                     font-size: 8px;
//                                     // border: 1px solid red;
//                                 }

//                                 >span {
//                                     display: block;
//                                     text-align: end;
//                                     width: 40px;
//                                     font-size: 8px;
//                                     color: #1681f3;
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区
//                     .index-industry-body {

//                         // 列表(左右列表共用样式)
//                         .index-industryB-list {
//                             height: 60px;

//                             ul {
//                                 li {
//                                     .index-list-title {
//                                         font-size: 8px;
//                                     }

//                                     .index-list-text {
//                                         font-size: 5px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 产品制造右侧
//                 .index-industry-right {

//                     // 入会申请、培训报名……
//                     .index-indright-list {
//                         margin-top: 10px;

//                         ul {
//                             li {
//                                 margin-bottom: 10px;
//                             }
//                         }
//                     }

//                     // 产业制造右侧图片
//                     .index-indright-img {
//                         height: 57px;
//                     }
//                 }
//             }

//             /*产业制造 end*/

//             /*企业会员 start*/
//             .member {
//                 width: 100%;
//                 margin: 30px 0;

//                 // 企业会员头部
//                 .index-member {
//                     width: 100%;
//                     display: flex;
//                     border-bottom: 1.6px solid #0056b0;

//                     .index-member-title {
//                         width: 17%;
//                         height: 22px;
//                         font-size: 12px;
//                     }

//                     .index-memberright {
//                         width: 80%;
//                         // border: 1px solid red;

//                         ul {
//                             li {
//                                 font-size: 8px;
//                                 margin-left: 7px;
//                             }
//                         }

//                         >span {
//                             width: 30px;
//                             font-size: 8px;
//                             margin-top: 3.5px;
//                             // border: 1px solid red;
//                         }
//                     }
//                 }

//                 /*会员 start*/
//                 .attract {
//                     // margin-top: 100px;
//                     width: 100%;
//                     // height: 190px;
//                     background-size: cover;
//                     // border:1px solid red;

//                     >ul {
//                         display: flex;
//                         flex-wrap: wrap;
//                         list-style: none;

//                         >li {
//                             width: 15%;
//                             height: 70px;
//                             margin-top: 13px;
//                             margin-right: 1.4%;
//                             // border:1px solid blue;

//                             >img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }

//                         >li:nth-child(6) {
//                             margin-right: 0px;
//                         }
//                     }
//                 }

//                 /*会员 end*/
//             }

//             /*企业会员 end*/

//             /*行业标准 start*/
//             .index-hye {
//                 width: 100%;
//                 margin-top: 15px;

//                 // 行业标准顶部
//                 .index-hye-left {
//                     width: 49%;

//                     // 行业标准头部
//                     .index-hye-title {
//                         display: flex;
//                         border-bottom: 1.6px solid #0056b0;

//                         .index-hyetitle {
//                             width: 70px;
//                             height: 23px;
//                             font-size: 10px;
//                             color: #fff;
//                             padding: 5px 0 0 7px;
//                             box-sizing: border-box;
//                             background: url("../../assets/pc/Index/industry.png") no-repeat;
//                             background-size: 100% 100%;
//                         }

//                         // 行业标准头部tap
//                         .index-hyeright {
//                             width: 85%;

//                             ul {
//                                 .normsActive {
//                                     color: #0056b0;
//                                 }

//                                 li {
//                                     font-size: 10px;
//                                     margin-left: 10px;
//                                     cursor: pointer;
//                                 }
//                             }

//                             >span {
//                                 margin-left: 30px;
//                                 font-size: 10px;
//                                 cursor: pointer;
//                             }
//                         }
//                     }

//                     // 行业标准内容区
//                     .index-hye-body {
//                         margin-top: 10px;
//                         margin-bottom: 10px;

//                         >ul {
//                             li {
//                                 margin-top: 10px;
//                                 border-bottom: 1px solid #cccccc;

//                                 // 行业标准列表左侧内容
//                                 .index-list-left {
//                                     width: 100%;
//                                     height: 100%;

//                                     .index-list-top {
//                                         .index-list-top2 {
//                                             font-size: 9px;
//                                         }

//                                         .index-list-right {
//                                             display: flex;
//                                             margin-left: auto;
//                                             width: 100px;
//                                             height: 100%;
//                                             color: #666666;
//                                             // border: 1px solid orange;

//                                             .down {
//                                                 width: 40px;
//                                                 height: 15px;
//                                                 text-align: center;
//                                                 line-height: 15px;
//                                                 font-size: 8px;
//                                                 margin-right: 5px;
//                                                 background-color: #fff;
//                                                 border: 1px solid #dedede;

//                                                 >span {
//                                                     font-size: 8px;
//                                                 }
//                                             }

//                                             .check {
//                                                 cursor: pointer;
//                                                 width: 40px;
//                                                 height: 15px;
//                                                 display: flex;
//                                                 align-items: center;
//                                                 justify-content: center;
//                                                 margin-right: 5px;
//                                                 background-color: #fff;
//                                                 border: 1px solid #dedede;

//                                                 >img {
//                                                     width: 7px;
//                                                     height: 7px;
//                                                     margin-right: 5px;
//                                                     display: block;
//                                                 }

//                                                 >span {
//                                                     font-size: 8px;
//                                                 }
//                                             }

//                                             .collect {
//                                                 cursor: pointer;
//                                                 margin-left: auto;
//                                                 width: 40px;
//                                                 height: 15px;
//                                                 text-align: center;
//                                                 line-height: 15px;
//                                                 font-size: 8px;
//                                                 background-color: #fff;
//                                                 border: 1px solid #dedede;

//                                                 >span {
//                                                     font-size: 8px;
//                                                 }
//                                             }
//                                         }
//                                     }

//                                     .index-list-bottom {
//                                         font-size: 9px;
//                                         margin-top: 5px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 /*行业标准 end*/

//                 /*政策法规 start*/
//                 .index-policy-left {
//                     width: 49%;

//                     // 政策法规头部
//                     .index-policy-title {
//                         display: flex;
//                         border-bottom: 1px solid #0056b0;

//                         .index-policytitle {
//                             width: 80px;
//                             height: 23px;
//                             font-size: 12px;
//                             color: #fff;
//                             padding: 5px 0 0 7px;
//                             box-sizing: border-box;
//                             background: url("../../assets/pc/Index/industry.png") no-repeat;
//                             background-size: 100% 100%;
//                         }

//                         // 政策法规头部tap
//                         .index-policyright {
//                             width: 85%;
//                             display: flex;
//                             align-items: center;
//                             justify-content: flex-end;
//                             margin-left: auto;

//                             ul {
//                                 list-style: none;
//                                 display: flex;
//                                 justify-content: flex-end;
//                                 // border: 1px solid blue;

//                                 .normsActive {
//                                     color: #0056b0;
//                                 }

//                                 li {
//                                     font-size: 10px;
//                                     margin-left: 30px;
//                                     cursor: pointer;
//                                     // border: 1px solid red;
//                                 }
//                             }

//                             >span {
//                                 margin-left: 15px;
//                                 font-size: 10px;
//                                 cursor: pointer;
//                                 color: #0056b0;
//                             }
//                         }
//                     }

//                     //政策法规内容区
//                     .index-policy-body {
//                         >ul {
//                             list-style: none;

//                             >li {
//                                 display: flex;
//                                 align-items: center;
//                                 padding: 4px 0;
//                                 border-bottom: 0.5px solid #dedede;

//                                 >div:nth-child(1) {
//                                     width: 120px;

//                                     >img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }
//                                 }

//                                 >div:nth-child(2) {
//                                     display: flex;
//                                     flex-direction: column;
//                                     margin-left: 5px;
//                                     width: 70%;
//                                     font-size: 10px;

//                                     >div:nth-child(1) {
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     >div:nth-child(2) {
//                                         margin-top: auto;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         display: -webkit-box;
//                                         -webkit-line-clamp: 2; //行数
//                                         -webkit-box-orient: vertical;

//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 /*政策法规 end*/
//             }


//             /*数智金融 start*/
//             .index-digifax {
//                 width: 100%;
//                 height: 150px;
//                 margin: 0 auto;
//                 margin-top: 20px;
//                 // border: 1px solid red;

//                 // 横向滚动
//                 .attract1 {
//                     display: flex;
//                     width: 100%;

//                     .attract1-title {
//                         display: flex;
//                         text-align: center;
//                         flex-direction: column;
//                         width: 25px;
//                         margin: 10px 0;
//                         padding: 10px 4px 10px 0;
//                         margin-right: 5px;
//                         background-color: #0056b0;

//                         >div:nth-child(1) {
//                             width: 100%;
//                             text-align: center;
//                             writing-mode: vertical-rl;
//                             font-size: 15px;
//                             color: #fff;
//                         }

//                         >div:nth-child(2) {
//                             width: 100%;
//                             text-align: center;
//                             margin-top: auto;
//                             margin-left: 2px;
//                             font-size: 8px;
//                             color: #fff;
//                         }
//                     }

//                     .seamless-warp2 {
//                         overflow: hidden;
//                         width: 94%;

//                         ul.item {
//                             list-style: none;
//                             display: flex;
//                             width: 100%;
//                             height: 100%;

//                             li {
//                                 width: 130px;
//                                 // height: 100%;
//                                 margin: 10px;
//                                 margin-right: 10px;
//                                 font-size: 10px;
//                                 box-shadow: 2px 2px 10px #e1e0e0;

//                                 .index-digifax-img {
//                                     width: 100%;
//                                     height: 90px;

//                                     img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }
//                                 }

//                                 .index-digifax-content {
//                                     display: flex;
//                                     align-items: center;
//                                     padding: 0 10px;
//                                     margin-top: 10px;
//                                     margin-bottom: 10px;
//                                     // border: 1px solid red;

//                                     .index-content-ms {
//                                         font-size: 8px;

//                                         line-height: 15px;

//                                         // border: 1px solid red;
//                                         >span {
//                                             display: block;
//                                         }
//                                     }

//                                     .index-content-icon:hover {
//                                         color: #1681f3;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 横向滚动
//             }

//             /*数智金融 end*/

//             /*食品咨询 start*/
//             .index-food {
//                 width: 100%;
//                 margin-top: 40px;
//                 // height: 220px;
//                 margin-bottom: 10px;

//                 // 食品咨询item
//                 .index-industry-left {
//                     width: 100%;

//                     // 头部
//                     .index-induleft {
//                         border-bottom: 1px solid #0056b0;

//                         .index-indutitle {
//                             width: 16%;
//                             height: 26px;
//                             font-size: 12px;
//                             padding: 5px 0 0 5px;
//                         }
//                     }

//                     // 内容区
//                     .index-industry-body {
//                         margin-top: 10px;

//                         // 列表(左右列表共用样式)
//                         .index-industryB-list {
//                             width: 32%;
//                             height: 100%;

//                             >div {
//                                 width: 100%;
//                                 height: 60px;

//                                 img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             >ul {
//                                 cursor: pointer;
//                                 list-style: none;

//                                 li {
//                                     border-bottom: 0.5px solid #dcdcdc;
//                                     // border: 1px solid red;

//                                     .index-list-title {
//                                         >span {
//                                             display: inline-block;
//                                             width: 7px;
//                                             height: 7px;
//                                             background-color: #0056b0;
//                                             border-radius: 50%;
//                                         }

//                                         font-size: 9px;
//                                         padding: 2px 0;
//                                     }

//                                     .index-list-text:nth-child(2) {
//                                         padding: 3px 0;
//                                         font-size: 8px;
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     .index-list-text:nth-child(3) {
//                                         // padding: 3px;
//                                         font-size: 8px;
//                                         color: #666666;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         padding-bottom: 8px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             /*食品咨询 end*/

//             /*会员3 start*/
//             .index-member3 {
//                 display: flex;
//                 justify-content: space-between;
//                 width: 100%;
//                 margin: 10px 0;
//                 list-style: none;

//                 >li {
//                     width: 13.5%;
//                     height: 40px;

//                     >img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//             }

//             /*会员3 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
//     .index {
//         width: 100%;
//         // border: 100px solid red;
//     }
// }
</style>
