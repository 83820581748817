<template>
    <!-- 关于学会页面 -->
    <div class="survey">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="survey-wrap">
            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">证书查询</div>
                    <div class="survey-title-sn">Certificate Query</div>
                </div>
            </div>
            <!-- <div>
                <el-input placeholder="请输入内容" v-model="keyword">
                    <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div> -->
            <div style="margin-top: 15px;">
                <el-input placeholder="请输入内容" v-model="keyword" class="input-with-select">
                    <el-select v-model="select" slot="prepend" placeholder="全部">
                        <el-option :label="item.name" :value="item.id" v-for="(item,index) in cranList" :key="index"></el-option>
                    </el-select>
                    <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <div class="collapse">
                <div class="listDate" v-if="!searchShow">
                    <div class="topBox">
                        <div class="BoxLef">
                            <div class="four">
                                <div class="hui">证书名称</div>
                                <div class="kong"></div>
                            </div>
                            <div class="one">
                                <div class="hui">姓名</div>
                                <div class="kong"></div>
                            </div>
                            <div class="one">
                                <div class="hui">性别</div>
                                <div class="kong"></div>
                            </div>
                            <div class="one">
                                <div class="hui">学历</div>
                                <div class="kong"></div>
                            </div>
                            <div class="four">
                                <div class="hui">发证日期</div>
                                <div class="kong"></div>
                            </div>
                            <div class="four">
                                <div class="hui">到期日期</div>
                                <div class="kong"></div>
                            </div>
                            <div class="four">
                                <div class="hui">证书编号</div>
                                <div class="kong"></div>
                            </div>
                            <div class="four">
                                <div class="hui">证书编号</div>
                                <div class="kong"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- v-for="(item,index) in cradList" :key="index" :name="index" -->
                <div v-else>
                    <div class="listDate">
                        <div class="topBox">
                            <div class="BoxLef">
                                <div class="four">
                                    <div class="hui">证书名称</div>
                                    <div class="kong">{{ cradList.cardName }}</div>
                                </div>
                                <div class="one">
                                    <div class="hui">姓名</div>
                                    <div class="kong">{{ cradList.name }}</div>
                                </div>
                                <div class="one">
                                    <div class="hui">性别</div>
                                    <div class="kong">{{ cradList.gender }}</div>
                                </div>
                                <div class="one">
                                    <div class="hui">学历</div>
                                    <div class="kong">{{ cradList.education == ''?'无': cradList.education}}</div>
                                </div>
                                <div class="four">
                                    <div class="hui">发证日期</div>
                                    <div class="kong">{{ cradList.issuetime }}</div>
                                </div>
                                <div class="four">
                                    <div class="hui">到期日期</div>
                                    <div class="kong">{{ cradList.endtime }}</div>
                                </div>
                                <div class="four">
                                    <div class="hui">证书编号</div>
                                    <div class="kong">{{ cradList.number == ''?'无':cradList.number }}</div>
                                </div>
                                <div class="four" @click="show = !show">
                                    <div class="hui" v-if="show"><i class="el-icon-arrow-up"></i></div>
                                    <div class="hui" v-else><i class="el-icon-arrow-down"></i></div>
                                    <div class="kong">查看详情</div>
                                </div>
                            </div>
                        </div>
                        <div class="bomBox" v-if="searchShow && show">
                            <div class="left">
                                <img :src="cradList.image" alt="">
                            </div>
                            <div class="right">
                                <div> <span>联系方式:</span> {{ cradList.phone == ''?'无': cradList.phone}}</div>
                                <div> <span>身份证号:</span> {{ cradList.cardNum == ''?'无': cradList.cardNum }}</div>
                                <div> <span>证书分类:</span> {{ cradList.cateName == ''?'无': cradList.cateName}}</div>
                                <div> <span>毕业院校:</span> {{ cradList.school == ''?'无': cradList.school }}</div>
                                <div> <span>培训单位:</span> {{ cradList.trainunit == ''?'无': cradList.trainunit}}</div>
                                <div> <span>发证单位:</span> {{cradList.issueunit == ''?'无': cradList.issueunit}}</div>
                                <div> <span>发证时间:</span> {{cradList.issuetime == ''?'无': cradList.issuetime}}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <el-empty v-if="cradList == '' && searchShow" description="内容为空"></el-empty>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import LearnLeft from "@/components/About/LearnLeft.vue"; //关于学会左侧组件
import BranchLeft from "@/components/About/BranchLeft.vue"; //分支机构左侧组件
import AnnunciateLeft from "@/components/About/AnnunciateLeft.vue"; //通告左侧组件
import NewsLeft from "@/components/About/NewsLeft.vue"; //学会要闻左侧组件
import MemberLeft from "@/components/About/MemberLeft.vue"; //企业会员左侧组件
import Right from "@/components/Right.vue"; //关于右侧组件
import Nav from "@/components/About/nav.vue"; //关于tap切换
import Advertising from "@/components/Advertising.vue"; //广告图
import HeadNav from "@/components/Nav.vue"; //导航
import {
    abouts,
    process,
    apply,
    works,
    branchs,
    notices,
    news,
    companylist,
    cardList,
    cardCateList
} from "@/network/data";
export default {
    name: "survey",
    data() {
        return {
            searchpagesize:4,
            cranList:[],
            searchpage:1,
            input2:'',
            activeNames: ['0'],
            keyword:'',
            select:'',
            // 当前tap索引
            currentIndex: 1,
            // 关于学会tap数据
            surveyTap: [
                {
                    name: "学会概况",
                },
                {
                    name: "学会章程",
                },
                {
                    name: "学会工作",
                },
                {
                    name: "分支机构",
                },
                {
                    name: "入会申请",
                },
                {
                    name: "通知通告",
                },
                {
                    name: "学会要闻",
                },
                {
                    name: "企业会员",
                },
            ],
            // 内容区域数据
            surveyData: [
                {
                    name: "456",
                },
            ],
            // 关于学会概况数据
            aboutsDe: {},
            // 关于学会章程数据
            processDe: {},
            // 入会申请数据
            applyDe: {},
            // 学会工作数据
            worksList: {},
            // 学会工作分页数据
            worksParams: {
                page: 1,
                pageSize: 3,
            },
            // 分支机构数据
            branchsList: {},
            // 分支机构分页数据
            branchsParams: {
                page: 1,
                pageSize: 3,
            },
            // 通知通告数据
            noticesList: {},
            // 通知通告分页数据
            noticesParams: {
                page: 1,
                pageSize: 3,
            },
            // 学会要闻数据
            newsList: {},
            // 学会要闻分页数据
            newsParams: {
                page: 1,
                pageSize: 3,
            },
            // 企业会员数据
            companylistDe: {},
            // 企业会员分页数据
            companyParams: {
                page: 1,
                pageSize: 9,
            },
            cradList:[],
            show:false,
            searchShow:false
        };
    },
    computed: {},
    watch: {
        $route(to, from) {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        },
    },
    created() {
        if (JSON.stringify(this.$route.query) != "{}") {
            this.currentIndex = this.$route.query.type;
            // this.surveyTapEv(this.$route.query.type);
        }
        this.cardCateList()
        // 获取关于学会概况数据
        this.aboutsRe();
        // 获取学会章程数据
        this.processRe();
        // 获取入会申请数据
        this.applyRe();
        // 获取学会工作数据
        this.worksRe();
        // 获取分支机构数据
        this.branchsRe();
        // 获取通知通告数据
        this.noticesRe();
        // 获取学会要闻数据
        this.newsRe();
        // 获取企业会员
        this.companylistRe();
        // this.search()
    },
    methods: {
        cardCateList(){
            cardCateList().then((res)=>{
                if(res.code==1) {
                    let dataList = {
                        id:'',
                        name:'全部'
                    }
                   this.cranList = res.data
                   this.cranList.unshift(dataList);
                }  
            })
        },
        handleChange(val) {
            console.log(val);
        },
        // 搜索企业会员数据
        search() {
            this.show = false
            let data ={
                page:this.companyParams.page,
                pagesize:this.companyParams.pageSize,
                keyword:this.keyword,
                cateId:this.select
            }
            cardList(data).then((res)=>{
                if(res.code==1) {
                   this.cradList = res.data.list
                   this.searchShow = res.data.search
                   console.log(this.cradList);
                   console.log(this.searchShow);

                }  
            })
        },
        // 关于学会页面tap切换
        surveyTapEv(index) {
            // this.surveyData = [];
            this.currentIndex = index;
            console.log(this.currentIndex, "企业会员数据");
            if (this.currentIndex == 0) {
                this.aboutsRe();
            } else if (this.currentIndex == 1) {
                this.processRe();
            } else if (this.currentIndex == 2) {
                this.worksRe();
            } else if (this.currentIndex == 3) {
                this.branchsRe();
            } else if (this.currentIndex == 4) {
                this.applyRe();
            } else if (this.currentIndex == 5) {
                this.noticesRe();
            } else if (this.currentIndex == 6) {
                this.newsRe();
            } else if (this.currentIndex == 7) {
                this.companylistRe();
            }
        },
        // 获取关于学会概况数据
        aboutsRe() {
            abouts().then((res) => {
                if (res.code == 1) {
                    this.aboutsDe = res.data;
                } else {
                    this.$message(res.msg);
                }
            });
        },
        // 获取关于学会章程数据
        processRe() {
            process().then((res) => {
                if (res.code == 1) {
                    this.processDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取入会申请数据
        applyRe() {
            apply().then((res) => {
                if (res.code == 1) {
                    this.applyDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取学会工作数据
        worksRe() {
            let data = {
                menuid: 4,
                page: this.worksParams.page,
                pagesize: this.worksParams.pageSize,
            };
            works(data).then((res) => {
                if (res.code == 1) {
                    this.worksList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取分支机构数据
        branchsRe() {
            let data = {
                menuid: 5,
                page: this.branchsParams.page,
                pagesize: this.branchsParams.pageSize,
            };
            branchs(data).then((res) => {
                if (res.code == 1) {
                    this.branchsList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取通知通告数据
        noticesRe() {
            let data = {
                menuid: 7,
                page: this.noticesParams.page,
                pagesize: this.noticesParams.pageSize,
            };
            notices(data).then((res) => {
                if (res.code == 1) {
                    this.noticesList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取学会要闻数据
        newsRe() {
            let data = {
                menuid: 8,
                page: this.newsParams.page,
                pagesize: this.newsParams.pageSize,
            };
            news(data).then((res) => {
                if (res.code == 1) {
                    // console.log(res.data);
                    this.newsList = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },

        //获取企业会员数据
        companylistRe() {
            let data = {
                page: this.companyParams.page,
                pagesize: this.companyParams.pageSize,
            };
            companylist(data).then((res) => {
                if (res.code == 1) {
                    this.companylistDe = res.data;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 学会工作子组件分页事件
        worksPage(page) {
            this.worksParams.page = page;
            this.worksRe();
        },
        // 分支机构子组件分页事件
        branchsPage(page) {
            this.branchsParams.page = page;
            this.branchsRe();
        },
        // 通知通告子组件分页事件
        noticesPage(page) {
            this.noticesParams.page = page;
            this.noticesRe();
        },
        // 学会要闻子组件分页事件
        newsPage(page) {
            this.newsParams.page = page;
            this.newsRe();
        },
        // 企业会员子组件分页事件
        companyPage(page) {
            this.companyParams.page = page;
            this.companylistRe();
            this.search()
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        LearnLeft,
        BranchLeft,
        AnnunciateLeft,
        NewsLeft,
        Right,
        Nav,
        Advertising,
        MemberLeft,
        HeadNav,
    },
};
</script>

<style lang="scss" scoped>
.listDate{
    // box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    margin-bottom: 20px;
    .topBox{
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dcdcdc;
        border-left: 1px solid #dcdcdc;
    }

    .BoxLef{
        display: flex;
        font-size: 15px;
        justify-content: space-between;
        text-align: center;
        width: 100%;
        .one{
            border-right: 1px solid #dcdcdc;
            width: 10%;
        }
        .four{
            border-right: 1px solid #dcdcdc;

            width: 17.5%;
        }
        .hui{
            background-color: #f1f1f1;
            padding: 10px;
            box-sizing: border-box;
        }
        .kong{
            padding: 10px;
            box-sizing: border-box;
        }
    }
    .bomBox{
        display: flex;
        .left{
            width: 50%;
            margin: 15px 0;
            img{
                width: 80%;
                height: 450px;
            }
        }
        .right{
            width: 50%;
            margin: 15px 0;
            font-size: 15px;
            span{
                font-size: 17px;
                color: #000;
                margin-right: 5px;
                font-weight: bold;
            }
            div{
                margin-bottom: 25px;
            }
        }
    }
    .BoxRight{
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 15px;
        width: 15%;
        color: #0056b0;
        justify-content: flex-end;
    }
}
.listP{
    display: flex;
    justify-content: space-between;
    width: 85%;

}
    ::v-deep  .el-input input{
        height: 50px!important;
    }
  ::v-deep .el-input-group__append, .el-input-group__prepend{
    background-color: #0056b0!important;
    color: #fff!important;
  }
  ::v-deep .el-collapse-item__content{
    padding: 20px!important;
    box-sizing: border-box!important;
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-around;
  }
  ::v-deep .el-collapse-item__header{
    font-size: 18px;
    padding-left: 15px;
    box-sizing: border-box;
    font-weight: bold;

  }
  ::v-deep .el-input__icon{
    line-height: 50px!important;
  }
  ::v-deep .el-select .el-input {
    width: 230px;
  }
  ::v-deep .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .collapse{
    margin-top: 30px;
  }
</style>
