<template>
    <!-- 产品需求---招聘详情页 -->
    <div class="demandInviteDetail">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="minkave-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>
            <!-- <div class="index-headImg">
                <div class="index-headImg-left">
                    <img src="../../../assets/pc/Index/headL.png" alt="" />
                </div>
                <div class="index-headImg-right">
                    <img src="../../../assets/pc/Index/headR.png" alt="" />
                </div>
            </div> -->

            <!-- 关于学会tap -->
            <div class="survey-nav">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">产业需求</div>
                    <!-- <div class="survey-title-sn">About the Institute</div> -->
                </div>

                <div style="font-size: 30px">就业招聘详情页</div>
                <!-- <ul>
                    <li
                        :key="index"
                        v-for="(item, index) in machiningTap"
                        @click="machiningTapEv(index)"
                        :class="currentIndex == index ? 'active' : ''"
                    >
                        {{ item.name }}
                    </li>
                </ul> -->
            </div>

            <!-- 内容区域 -->
            <div class="survey-cont">
                <div class="survey-content">
                    <!-- 内容区域左侧 -->
                    <!-- 技术难题 -->
                    <div class="survey-content-left">
                        <div class="survey-detail-left">
                            <!-- 左侧 -->
                            <div class="minkaveDetails-introduce5-left">
                                <!-- 技术难题标题 -->
                                <div class="minkaveDetails-introduce5-title">
                                    <div>
                                        <div></div>
                                        <span>技术难题</span>
                                    </div>
                                    <ul>
                                        <li
                                            v-for="(
                                                itemb, indexb
                                            ) in jobcateList"
                                            :key="indexb"
                                            :class="
                                                currentPuzzle == indexb
                                                    ? 'puzzleActive'
                                                    : ''
                                            "
                                        >
                                            {{ itemb.name }}
                                        </li>
                                    </ul>
                                </div>
                                <!-- 相关技术难题推荐 -->
                                <div class="minkaveDetails-introduce5-title2">
                                    <div class="">相关技术难题推荐</div>
                                    <ul>
                                        <li
                                            v-for="(itemc, indexc) in recommend"
                                            :key="indexc"
                                        >
                                            <div>{{ itemc.title }}</div>
                                            <div>
                                                状态:{{ itemc.recommend }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="survey-detail-right">
                            <div class="detail-right">
                                <div class="detail-right-place">
                                    当前位置：网站首页>产业需求>就业招聘>招聘
                                </div>
                                <div class="detail-right-cont">
                                    <div>{{ jobdetailObj.title }}</div>
                                    <div>
                                        {{ jobdetailObj.createtime }} 更新
                                    </div>
                                    <div v-if="jobdetailObj.salarymoney">
                                        {{ jobdetailObj.salarymoney }}/月
                                    </div>
                                    <div
                                        v-if="
                                            ity &&
                                            jobdetailObj.workyear &&
                                            jobdetailObj.education
                                        "
                                    >
                                        {{ city }} |
                                        {{ jobdetailObj.workyear }} |
                                        {{ jobdetailObj.education }}
                                    </div>
                                    <div>职位详情</div>
                                    <div>
                                        <ul v-html="jobdetailObj.content"></ul>
                                    </div>
                                    <div>工作地址</div>
                                    <div>{{ jobdetailObj.address }}</div>
                                    <div>
                                        <div
                                            @click="obtain"
                                            v-if="companyType == 1"
                                        >
                                            就业发布
                                        </div>
                                        <div
                                            @click="bring"
                                            v-if="companyType == 2"
                                        >
                                            招聘发布
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 内容区域右侧 -->
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //右侧
import HeadNav from "@/components/Nav.vue"; //导航
import { jobdetail, jobcate } from "../../../network/data";
import Advertising from "@/components/Advertising.vue"; //广告图
// import Left3 from "@/components/Science/Left3.vue"; //左侧

export default {
    // 组件名称
    name: "demand",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 存储企业会员登录返回的id
            memberId: "",
            // 招聘详情页id
            id: "",
            // 当前tap索引
            // currentIndex: 2,
            // 登录返回,判断是否能发布招聘信息，1==不可以，2==可以
            companyType: "",
            // 技术难题左侧tap索引
            currentPuzzle: 0,

            // 就业招聘职位方向
            jobcateList: [],
            // 详情页返回的分类id
            puzzleId: "",
            // 难题推荐列表
            recommend: [],
            // 就业招聘详情
            jobdetailObj: {},
            // 城市
            city: "",
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        if (this.$route.query) {
            this.id = this.$route.query.id;
            this.jobdetailRe();
        }

        // 存储企业会员登录返回的id
        this.memberId = sessionStorage.getItem("id");
        // 判断是否能发布招聘信息，1==不可以，2==可以
        this.companyType = sessionStorage.getItem("companyType");
    },
    methods: {
        machiningTapEv(index) {
            // this.surveyData = [];
            this.currentIndex = index;
        },

        // 技术难题分页点击事件
        pageChange2() {
            conosle.log("暂未设置");
        },

        // 技术难题左侧tap点击事件
        // puzzleEv(item, index) {
        //     this.currentPuzzle = index;
        //     this.puzzleId = item.id;
        // },
        // 获取招聘详情页数据
        jobdetailRe() {
            let data = {
                id: this.id,
            };
            jobdetail(data).then((res) => {
                if (res.code == 1) {
                    var reg = /.+?(省|市|自治区|自治州|县|区)/g;
                    this.recommend = res.data.list;
                    this.jobdetailObj = res.data.info;
                    this.puzzleId = res.data.info.cate_id;
                    this.city = res.data.info.address.match(reg)[1];
                    this.jobcateRe();
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 获取招聘职位方向
        jobcateRe() {
            jobcate().then((res) => {
                if (res.code == 1) {
                    this.jobcateList = res.data;
                    // 判断详情页返回的分类id在职位方向列表中的位置
                    let index = this.jobcateList.findIndex((item) => {
                        return item.id == this.puzzleId;
                    });
                    this.currentPuzzle = index;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 跳转到招聘发布
        bring() {
            // 判断是否登录
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: "/science/recruit",
                    query: {
                        indexa: 1,
                        lable_id: this.jobdetailObj.cate_id,
                    },
                });
            }
        },
        // 跳转到就业发布
        obtain() {
            // 判断是否登录
            if (!sessionStorage.getItem("token")) {
                this.$router.push({
                    path: "/login",
                });
            } else {
                this.$router.push({
                    path: "/science/recruit",
                    query: {
                        indexa: 0,
                        lable_id: this.jobdetailObj.cate_id,
                    },
                });
            }
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Header,
        Footer,
        Right,
        Advertising,
        // Left3,
        HeadNav,
    },
};
</script>

<style lang="scss">
// .demandInviteDetail {
//     width: 100%;

//     .minkave-wrap {
//         width: 70%;
//         // height: 1000px;
//         margin: 0 auto;
//         // margin-top: 35px;
//         margin-bottom: 40px;
//         // border: 1px solid red;

//         /*顶部图片start*/
//         .index-headImg {
//             position: relative;
//             z-index: 0;
//             width: 100%;
//             height: 50px;
//             margin-top: 15px;
//             display: flex;
//             justify-content: space-between;

//             // 左侧图片
//             .index-headImg-left {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }

//             // 右侧图片
//             .index-headImg-right {
//                 width: 48%;
//                 height: 100%;

//                 img {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//         /*顶部图片end*/

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 0.5px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 20%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;

//                 .survey-title-cn {
//                     font-size: 10px;
//                     color: #0056b0;
//                     margin-right: 15px;
//                 }

//                 .survey-title-sn {
//                     height: 20px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     background-color: #0056b0;
//                 }
//             }

//             // > ul {
//             //     width: 80%;
//             //     list-style: none;
//             //     display: flex;
//             //     border: 1px solid red;

//             //     .active {
//             //         color: #0068d5;
//             //     }

//             //     li {
//             //         cursor: pointer;
//             //         margin-left: 10px;
//             //         padding-left: 10px;
//             //         font-size: 10px;
//             //         border-left: 1px solid #e9e9e9;
//             //     }
//             // }
//         }
//         /*关于学会tap end*/

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin-top: 10px;
//                 // height: 300px;
//                 // border: 1px solid red;

//                 // 内容区域左侧1(技术难题)
//                 .survey-content-left {
//                     display: flex;
//                     width: 68%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     .survey-detail-left {
//                         width: 26%;
//                         // border: 1px solid red;

//                         // 详情左侧内容
//                         .minkaveDetails-introduce5-left {
//                             width: 100%;
//                             // height: 216px;
//                             // border: 1px solid red;

//                             // 技术难题
//                             .minkaveDetails-introduce5-title {
//                                 > div:nth-child(1) {
//                                     display: flex;
//                                     align-items: center;
//                                     height: 30px;
//                                     line-height: 30px;
//                                     padding: 10px;
//                                     box-sizing: border-box;
//                                     // border: 1px solid red;
//                                     background-color: #0056b0;

//                                     > div:nth-child(1) {
//                                         width: 1.5px;
//                                         height: 14px;
//                                         background: #fff;
//                                     }

//                                     > span {
//                                         margin-top: -1.5px;
//                                         margin-left: 8px;
//                                         font-size: 11px;
//                                         color: #fff;
//                                     }
//                                 }

//                                 > ul {
//                                     list-style: none;

//                                     .puzzleActive {
//                                         color: #fff;
//                                         background-color: #8fc320;
//                                     }

//                                     li {
//                                         text-align: center;
//                                         height: 30px;
//                                         line-height: 30px;
//                                         border-bottom: 1px solid #f2f2f2;
//                                         letter-spacing: 1px;
//                                         font-size: 10px;
//                                     }
//                                 }
//                             }

//                             // 技术难题推荐
//                             .minkaveDetails-introduce5-title2 {
//                                 margin-top: 30px;
//                                 > div:nth-child(1) {
//                                     font-size: 11px;
//                                 }

//                                 > ul {
//                                     list-style: none;

//                                     li {
//                                         margin-top: 10px;
//                                         padding: 5px;
//                                         box-sizing: border-box;
//                                         border-radius: 4px;
//                                         font-size: 12px;
//                                         background-color: #f8f8f8;

//                                         > div:nth-child(1) {
//                                             font-size: 10px;
//                                             white-space: nowrap;
//                                             overflow: hidden;
//                                             text-overflow: ellipsis;
//                                         }

//                                         > div:nth-child(2) {
//                                             margin-top: 4px;
//                                             font-size: 8px;
//                                             color: #9f9f9f;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 详情右侧内容
//                     .survey-detail-right {
//                         width: 70%;
//                         margin-left: auto;
//                         // border: 1px solid red;

//                         .detail-right {
//                             width: 100%;
//                             font-size: 12px;
//                             background-color: #f8f8f8;

//                             .detail-right-place {
//                                 font-size: 10px;
//                                 padding: 5px;
//                                 box-sizing: border-box;
//                                 border-bottom: 0.5px solid #e6e6e6;
//                             }

//                             .detail-right-cont {
//                                 padding: 20px 10px;
//                                 font-size: 10px;

//                                 > div:nth-child(1) {
//                                     font-size: 20px;
//                                     font-weight: 700;
//                                 }
//                                 > div:nth-child(2) {
//                                     margin: 7px 0;
//                                     font-size: 9px;
//                                 }

//                                 > div:nth-child(3) {
//                                     font-size: 15px;
//                                     font-weight: 700;
//                                     // color: red;
//                                 }

//                                 > div:nth-child(4) {
//                                     font-size: 9px;
//                                     margin: 7px 0;
//                                 }

//                                 > div:nth-child(5) {
//                                     font-size: 15px;
//                                     margin: 20px 0 5px 0;
//                                 }

//                                 > div:nth-child(6) {
//                                     font-size: 9px;

//                                     > ul {
//                                         list-style: none;

//                                         > li:nth-child(1) {
//                                             font-size: 11px;
//                                             margin-bottom: 7px;
//                                         }
//                                         li {
//                                             margin: 7px 0;
//                                         }
//                                     }
//                                 }

//                                 > div:nth-child(7) {
//                                     font-size: 15px;
//                                     margin-top: 20px;
//                                 }

//                                 > div:nth-child(8) {
//                                     font-size: 9px;
//                                     margin-top: 7px;
//                                 }

//                                 > div:last-child {
//                                     display: flex;
//                                     margin: 20px 0;
//                                     // border: 1px solid red;

//                                     > div:nth-child(1) {
//                                         font-size: 9px;
//                                         color: #fff;
//                                         padding: 3px 8px;
//                                         background-color: #40509e;
//                                         // border: 1px solid red;
//                                     }

//                                     > div:nth-child(2) {
//                                         margin-left: 30px;
//                                         font-size: 9px;
//                                         color: #fff;
//                                         padding: 3px 8px;
//                                         background-color: #f9653f;
//                                         // border: 1px solid red;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 30%;
//                     // border: 1px solid red;
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .demandInviteDetail {
//         width: 100%;

//         .minkave-wrap {
//             width: 70%;
//             // height: 1000px;
//             margin: 0 auto;
//             // margin-top: 35px;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 0.5px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 20%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 15px;
//                     }

//                     .survey-title-sn {
//                         height: 20px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                     }
//                 }

//                 // > ul {
//                 //     width: 80%;
//                 //     list-style: none;
//                 //     display: flex;
//                 //     border: 1px solid red;

//                 //     .active {
//                 //         color: #0068d5;
//                 //     }

//                 //     li {
//                 //         cursor: pointer;
//                 //         margin-left: 10px;
//                 //         padding-left: 10px;
//                 //         font-size: 10px;
//                 //         border-left: 1px solid #e9e9e9;
//                 //     }
//                 // }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin-top: 10px;
//                     // height: 300px;
//                     // border: 1px solid red;

//                     // 内容区域左侧1(技术难题)
//                     .survey-content-left {
//                         display: flex;
//                         width: 68%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         .survey-detail-left {
//                             width: 26%;
//                             // border: 1px solid red;

//                             // 详情左侧内容
//                             .minkaveDetails-introduce5-left {
//                                 width: 100%;
//                                 // height: 216px;
//                                 // border: 1px solid red;

//                                 // 技术难题
//                                 .minkaveDetails-introduce5-title {
//                                     > div:nth-child(1) {
//                                         display: flex;
//                                         align-items: center;
//                                         height: 25px;
//                                         line-height: 25px;
//                                         padding: 10px;
//                                         box-sizing: border-box;
//                                         // border: 1px solid red;
//                                         background-color: #0056b0;

//                                         > div:nth-child(1) {
//                                             width: 1.5px;
//                                             height: 10px;
//                                             background: #fff;
//                                         }

//                                         > span {
//                                             margin-top: -1.5px;
//                                             margin-left: 4px;
//                                             font-size: 10px;
//                                             color: #fff;
//                                         }
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         .puzzleActive {
//                                             color: #fff;
//                                             background-color: #8fc320;
//                                         }

//                                         li {
//                                             text-align: center;
//                                             height: 25px;
//                                             line-height: 25px;
//                                             border-bottom: 1px solid #f2f2f2;
//                                             letter-spacing: 1px;
//                                             font-size: 8px;
//                                         }
//                                     }
//                                 }

//                                 // 技术难题推荐
//                                 .minkaveDetails-introduce5-title2 {
//                                     margin-top: 30px;
//                                     > div:nth-child(1) {
//                                         font-size: 10px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         li {
//                                             margin-top: 5px;
//                                             padding: 5px;
//                                             box-sizing: border-box;
//                                             border-radius: 4px;
//                                             background-color: #f8f8f8;

//                                             > div:nth-child(1) {
//                                                 font-size: 8px;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                             }

//                                             > div:nth-child(2) {
//                                                 margin-top: 4px;
//                                                 font-size: 7px;
//                                                 color: #9f9f9f;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 详情右侧内容
//                         .survey-detail-right {
//                             width: 70%;
//                             margin-left: auto;
//                             // border: 1px solid red;

//                             .detail-right {
//                                 width: 100%;
//                                 font-size: 12px;
//                                 background-color: #f8f8f8;

//                                 .detail-right-place {
//                                     font-size: 10px;
//                                     padding: 5px;
//                                     box-sizing: border-box;
//                                     border-bottom: 0.5px solid #e6e6e6;
//                                 }

//                                 .detail-right-cont {
//                                     padding: 20px 10px;
//                                     font-size: 10px;

//                                     > div:nth-child(1) {
//                                         font-size: 20px;
//                                         font-weight: 700;
//                                     }
//                                     > div:nth-child(2) {
//                                         margin: 7px 0;
//                                         font-size: 9px;
//                                     }

//                                     > div:nth-child(3) {
//                                         font-size: 15px;
//                                         font-weight: 700;
//                                         // color: red;
//                                     }

//                                     > div:nth-child(4) {
//                                         font-size: 9px;
//                                         margin: 7px 0;
//                                     }

//                                     > div:nth-child(5) {
//                                         font-size: 15px;
//                                         margin: 20px 0 5px 0;
//                                     }

//                                     > div:nth-child(6) {
//                                         font-size: 9px;

//                                         > ul {
//                                             list-style: none;

//                                             > li:nth-child(1) {
//                                                 font-size: 11px;
//                                                 margin-bottom: 7px;
//                                             }
//                                             li {
//                                                 margin: 7px 0;
//                                             }
//                                         }
//                                     }

//                                     > div:nth-child(7) {
//                                         font-size: 15px;
//                                         margin-top: 20px;
//                                     }

//                                     > div:nth-child(8) {
//                                         font-size: 9px;
//                                         margin-top: 7px;
//                                     }

//                                     > div:last-child {
//                                         display: flex;
//                                         margin: 20px 0;
//                                         // border: 1px solid red;

//                                         > div:nth-child(1) {
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #40509e;
//                                             // border: 1px solid red;
//                                         }

//                                         > div:nth-child(2) {
//                                             margin-left: 30px;
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #f9653f;
//                                             // border: 1px solid red;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .demandInviteDetail {
//         width: 100%;

//         .minkave-wrap {
//             width: 80%;
//             // height: 1000px;
//             margin: 0 auto;
//             // margin-top: 35px;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             /*顶部图片start*/
//             .index-headImg {
//                 position: relative;
//                 z-index: 0;
//                 width: 100%;
//                 height: 50px;
//                 margin-top: 15px;
//                 display: flex;
//                 justify-content: space-between;

//                 // 左侧图片
//                 .index-headImg-left {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }

//                 // 右侧图片
//                 .index-headImg-right {
//                     width: 48%;
//                     height: 100%;

//                     img {
//                         width: 100%;
//                         height: 100%;
//                     }
//                 }
//             }
//             /*顶部图片end*/

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 20%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         font-size: 10px;
//                         color: #0056b0;
//                         margin-right: 15px;
//                     }

//                     .survey-title-sn {
//                         height: 20px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                     }
//                 }

//                 // > ul {
//                 //     width: 80%;
//                 //     list-style: none;
//                 //     display: flex;
//                 //     border: 1px solid red;

//                 //     .active {
//                 //         color: #0068d5;
//                 //     }

//                 //     li {
//                 //         cursor: pointer;
//                 //         margin-left: 10px;
//                 //         padding-left: 10px;
//                 //         font-size: 10px;
//                 //         border-left: 1px solid #e9e9e9;
//                 //     }
//                 // }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     // height: 300px;
//                     // border: 1px solid red;

//                     // 内容区域左侧1(技术难题)
//                     .survey-content-left {
//                         display: flex;
//                         width: 68%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         .survey-detail-left {
//                             width: 26%;
//                             // border: 1px solid red;

//                             // 详情左侧内容
//                             .minkaveDetails-introduce5-left {
//                                 width: 100%;
//                                 // height: 216px;
//                                 // border: 1px solid red;

//                                 // 技术难题
//                                 .minkaveDetails-introduce5-title {
//                                     > div:nth-child(1) {
//                                         display: flex;
//                                         align-items: center;
//                                         height: 30px;
//                                         line-height: 30px;
//                                         padding: 10px;
//                                         box-sizing: border-box;
//                                         // border: 1px solid red;
//                                         background-color: #0056b0;

//                                         > div:nth-child(1) {
//                                             width: 1.5px;
//                                             height: 10px;
//                                             background: #fff;
//                                         }

//                                         > span {
//                                             margin-top: -1.5px;
//                                             margin-left: 4px;
//                                             font-size: 10px;
//                                             color: #fff;
//                                         }
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         .puzzleActive {
//                                             color: #fff;
//                                             background-color: #8fc320;
//                                         }

//                                         li {
//                                             text-align: center;
//                                             height: 20px;
//                                             line-height: 20px;
//                                             border-bottom: 1px solid #f2f2f2;
//                                             letter-spacing: 1px;
//                                             font-size: 6px;
//                                         }
//                                     }
//                                 }

//                                 // 技术难题推荐
//                                 .minkaveDetails-introduce5-title2 {
//                                     margin-top: 15px;
//                                     > div:nth-child(1) {
//                                         font-size: 9px;
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     > ul {
//                                         list-style: none;

//                                         li {
//                                             margin-top: 3px;
//                                             padding: 5px;
//                                             box-sizing: border-box;
//                                             border-radius: 4px;
//                                             font-size: 8px;
//                                             background-color: #f8f8f8;

//                                             > div:nth-child(1) {
//                                                 // font-size: 11px;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                             }

//                                             > div:nth-child(2) {
//                                                 margin-top: 4px;
//                                                 // font-size: 9px;
//                                                 color: #9f9f9f;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }

//                         // 详情右侧内容
//                         .survey-detail-right {
//                             width: 70%;
//                             margin-left: auto;
//                             // border: 1px solid red;

//                             .detail-right {
//                                 width: 100%;
//                                 font-size: 12px;
//                                 background-color: #f8f8f8;

//                                 .detail-right-place {
//                                     font-size: 8px;
//                                     padding: 5px;
//                                     box-sizing: border-box;
//                                     border-bottom: 1px solid silver;
//                                 }

//                                 .detail-right-cont {
//                                     padding: 10px 10px;
//                                     font-size: 10px;

//                                     > div:nth-child(1) {
//                                         font-size: 20px;
//                                         font-weight: 700;
//                                     }
//                                     > div:nth-child(2) {
//                                         margin: 5px 0;
//                                         font-size: 9px;
//                                         color: #999999;
//                                     }

//                                     > div:nth-child(3) {
//                                         font-size: 15px;
//                                         font-weight: 700;
//                                         color: red;
//                                     }

//                                     > div:nth-child(4) {
//                                         font-size: 9px;
//                                         margin: 5px 0;
//                                     }

//                                     > div:nth-child(5) {
//                                         font-size: 15px;
//                                         margin: 10px 0 5px 0;
//                                     }

//                                     > div:nth-child(6) {
//                                         font-size: 9px;

//                                         > ul {
//                                             list-style: none;

//                                             > li:nth-child(1) {
//                                                 font-size: 11px;
//                                                 margin-bottom: 5px;
//                                             }
//                                             li {
//                                                 margin: 5px 0;
//                                                 white-space: nowrap;
//                                                 overflow: hidden;
//                                                 text-overflow: ellipsis;
//                                             }
//                                         }
//                                     }

//                                     > div:nth-child(7) {
//                                         font-size: 15px;
//                                         margin-top: 15px;
//                                     }

//                                     > div:nth-child(8) {
//                                         font-size: 9px;
//                                         margin-top: 2px;
//                                     }

//                                     > div:last-child {
//                                         display: flex;
//                                         margin: 20px 0;
//                                         // border: 1px solid red;

//                                         > div:nth-child(1) {
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #40509e;
//                                             // border: 1px solid red;
//                                         }

//                                         > div:nth-child(2) {
//                                             margin-left: 30px;
//                                             font-size: 9px;
//                                             color: #fff;
//                                             padding: 3px 8px;
//                                             background-color: #f9653f;
//                                             // border: 1px solid red;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
