import { render, staticRenderFns } from "./Demeanour.vue?vue&type=template&id=41098793&scoped=true&"
import script from "./Demeanour.vue?vue&type=script&lang=js&"
export * from "./Demeanour.vue?vue&type=script&lang=js&"
import style0 from "./Demeanour.vue?vue&type=style&index=0&id=41098793&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41098793",
  null
  
)

export default component.exports