<template>
    <!-- 关于学会页面 -->
    <div class="survey">
        <Mheader :currentIndex="4"></Mheader>
        <div class="survey-wrap">
            <div>当前位置：{{tab}}</div>
            <div class="survey-cont">
                <div class="survey-content">
                    <div class="survey-content-left">
                        <div class="survey-left-title">
                            <div>{{newsDetail.title}}</br>
                               {{newsDetail.subtitle}}
                            </div>
                            <div>
                                时间：{{newsDetail.createtime }} 来源：{{newsDetail.source}} 作者：{{ newsDetail.author }} 原文：
                            </div>
                        </div>

                        <div class="survey-left-box" v-html="newsDetail.content"></div>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "@/components/Mheader.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //关于右侧组件
import Nav from "@/components/About/nav.vue"; //关于tap切换
import { companyNewdetail } from '../../../network/data';
export default {
    name: "survey",
    data() {
        return {
            // 新闻详情页数据
            newsDetail:{},
            // 当前位置
            tab:'',
            // 新闻id
            id:'',
            // 导航栏id
            menu_id:''
        };
    },
    computed: {},
    watch: {
        // $route(to, from) {
        //     window.location.reload(); //监测到路由发生跳转时刷新一次页面
        // },
    },
    created(){
        if (this.$route.query != "") {
            this.menu_id = this.$route.query.menu_id;
            this.id = this.$route.query.id;

            // 获取新闻详情页数据
            this.companyNewdetailRe()
        }

        
    },  
    methods: {
        // 新闻详情页
        companyNewdetailRe() {
            let data={
                id:this.id,
                menuid:this.menu_id
            }
            companyNewdetail(data).then((res) => {
               if (res.code == 1) {
                  this.tab=res.data.tab
                  this.newsDetail=res.data.detail
               } else {
                  this.message(res.msg);
               }
           });
        },
    },
    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Mheader,
        Footer,
        Right,
        Nav,
    },
};
</script>

<style lang="scss">

// .survey {
//     width: 100%;
//     .survey-wrap {
//         width: 70%;
//         // height: 900px;
//         margin: 0 auto;
//         margin-bottom: 40px;
//         // border: 1px solid red;

//         >div:nth-child(1){
//             margin: 10px 0 20px 0;
//             font-size: 8px;
//             color: #999999;
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;
//         }

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin: 0 auto;
//                 // border: 1px solid red;

//                 // 内容区域左侧
//                 .survey-content-left {
//                     width: 72%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     // 头部标题
//                     .survey-left-title {
//                         padding:3px;
//                         border-bottom:1px solid #CACACA;

//                         >div:nth-child(1){
//                             font-size:12px;
//                             white-space: nowrap;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }

//                         >div:nth-child(2){
//                             margin-top:3px;
//                             font-size:9px;
//                             color:#999999;
//                             white-space: nowrap;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }
//                     }

//                     // 左侧内容
//                     .survey-left-box{
//                         width:100%;
//                         // height:300px;
//                         font-size:10px;
//                         margin-top:20px;
//                         margin-bottom: 40px;;
//                         // border:1px solid red;

//                         img{
//                             display: block;
//                             width:100%;
//                             height:100%;
//                         }
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 25%;
//                     // border: 1px solid red;

                   
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width:1200px) and (max-width: 1600px) {
//     .survey {
//     width: 100%;
//     .survey-wrap {
//         width: 70%;
//         // height: 900px;
//         margin: 0 auto;
//         margin-bottom: 40px;;
//         // border: 1px solid red;

//         >div:nth-child(1){
//             margin: 10px 0 20px 0;
//             font-size: 8px;
//             color: #999999;
//         }

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin: 0 auto;
//                 // border: 1px solid red;

//                 // 内容区域左侧
//                 .survey-content-left {
//                     width: 72%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     // 头部标题
//                     .survey-left-title {
//                         padding:3px;
//                         border-bottom:1px solid #CACACA;

//                         >div:nth-child(1){
//                             font-size:12px;
//                             white-space: nowrap;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }

//                         >div:nth-child(2){
//                             margin-top:3px;
//                             font-size:9px;
//                             color:#999999;
//                             white-space: nowrap;
//                             overflow: hidden;
//                             text-overflow: ellipsis;
//                         }
//                     }

//                     // 左侧内容
//                     .survey-left-box{
//                         width:100%;
//                         // height:300px;
//                         font-size:10px;
//                         margin-top:10px;
//                         margin-bottom: 40px;
//                         // border:1px solid red;
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 25%;
//                     // border: 1px solid red;
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .survey {
//     width: 100%;
//     .survey-wrap {
//         width: 80%;
//         // height: 635px;
//         margin: 0 auto;
//         margin-bottom: 40px;
//         // border: 1px solid red;

//         >div:nth-child(1){
//             margin: 10px 0 20px 0;
//             font-size: 8px;
//             color: #999999;
//         }

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;
//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin: 0 auto;
//                 // border: 1px solid red;

//                 // 内容区域左侧
//                 .survey-content-left {
//                     width: 72%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     // 头部标题
//                     .survey-left-title {
//                         padding:3px;
//                         border-bottom:1px solid #CACACA;

//                         >div:nth-child(1){
//                             font-size:12px;
//                         }

//                         >div:nth-child(2){
//                             margin-top:3px;
//                             font-size:9px;
//                             color:#999999;
//                         }
//                     }

//                     // 左侧内容
//                     .survey-left-box{
//                         width:100%;
//                         // height:300px;
//                         margin-top:10px;
//                         // border:1px solid red;
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 25%;
//                     // border: 1px solid red;
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
