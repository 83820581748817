import { render, staticRenderFns } from "./SpecialDetail.vue?vue&type=template&id=06a8ab75&scoped=true&"
import script from "./SpecialDetail.vue?vue&type=script&lang=js&"
export * from "./SpecialDetail.vue?vue&type=script&lang=js&"
import style0 from "./SpecialDetail.vue?vue&type=style&index=0&id=06a8ab75&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a8ab75",
  null
  
)

export default component.exports