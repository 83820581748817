<template>
    <!-- 食品咨询 -->
    <div class="consult">
        <Header></Header>
        <HeadNav></HeadNav>
        <div class="prefabricate-wrap">
            <!-- 顶部图片 -->
            <Advertising></Advertising>

            <!-- 关于学会tap -->
            <div class="survey-nav" v-if="type == 1">
                <!-- <Nav></Nav> -->
                <div class="survey-nav-title">
                    <div class="survey-title-cn">食品咨询</div>
                    <div class="survey-title-sn">consult</div>
                </div>
                <ul>
                    <li :key="index" v-for="(item, index) in navListw"  
                        @click="observeTapEv(item, index)"
                        :class="currentIndex == index ? 'active' : ''">{{ item.name }}</li>
                </ul>
            </div>

            <!-- 内容区域,包含学会概况、学会章程、入会申请、通知通告、学会要闻、企业会员 -->
            <div class="survey-cont">
                <div class="survey-content" v-if="type == 1">
                    <div class="survey-content-left">
                        <ul>
                            <!-- 左侧内容区域列表 -->
                            <li
                                :key="indexd"
                                v-for="(itemd, indexd) in foodnewsListDe.data"
                                @click="goCousultDetail(itemd.id)"
                            >
                                <div class="survey-item-img">
                                    <img
                                        :src="itemd.image"
                                        alt=""
                                    />
                                </div>
                                <div class="survey-item-text">
                                    <div class="survey-item-text1">
                                        {{ itemd.title }}
                                    </div>
                                    <div class="survey-item-text2">
                                        {{ itemd.description }}
                                    </div>
                                    <div class="survey-item-text3">
                                        时间：{{ itemd.createtime }}
                                    </div>
                                </div>
                            </li>

                            <!-- 左侧内容区域分页 -->
                            <div class="pageDown">
                                <Pagination
                                    v-if="JSON.stringify(foodnewsListDe) != ''"
                                    :pageNo="foodnewsParams.page"
                                    :pageSize="foodnewsParams.pageSize"
                                    :total="total"
                                    :continues="5"
                                    @getPageNo="getPageEv"
                                />
                            </div>
                        </ul>
                    </div>
                    <div class="survey-content-right">
                        <Right></Right>
                    </div>
                </div>
                <div class="search-list" v-else>
                    <ul v-if="searlist.length!=0">
                        <li :key="index" v-for="(item, index) in searlist">
                            <div class="introduces-item-left">
                                <img :src="item.image" />
                            </div>
                            <div class="introduces-item-right">
                                <div>{{ item.title }}</div>
                                <div>{{ item.source }}</div>
                                <div @click="member(item)">查看详情</div>
                            </div>
                        </li>
                    </ul>
                    <div class="search-none" v-else>
                        <img src="../../assets/pc/Index/lack.png"></img>
                    </div>
                </div>
            </div>
            <!-- 内容区域 -->
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import HeadNav from "@/components/Nav.vue"; //导航
import Header from "@/components/Header.vue"; //头部
import Footer from "@/components/Footer.vue"; //尾部
import Right from "@/components/Right.vue"; //关于右侧组件
import Pagination from "@/components/Pagination"; //分页组件
import Advertising from "@/components/Advertising.vue"; //广告图
import { foodnewlist, search,navlist} from "../../network/data";
export default {
    name: "consult",
    data() {
        return {
            // 食品咨询列表数据
            foodnewsListDe: {},
            // 分页
            foodnewsParams: {
                page: 1,
                pageSize: 3,
            },
            total: 10,
            // 判断食品咨询列表还是首页搜索列表type==1食品咨询列表,type==-1搜索列表
            type:'1',
            // 首页搜索关键字
            keyword: "",
            // 搜索列表数据
            searlist: [],
            // 当前面包屑
            currentIndex:'',
            // 面包屑id
            id:'',
            // 面包屑列表
            navListw:[]
        };
    },
    computed: {},
    watch: {
        $route(to, from) {
            window.location.reload(); //监测到路由发生跳转时刷新一次页面
        },
    },
    created() {
        if (this.$route.query.type==-1) {
            this.keyword = this.$route.query.keyword;
            this.type = this.$route.query.type;
            this.seachList();
        }
        
        // 获取食品咨询列表数据
        this.currentIndex = this.$route.query.type;
        this.id = this.$route.query.menuid;
        this.navlistRe()
        this.foodnewsListRe();
    },
    methods: {
        // 获取面包屑列表
        navlistRe() {
            navlist(44).then((res) => {
                if (res.code == 1) {
                    this.navListw=res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 面包屑点击事件
        observeTapEv(item, index) {
            this.id = item.id;
            this.currentIndex = index;
            this.foodnewsParams.page = 1;
            this.foodnewsListRe();
        },
        // 分页触发事件
        getPageEv(index) {
            this.foodnewsParams.page = index;
            this.foodnewsListRe();
        },
        // 获取食品咨询列表数据
        foodnewsListRe() {
            let data = {
                menuid:this.id,
                page: this.foodnewsParams.page,
                pagesize: this.foodnewsParams.pageSize,
            };
            foodnewlist(data).then((res) => {
                if (res.code == 1) {
                    this.foodnewsListDe = res.data.list;
                    this.foodnewsParams.page = this.foodnewsListDe.current_page;
                    this.foodnewsParams.pageSize =
                        this.foodnewsListDe.per_page - 0;
                    this.total = this.foodnewsListDe.total;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 搜索列表
        seachList() {
            let data = { keyword: this.keyword };
            search(data).then((res) => {
                if (res.code == 1) {
                    this.searlist = res.data.list;
                } else {
                    this.message(res.msg);
                }
            });
        },
        // 跳转到食品资讯详情页
        goCousultDetail(id) {
            this.$router.push({
                path: "/consult/cousultDetail",
                query: {
                    id: id,
                    type: 1,
                },
            });
        },
    },

    mounted() {},
    beforeCreate() {},
    beforeMount() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
    activated() {},
    components: {
        Header,
        Footer,
        Right,
        Pagination,
        Advertising,
        HeadNav,
    },
};
</script>

<style lang="scss">
// .consult {
//     width: 100%;
//     .prefabricate-wrap {
//         width: 70%;
//         // height: 1000px;
//         margin: 0 auto;
//         // border: 1px solid red;

//         /*关于学会tap start*/
//         .survey-nav {
//             display: flex;
//             align-items: center;
//             width: 100%;
//             padding: 10px 0;
//             box-sizing: border-box;
//             border-bottom: 1px solid #e4e4e4;

//             .survey-nav-title {
//                 width: 30%;
//                 display: flex;
//                 align-items: center;
//                 // border: 1px solid red;

//                 .survey-title-cn {
//                     width: 30%;
//                     font-size: 13px;
//                     color: #0056b0;
//                     margin-right: 10px;
//                 }
//                 .survey-title-sn {
//                     height: 10px;
//                     font-size: 6px;
//                     color: #fff;
//                     padding: 0 6px;
//                     background-color: #0056b0;
//                 }
//             }

//             > ul {
//                 width: 70%;
//                 list-style: none;
//                 display: flex;
//                 // border: 1px solid red;

//                 .active {
//                     color: #0068d5;
//                 }

//                 li {
//                     cursor: pointer;
//                     margin-left: 10px;
//                     padding-left: 10px;
//                     font-size: 10px;
//                     border-left: 1px solid #e9e9e9;
//                 }
//             }
//         }
//         /*关于学会tap end*/

//         /*内容区域 start*/
//         .survey-cont {
//             width: 100%;

//             // 食品咨询列表
//             .survey-content {
//                 display: flex;
//                 width: 100%;
//                 margin: 0 auto;
//                 margin-bottom: 40px;
//                 // border: 1px solid red;

//                 // 内容区域左侧
//                 .survey-content-left {
//                     width: 67%;
//                     height: 100%;
//                     // border: 1px solid blue;

//                     > ul {
//                         list-style: none;

//                         // 左侧内容区域列表
//                         li {
//                             width: 100%;
//                             height: 80px;
//                             display: flex;
//                             padding: 15px 0;
//                             cursor: pointer;
//                             border-bottom: 1px solid #e4e4e4;

//                             .survey-item-img {
//                                 width: 26%;
//                                 height: 100%;

//                                 img {
//                                     width: 100%;
//                                     height: 100%;
//                                 }
//                             }

//                             .survey-item-text {
//                                 width: 72%;
//                                 display: flex;
//                                 flex-direction: column;
//                                 margin-left: auto;
//                                 font-size: 9px;
//                                 .survey-item-text1 {
//                                     white-space: nowrap;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                 }

//                                 .survey-item-text2 {
//                                     margin-top: 20px;
//                                     font-size: 7px;
//                                     color: #999999;
//                                     overflow: hidden;
//                                     text-overflow: ellipsis;
//                                     display: -webkit-box;
//                                     -webkit-line-clamp: 2; //行数
//                                     -webkit-box-orient: vertical;
//                                 }

//                                 .survey-item-text3 {
//                                     margin-top: auto;
//                                     font-size: 8px;
//                                     color: #999999;
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 // 内容区域右侧
//                 .survey-content-right {
//                     margin-left: auto;
//                     width: 30%;
//                     // border: 1px solid red;
//                 }
//             }

//             // 搜索列表
//             .search-list {
//                 margin: 10px 0;
//                 > ul {
//                     list-style: none;

//                     li {
//                         display: flex;
//                         width: 100%;
//                         height: 90px;
//                         padding: 10px;
//                         margin-bottom: 20px;
//                         box-sizing: border-box;
//                         box-shadow: 2px 2px 10px #e1e0e0;
//                         // border: 1px solid red;

//                         // 新闻资讯内容区域左侧
//                         .introduces-item-left {
//                             width: 20%;
//                             height: 100%;

//                             img {
//                                 width: 100%;
//                                 height: 100%;
//                             }
//                         }

//                         // 新闻资讯内容区域右侧
//                         .introduces-item-right {
//                             display: flex;
//                             flex-direction: column;
//                             width: 78%;
//                             height: 100%;
//                             margin-left: auto;

//                             > div:nth-child(1) {
//                                 font-size: 10px;
//                                 white-space: nowrap;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                             }

//                             > div:nth-child(2) {
//                                 margin-top: 3px;
//                                 font-size: 7px;
//                                 color: #b3b3b3;
//                                 overflow: hidden;
//                                 text-overflow: ellipsis;
//                                 display: -webkit-box;
//                                 -webkit-line-clamp: 2; //行数
//                                 -webkit-box-orient: vertical;
//                             }

//                             > div:nth-child(3) {
//                                 cursor: pointer;
//                                 width: 40px;
//                                 padding: 3px 10px;
//                                 margin-top: auto;
//                                 font-size: 10px;
//                                 border: 1px solid #808080;
//                             }
//                         }
//                     }
//                 }

//                 .search-none{
//                     width:300px;
//                     height:260px;
//                     margin:0 auto;

//                     >img{
//                         width:100%;
//                         height:100%;
//                     }
//                 }
//             }
//         }

//         /*内容区域 end*/
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) and (max-width: 1600px) {
//     .consult {
//         width: 100%;
//         .prefabricate-wrap {
//             width: 70%;
//             // height: 1000px;
//             margin: 0 auto;
//             // border: 1px solid red;

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 140px;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         width: 60px;
//                         font-size: 13px;
//                         color: #0056b0;
//                         margin-right: 10px;
//                         // border: 1px solid red;
//                     }
//                     .survey-title-sn {
//                         width: 60px;
//                         height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         background-color: #0056b0;
//                     }
//                 }

//                 > ul {
//                     width: 70%;
//                     list-style: none;
//                     display: flex;
//                     // border: 1px solid red;

//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         margin-left: 10px;
//                         padding-left: 10px;
//                         font-size: 8px;
//                         border-left: 1px solid #e9e9e9;
//                     }
//                 }
//             }
//             /*关于学会tap end*/
//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin: 0 auto;
//                     margin-bottom: 40px;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 67%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         > ul {
//                             list-style: none;

//                             // 左侧内容区域列表
//                             li {
//                                 width: 100%;
//                                 height: 60px;
//                                 display: flex;
//                                 padding: 13px 0 10px 0;
//                                 cursor: pointer;
//                                 border-bottom: 0.5px solid #e4e4e4;

//                                 .survey-item-img {
//                                     width: 26%;
//                                     height: 100%;

//                                     img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }
//                                 }

//                                 .survey-item-text {
//                                     width: 72%;
//                                     display: flex;
//                                     flex-direction: column;
//                                     margin-left: auto;
//                                     font-size: 9px;
//                                     .survey-item-text1 {
//                                         white-space: nowrap;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                     }

//                                     .survey-item-text2 {
//                                         margin-top: 20px;
//                                         font-size: 7px;
//                                         color: #999999;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         display: -webkit-box;
//                                         -webkit-line-clamp: 2; //行数
//                                         -webkit-box-orient: vertical;
//                                     }

//                                     .survey-item-text3 {
//                                         margin-top: auto;
//                                         font-size: 8px;
//                                         color: #999999;
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .consult {
//         width: 100%;
//         .prefabricate-wrap {
//             width: 80%;
//             // height: 1000px;
//             margin: 0 auto;
//             // border: 1px solid red;

//             /*关于学会tap start*/
//             .survey-nav {
//                 display: flex;
//                 align-items: center;
//                 width: 100%;
//                 padding: 10px 0;
//                 box-sizing: border-box;
//                 border-bottom: 1px solid #e4e4e4;

//                 .survey-nav-title {
//                     width: 30%;
//                     display: flex;
//                     align-items: center;
//                     // border: 1px solid red;

//                     .survey-title-cn {
//                         width: 50%;
//                         font-size: 12px;
//                         color: #0056b0;
//                         margin-right: 6px;
//                     }
//                     .survey-title-sn {
//                         width: 30%;
//                         height: 10px;
//                         font-size: 6px;
//                         color: #fff;
//                         padding: 0 6px;
//                         white-space: nowrap;
//                         overflow: hidden;
//                         text-overflow: ellipsis;
//                         background-color: #0056b0;
//                     }
//                 }

//                 > ul {
//                     width: 70%;
//                     list-style: none;
//                     display: flex;
//                     // border: 1px solid red;

//                     .active {
//                         color: #0068d5;
//                     }

//                     li {
//                         cursor: pointer;
//                         margin-left: 10px;
//                         padding-left: 10px;
//                         font-size: 7px;
//                         border-left: 1px solid #e9e9e9;
//                     }
//                 }
//             }
//             /*关于学会tap end*/

//             /*内容区域 start*/
//             .survey-cont {
//                 width: 100%;

//                 .survey-content {
//                     display: flex;
//                     width: 100%;
//                     margin: 0 auto;
//                     margin-bottom: 40px;
//                     // border: 1px solid red;

//                     // 内容区域左侧
//                     .survey-content-left {
//                         width: 67%;
//                         height: 100%;
//                         // border: 1px solid blue;

//                         > ul {
//                             list-style: none;

//                             // 左侧内容区域列表
//                             li {
//                                 width: 100%;
//                                 height: 60px;
//                                 display: flex;
//                                 padding: 8px 0;
//                                 border-bottom: 1px solid #e4e4e4;

//                                 .survey-item-img {
//                                     width: 27%;
//                                     height: 100%;

//                                     img {
//                                         width: 100%;
//                                         height: 100%;
//                                     }
//                                 }

//                                 .survey-item-text {
//                                     width: 70%;
//                                     display: flex;
//                                     flex-direction: column;
//                                     margin-left: auto;
//                                     font-size: 9px;
//                                     .survey-item-text1 {
//                                     }

//                                     .survey-item-text2 {
//                                         margin-top: 10px;
//                                         font-size: 7px;
//                                         color: #999999;
//                                         overflow: hidden;
//                                         text-overflow: ellipsis;
//                                         display: -webkit-box;
//                                         -webkit-line-clamp: 2; //行数
//                                         -webkit-box-orient: vertical;
//                                     }

//                                     .survey-item-text3 {
//                                         margin-top: auto;
//                                         font-size: 8px;
//                                         color: #999999;
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     // 内容区域右侧
//                     .survey-content-right {
//                         margin-left: auto;
//                         width: 30%;
//                         // border: 1px solid red;
//                     }
//                 }
//             }

//             /*内容区域 end*/
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
