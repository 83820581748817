<template>
    <div class="introduces">
        <Mheader :currentIndex="1"></Mheader>

        <!-- 轮播图 -->
        <div class="mIndex-swiper">
            <MeSwiper></MeSwiper>
        </div>

        <div class="introduces-wrap">
            <div class="introduces-cont">
                <div class="introduces-cont-title">
                    <div>公司简介</div>
                    <div>about us</div>
                </div>
                <div
                    class="introduces-cont-text"
                    v-html="companyAbout.content"
                ></div>
            </div>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Mheader from "../../../components/Mheader.vue";
import Footer from "../../../components/Footer";
import MeSwiper from "../../../components/Member/swiper.vue";
import { companyAbout } from "../../../network/data";
export default {
    // 组件名称
    name: "introduces",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            // 当前点击导航栏id
            menu_id: "",
            companyAbout: {},
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {
        // 存储当前网站id
        if (this.$route.query != "") {
            // 获取当前导航栏id
            console.log(this.$route.query.menu_id);
            this.menu_id = this.$route.query.menu_id;
            // 获取企业会员公司简介
            this.companyAboutRe();
        }
    },
    methods: {
        // 获取企业会员公司简介
        companyAboutRe() {
            let data = {
                id: sessionStorage.getItem("id"),
                menuid: this.menu_id,
            };
            companyAbout(data).then((res) => {
                if (res.code == 1) {
                    this.companyAbout = res.data.info;
                    // console.log(res.data, "企业会员公司简介数据");
                } else {
                    this.message(res.msg);
                }
            });
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {
        Mheader,
        Footer,
        MeSwiper,
    },
};
</script>

<style lang="scss">
// .introduces {
//     width: 100%;

//     img {
//         display: block;
//         height: 100%;
//         width: 100%;
//     }

//     // 版心
//     .introduces-wrap {
//         width: 70%;
//         margin: 0 auto;

//         .introduces-cont {
//             width: 100%;
//             // height: 800px;
//             margin-top: 20px;
//             margin-bottom: 40px;
//             // border: 1px solid red;

//             .introduces-cont-title {
//                 text-align: center;
//                 margin: 15px 0 15px 0;

//                 > div:nth-child(1) {
//                     font-size: 14px;
//                 }

//                 > div:nth-child(2) {
//                     font-size: 10px;
//                     color: #999999;
//                 }
//             }

//             .introduces-cont-text {
//                 font-size: 10px;
//             }
//         }
//     }
// }

// //当屏幕最小宽度为992px
// @media screen and (min-width: 1200px) {
// }

// //当屏幕宽度 最小为768px 最大为991px时
// @media screen and (min-width: 768px) and (max-width: 1200px) {
//     .introduces {
//         width: 100%;

//         img {
//             display: block;
//             height: 100%;
//             width: 100%;
//         }

//         // 版心
//         .introduces-wrap {
//             width: 80%;
//             margin: 0 auto;

//             .introduces-cont {
//                 width: 100%;
//                 // height: 800px;
//                 margin-top: 20px;
//                 margin-bottom: 40px;
//                 // border: 1px solid red;
//             }
//         }
//     }
// }

// //当屏幕最大宽度为767px
// @media screen and (max-width: 767px) {
// }
</style>
