<template>
    <!-- 暂未使用 -->
    <!-- 产业需求 -->
    <div class="left">
        <div class="left-card">
            <ul class="ul2">
                <li v-for="(itemb, indexb) in 16" :key="indexb">
                    <div class="science-card">
                        <div class="science-card4-start">
                            <div>未解决</div>
                            <div>河南食品科技河南食品科?</div>
                        </div>
                        <div class="science-card4-tag1">
                            <span>标签：</span>
                            <span>新品研发、研发</span>
                        </div>

                        <div class="science-card4-tag3">
                            <div class="science-card4-data">
                                0 回答 | 4小时前
                            </div>
                            <div>
                                <img
                                    src="../../assets/pc/Index/fj.png"
                                    alt=""
                                />
                                <div>发布难题</div>
                            </div>
                            <div>
                                <img
                                    src="../../assets/pc/Index/bj.png"
                                    alt=""
                                />
                                <div>我来答</div>
                            </div>
                        </div>
                    </div>
                </li>

                <!-- 左侧内容区域分页 -->
                <div class="pageDown">
                    <el-pagination
                        background
                        prev-text="上一页"
                        next-text="下一页"
                        @prev-click="pagePrev"
                        @next-click="pageNext"
                        @current-change="pageChange"
                        :page-size="12"
                        layout=" prev, pager, next"
                        :total="totalPage"
                    >
                    </el-pagination>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    // 组件名称
    name: "left",
    // 组件参数 接收来自父组件的数据
    props: {},
    // 组件状态值
    data() {
        return {
            totalPage: 100,
            pagePrev: 3,
            pageNext: 9,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    // 组件方法
    created() {},
    methods: {
        pageChange() {
            conosle.log("暂未设置");
        },
    },
    mounted() {},
    // 局部注册的组件
    components: {},
};
</script>

<style lang="scss">
// .left {
//     width: 100%;

//     .left-card {
//         width: 100%;
//         // 助企名家start
//         .ul2 {
//             display: flex;
//             justify-content: space-between;
//             flex-wrap: wrap;
//             margin-top: 10px;
//             list-style: none;

//             li {
//                 width: 46%;
//                 padding: 8px 8px;
//                 box-sizing: border-box;
//                 margin-bottom: 18px;
//                 border: 1px solid orchid;

//                 .science-card {
//                     // padding: 0px;
//                     box-sizing: border-box;

//                     .science-card4-start {
//                         display: flex;
//                         // justify-content: space-between;

//                         > div:nth-child(1) {
//                             width: 30px;
//                             height: 10px;
//                             text-align: center;
//                             line-height: 10px;
//                             margin-right: 10px;
//                             color: #fff;
//                             font-size: 6px;
//                             background-color: #f27518;
//                         }

//                         > div:nth-child(2) {
//                             font-size: 8px;
//                         }
//                     }

//                     .science-card4-tag1 {
//                         margin-top: 10px;
//                         font-size: 7px;
//                     }
//                     .science-card4-tag3 {
//                         display: flex;
//                         align-items: center;
//                         justify-content: space-around;
//                         margin-top: 15px;
//                         font-size: 6px;
//                         border: 1px solid red;

//                         > div:nth-child(1) {
//                             font-size: 7px;
//                         }

//                         > div:nth-child(2) {
//                             display: flex;
//                             img {
//                                 width: 10px;
//                                 height: 10px;
//                                 // margin-top: 3px;
//                                 margin-right: 5px;
//                             }
//                         }

//                         > div:nth-child(3) {
//                             display: flex;
//                             img {
//                                 width: 9px;
//                                 height: 9px;
//                                 // margin-top: 3px;
//                                 margin-right: 5px;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         // 助企名家end

//         // 左侧内容区域分页
//         .pageDown {
//             width: 100%;
//             margin: 20px auto;
//             text-align: start;
//             border: 1px solid blue;

//             .el-pagination {
//                 display: flex;
//                 border: 1px solid red !important;

//                 .btn-prev {
//                     width: 40px;
//                     height: 20px;
//                     color: black;
//                     box-sizing: border-box;
//                     border-radius: 0px !important;
//                     border: 1px solid #337136 !important;

//                     > span {
//                         line-height: 20px;
//                         font-size: 10px !important;
//                     }
//                 }
//                 .el-pager {
//                     display: flex;
//                     .active {
//                         background-color: #95bf97 !important;
//                     }
//                     li {
//                         display: flex;
//                         justify-content: center;
//                         height: 20px;
//                         width: 20px;
//                         line-height: 20px;
//                         font-size: 7px;
//                         color: black;
//                         padding: 0;
//                         box-sizing: border-box;
//                         border-radius: 0px !important;
//                         border: 1px solid #337136 !important;
//                     }
//                 }

//                 .btn-next {
//                     width: 40px;
//                     height: 20px;
//                     color: black;
//                     box-sizing: border-box;
//                     border-radius: 0px !important;
//                     border: 1px solid #337136 !important;

//                     > span {
//                         line-height: 20px;
//                         font-size: 10px !important;
//                     }
//                 }
//             }
//         }
//     }
// }
</style>
